import card from "./components/card"

const spellFunctions = {
  lightningBolt: (data) => {
    if (["opponent", "creature", "spellcaster"].includes(data.target.type)) {
      data = data.resolveDamage(data, 4)
      data.target.type === "opponent" &&
        (data.theirPlayer = data.handleDamageToArmor(data.theirPlayer, 2))
      data = data.spellSplice(data)
    } else if (data?.target.type === "artifact" && data?.target.cost <= 3) {
      data = data.meltArtifact(data, data?.target, 3)
      data = data.spellSplice(data)
    }

    return data
  },
  shock: (data) => {
    if (["opponent", "creature", "spellcaster"].includes(data.target.type)) {
      data = { ...data.resolveDamage(data, 4) }
    } else if (data?.target.type === "artifact" && data?.target.cost <= 3) {
      data = data.meltArtifact(data, data?.target, 3)
    }

    return data
  },
  vikingBerserker: (data) => {
    data = data.discardCards(data, 2, true, "self")
    return data
  },
  rideToValhalla: (data) => {
    data = data.drawCards(data, 3)
    data = data.spellSplice(data)
    data.pauseGame = true
    if (data.discardNumber) {
      data.discardNumber += 2
    } else {
      data.discardNumber = 2
    }
    return data
  },
  heraldOfValhalla: (data) => {
    
    const originalLength = data.attackerCurrentBoard.length
    data.buffCard = { attack: originalLength * 2, defense: originalLength * 2 }
    let count = 0
    while (count < originalLength) {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.attackerCurrentBoard[0],
        kill: true,
      })
      count++
    }
    return data
  },
  athenaOfWar: (data) => {
    if (["opponent", "creature", "spellcaster"].includes(data.target.type)) {
      data = { ...data.resolveDamage(data, 4) }
      data.target.type === "opponent" &&
        (data.theirPlayer = data.handleDamageToArmor(data.theirPlayer, 2))
      data = data.spellSplice(data)
    } else if (data?.target.type === "artifact" && data?.target.cost <= 3) {
      data = data.meltArtifact(data, data?.target, 3)
      data = data.spellSplice(data)
    }

    return data
  },
  raiseDead: (data) => {
    if (data.target.type === "creature" || data.target.type === "spellcaster") {
      const reviveTarget = data.myCurrentGY.splice(data.index, 1)[0]
      data = data.playCreature({
        ...data,
        creature: { ...reviveTarget },
        castSpell: true,
      })
      data = data.spellSplice(data)
    }
    return data
  },
  zeusWrath: (data) => {
    data = data.boardAreaOfEffect(data, 3)
    data = data.resolveDamage(data, 3, "opponent")
    data = data.spellSplice(data)
    return data
  },
  improvedZeusWrath: (data) => {
    data = data.boardAreaOfEffect(data, 4)
    data = data.resolveDamage(data, 4, "opponent")
    return data
  },
  vajra: (data) => {
    data = data.boardAreaOfEffect(data, 5)
    data = data.resolveDamage(data, 5, "opponent")
    return data
  },
  cerberusWrath: (data) => {
    data = data.calculateHealing(data, 1 + data.defenderCurrentBoard.length)
    data = data.boardAreaOfEffect(data, 1)
    data = data.resolveDamage(data, 1, "opponent")

    return data
  },
  cerberusWrathSpell: (data) => {
    data = data.calculateHealing(data, 1 + data.defenderCurrentBoard.length)
    data = data.boardAreaOfEffect(data, 1)
    data = data.resolveDamage(data, 1, "opponent")
    data = data.spellSplice(data)

    return data
  },
  athenasKnowledge: (data) => {
    data = data.resolveDamage(data, 2, "self", false)
    data = data.drawCards(data, 2)
    
    data = data.spellSplice(data)
    return data
  },
  athenasImprovedKnowledge: (data) => {
    data = data.drawCards(data, 2)
    
    return data
  },
  discipleOfAthena: (data) => {
    data = data.drawCards(data, 1)
    return data
  },
  hadesBargain: (data) => {
    // Filter out cards with card.type === 'realm'
  let nonRealmCards = data.myCurrentGY.filter(card => card.class !== 'realm');

  if (nonRealmCards.length > 2) {
      for (let i = 0; i <= 2; i++) {
          const randomIndex = Math.floor(Math.random() * nonRealmCards.length);
          const retrievedCard = nonRealmCards.splice(randomIndex, 1)[0];
          data.myHand = [...data.myHand, retrievedCard];
          // Also remove the selected card from the original data.myCurrentGY
          data.myCurrentGY = data.myCurrentGY.filter(card => card !== retrievedCard);
      }
  } else if (nonRealmCards.length === 2) {
      for (let i = 0; i < 2; i++) {
          const randomIndex = Math.floor(Math.random() * nonRealmCards.length);
          const retrievedCard = nonRealmCards.splice(randomIndex, 1)[0];
          data.myHand = [...data.myHand, retrievedCard];
          // Also remove the selected card from the original data.myCurrentGY
          data.myCurrentGY = data.myCurrentGY.filter(card => card !== retrievedCard);
      }
  } else if (nonRealmCards.length === 1) {
      const retrievedCard = nonRealmCards.splice(0, 1)[0];
      data.myHand = [...data.myHand, retrievedCard];
      // Also remove the selected card from the original data.myCurrentGY
      data.myCurrentGY = data.myCurrentGY.filter(card => card !== retrievedCard);
  }
  data = data.spellSplice(data)
  return data;
},
improvedHadesBargain: (data) => {
  // Filter out cards with card.type === 'realm'
  let nonRealmCards = data.myCurrentGY.filter(card => card.class !== 'realm');

  if (nonRealmCards.length > 2) {
      for (let i = 0; i <= 2; i++) {
          const randomIndex = Math.floor(Math.random() * nonRealmCards.length);
          const retrievedCard = nonRealmCards.splice(randomIndex, 1)[0];
          if(retrievedCard.cost > 1){
            retrievedCard.cost -= 1
          }
          data.myHand = [...data.myHand, retrievedCard];
          // Also remove the selected card from the original data.myCurrentGY
          data.myCurrentGY = data.myCurrentGY.filter(card => card !== retrievedCard);
      }
  } else if (nonRealmCards.length === 2) {
      for (let i = 0; i < 2; i++) {
          const randomIndex = Math.floor(Math.random() * nonRealmCards.length);
          const retrievedCard = nonRealmCards.splice(randomIndex, 1)[0];
          if(retrievedCard.cost > 1){
            retrievedCard.cost -= 1
          }
          data.myHand = [...data.myHand, retrievedCard];
          // Also remove the selected card from the original data.myCurrentGY
          data.myCurrentGY = data.myCurrentGY.filter(card => card !== retrievedCard);
      }
  } else if (nonRealmCards.length === 1) {
      const retrievedCard = nonRealmCards.splice(0, 1)[0];
      if(retrievedCard.cost > 1){
        retrievedCard.cost -= 1
      }
      data.myHand = [...data.myHand, retrievedCard];
      // Also remove the selected card from the original data.myCurrentGY
      data.myCurrentGY = data.myCurrentGY.filter(card => card !== retrievedCard);
  }
  return data;
},
  apollosLight: (data) => {
    data = data.drawCards(data, 1)
    data = data.calculateHealing(data, 3)
    data.myPlayer.defense += 4
    return data
  },
  apollosLightSpell: (data) => {
    data = data.calculateHealing(data, 3)
    data.myPlayer.defense += 4
    data = data.drawCards(data, 1)
    data = data.spellSplice(data)
    return data
  },
  fatherOfMonsters: (data) => {
    const typhon = { ...data.creature }
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.attackerCurrentBoard[i].name === "Typhon") {
        data.attackerCurrentBoard[i].damageTaken = 0
      }
    }
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (
          (data.deck[i].class === "monster" ||
            data.deck[i].class === "giant") &&
          data.deck[i].name !== "Typhon"
        ) {
          let summonedMonster = data.deck.splice(i, 1)[0]
          data = data.playCreature({
            ...data,
            creature: { ...summonedMonster },
            castSpell: true,
          })
          data.creature = { ...typhon }
          data.theirPlayer.damageTaken +=
            summonedMonster.attack > data.theirPlayer.block
              ? summonedMonster.attack - data.theirPlayer.block
              : 0
          if (!summonedMonster.effects.includes("haste")) {
            data.attacked.push(summonedMonster)
          }
          if (summonedMonster.attack > 0) {
            if (data.theirPlayer.durability > 0) {
              data.theirPlayer.durability -= 1
            }
            if (data.theirPlayer.durability === 0) {
              data.theirPlayer.block = 0
            }
          }
          data = data.calculateHealing(data, summonedMonster.attack || 0)
          break
        }
      }
    } else {
      const typhon = { ...data.creature }
      const values = data.myCurrentGY.filter(
        (card) =>
          card.type === "creature" &&
          (card.class === "monster" || card.class === "giant")
      )
      if(values.length > 0){
        const randomIndex = Math.floor(Math.random() * values.length)
        const drawnCard = values.splice(randomIndex, 1)[0]
        data.myCurrentGY = data.myCurrentGY.filter(
          (card) => drawnCard?.id !== card.id
        )
        data = data.playCreature({
          ...data,
          creature: drawnCard,
          castSpell: true,
        })
        data.creature = { ...typhon }
        data.theirPlayer.damageTaken +=
          drawnCard?.attack > data.theirPlayer.block
            ? drawnCard?.attack - data.theirPlayer.block
            : 0
        data = data.calculateHealing(data, drawnCard.attack || 0)
        if (drawnCard?.attack > 0) {
          if (data.theirPlayer.durability > 0) {
            data.theirPlayer.durability -= 1
          }
          if (data.theirPlayer.durability === 0) {
            data.theirPlayer.block = 0
          }
        }
      }
    }
    return data
  },
  achillesArmor: (data) => {
    data.myPlayer.block += 1
    data.myPlayer.durability += 2
    data = data.drawCards(data, 1)
    data = data.spellSplice(data)
    return data
  },
  motherOfMonsters: (data) => {
    data.theirPlayer.poison += 2
    if (data.defenderCurrentBoard.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
      })
    }
    data.kill = false
    return data
  },
  motherOfMonstersSpell: (data) => {
    data.theirPlayer.poison += 2
    if (data.defenderCurrentBoard.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
      })
      data.kill = false
    }
    if (!data.drawFromGraveyard) {
      const echidna = data.deck.filter((card) => card.name === "Echidna")[0]
      data.deck = data.deck.filter(
        (card) => card.name !== "Echidna" && card.id !== echidna?.id
      )
      if (echidna) {
        data.myHand.push(echidna)
      }
    } else {
      const echidna = data.myCurrentGY.filter(
        (card) => card.name === "Echidna"
      )[0]
      data.myCurrentGY = data.myCurrentGY.filter(
        (card) => card.name !== "Echidna" && card.id !== echidna?.id
      )
      if (echidna) {
        data.myHand.push(echidna)
      }
    }
    data = data.spellSplice(data)
    return data
  },
  fatherOfMonstersSpell: (data) => {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.attackerCurrentBoard[i].name === "Typhon") {
        data.attackerCurrentBoard[i].damageTaken = 0
      }
    }
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.deck[i].name === "Typhon") {
          const typhon = data.deck.splice(i, 1)[0]
          data.myHand = [...data.myHand, typhon]
        }
      }
      for (let i = 0; i < data.deck.length; i++) {
        if (
          (data.deck[i].class === "monster" ||
            data.deck[i].class === "giant") &&
          data.deck[i].name !== "Typhon"
        ) {
          let summonedMonster = data.deck.splice(i, 1)[0]
          data = data.playCreature({
            ...data,
            creature: summonedMonster,
            castSpell: true,
          })
          break
        }
      }
    } else {
      const values = data.myCurrentGY.filter(
        (card) =>
          card.type === "creature" &&
          (card.class === "monster" || card.class === "giant") &&
          card.name !== "Typhon"
      )
      const randomIndex = Math.floor(Math.random() * values.length)
      const drawnCard = values.splice(randomIndex, 1)[0]
      data.myCurrentGY = data.myCurrentGY.filter(
        (card) => drawnCard?.id !== card.id
      )
      data = data.playCreature({
        ...data,
        creature: drawnCard,
        castSpell: true,
      })
      for (let i = 0; i < data.myCurrentGY.length; i++) {
        if (data.myCurrentGY[i].name === "Typhon") {
          data.myHand = [...data.myHand, data.myCurrentGY.splice(i, 1)[0]]
        }
      }
    }
    data = data.spellSplice(data)
    return data
  },
  servantOfHades: (data) => {
    data = data.drawCards(data, 1)
    data.myPlayer.damageTaken += data.drawnCards.reduce((acc, card) => {
      acc += card.cost
      return acc
    }, 0)
    return data
  },
  theGraeae: (data) => {
    data = data.drawCards(data, 1, "both")
    data.myPlayer.damageTaken += data.drawnCards.reduce((acc, card) => {
      acc += card.cost
      return acc
    }, 0)
    data.theirPlayer.damageTaken += data.theirDrawnCards.reduce((acc, card) => {
      acc += card.cost
      return acc
    }, 0)
    return data
  },
  agamemnonsCall: (data) => {
    const myDeck = [...data.deck]
    for (let i = 0; i < myDeck.length; i++) {
      if (myDeck[i].cost >= 5) {
        myDeck[i].cost += 2
      }
    }
    data = data.drawCards(data, 4)
    data = data.spellSplice(data)
    return data
  },
  timeErosion: (data) => {
    data.theirPlayer.damageTaken += 5
    if (data.theirPlayer.durability > 0) {
      if (data.theirPlayer.durability === 1) {
        data.theirPlayer.durability = 0
        data.theirPlayer.block = 0
        data.attackerCurrentBoard[data.index].attack += 1
      } else if (data.theirPlayer.durability === 2) {
        data.theirPlayer.durability = 0
        data.theirPlayer.block = 0
        data.attackerCurrentBoard[data.index].attack += 2
      } else {
        data.theirPlayer.durability -= 3
        data.attackerCurrentBoard[data.index].attack += 3
        if (data.theirPlayer.durability === 0) {
          data.theirPlayer.block = 0
        }
      }
    }
    data = data.spellSplice(data)
    return data
  },
  hydraSplit: (data) => {
    data.card.damageTaken = 0
    const hydraOne = JSON.parse(JSON.stringify(data.card))
    const hydraTwo = JSON.parse(JSON.stringify(data.card))
    hydraOne.id = Math.floor(
      (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        10000000
    )
    hydraTwo.id = Math.floor(
      (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        10000000
    )
    hydraOne.token = true
    hydraTwo.token = true
    hydraOne.mythology = "token"
    hydraTwo.mythology = "token"
    hydraOne.effects.splice(hydraOne.effects.indexOf("lastRites"), 1)
    hydraOne.abilities = []
    hydraOne.text = hydraOne.text.replace(
      "Last Rites: Summon 2 copies without last rites, they're removed from the game when they die",
      ""
    )
    hydraOne.activatesOn = []
    hydraOne.image = "leftHydraHead"
    hydraTwo.image = "rightHydraHead"
    hydraTwo.effects.splice(hydraTwo.effects.indexOf("lastRites"), 1)
    hydraTwo.abilities = []
    hydraTwo.text = hydraTwo.text.replace(
      "Last Rites: Summon 2 copies without last rites, they're removed from the game when they die",
      ""
    )
    hydraTwo.activatesOn = []
    if (data.location === "attacker") {
      data = data.playCreature({
        ...data,
        creature: hydraOne,
        castSpell: false,
      })
      data = data.playCreature({
        ...data,
        creature: hydraTwo,
        castSpell: false,
      })
      data.attacked = [...data.attacked, hydraOne, hydraTwo]
    } else if (data.location === "defender") {
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: hydraOne,
        castSpell: false,
      })
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: hydraTwo,
        castSpell: false,
      })
      data.theirAttacked = [...data.theirAttacked, hydraOne, hydraTwo]
    }
    return data
  },
  hadesSummoningRitual: (data) => {
    const creatures = data.myCurrentGY.filter(
      (card) =>
        (card.type === "creature" || card.type === "spellcaster") &&
        card.cost <= 3
    )
    let count = 2
    while (creatures.length > 0 && count > 0) {
      const cardDrawn = creatures.splice(Math.random() * creatures.length, 1)[0]
      data.myHand.push(cardDrawn)
      data.myCurrentGY.splice(
        data.myCurrentGY.findIndex((card) => card.id === cardDrawn.id),
        1
      )
      count--
    }
    data = data.spellSplice(data)
    return data
  },
  releaseTheKraken: (data) => {
    const myDeck = [...data.deck]
    const myGY = [...data.myCurrentGY]
    const hand = [...data.myHand]
    const myBoard = [...data.attackerCurrentBoard]
    let totalAttack = 0
    let totalDefense = 0
    let count = 2
    let damage = 0
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < myDeck.length; i++) {
        const currentCard = myDeck[i]
        if (
          (currentCard.type === "creature" ||
            currentCard.type === "spellcaster") &&
          currentCard.cost <= 2
        ) {
          const creature = myDeck.splice(i, 1)[0]
          if (data.theirPlayer.durability > 0) {
            creature.attack > data.theirPlayer.block
              ? (data.theirPlayer.damageTaken +=
                  creature.attack - data.theirPlayer.block)
              : (data.theirPlayer.damageTaken += 0)
            data.theirPlayer.durability -= 1
            if (data.theirPlayer.durability === 0) {
              data.theirPlayer.block = 0
            }
          } else {
            if (damage < 8) {
              let remainingDamage = 8 - damage // Calculate how much more damage can be dealt
              let damageToDeal = Math.min(creature.attack, remainingDamage) // Ensure we do not exceed the remaining allowed damage
              data.theirPlayer.damageTaken += damageToDeal // Add the damage to the opponent's damage taken
              damage += damageToDeal // Update the total damage dealt
            }
          }
          totalAttack += Math.ceil(creature.attack / 2)
          totalDefense += Math.ceil(creature.defense / 2)
          myGY.unshift(creature)
          i--
          count--
        }
        if (count === 0) {
          break
        }
      }
    } else {
      for (let i = 0; i < myGY.length; i++) {
        const currentCard = myGY[i]
        if (
          (currentCard.type === "creature" ||
            currentCard.type === "spellcaster") &&
          currentCard.cost <= 2
        ) {
          const creature = myGY.splice(i, 1)[0]
          if (data.theirPlayer.durability > 0) {
            creature.attack > data.theirPlayer.block
              ? (data.theirPlayer.damageTaken +=
                  creature.attack - data.theirPlayer.block)
              : (data.theirPlayer.damageTaken += 0)
            data.theirPlayer.durability -= 1
            if (data.theirPlayer.durability === 0) {
              data.theirPlayer.block = 0
            }
          } else {
            if (damage < 8) {
              let remainingDamage = 8 - damage // Calculate how much more damage can be dealt
              let damageToDeal = Math.min(creature.attack, remainingDamage) // Ensure we do not exceed the remaining allowed damage
              data.theirPlayer.damageTaken += damageToDeal // Add the damage to the opponent's damage taken
              damage += damageToDeal // Update the total damage dealt
            }
          }
          totalAttack += Math.ceil(creature.attack / 2)
          totalDefense += Math.ceil(creature.defense / 2)
          myGY.unshift(creature)
          i--
          count--
        }
        if (count === 0) {
          break
        }
      }
    }

    let krakenFound = false
    while (krakenFound === false) {
      for (let i = 0; i < myDeck.length; i++) {
        const isKraken = myDeck[i]
        if (isKraken.name === "Kraken") {
          myDeck[i].attack += totalAttack
          myDeck[i].defense += totalDefense
          krakenFound = true
          let kraken = myDeck.splice(i, 1)[0]
          if (kraken.cost < 8) {
            kraken.cost += 2
          } else {
            kraken.cost = 10
          }
          hand.push(kraken)
        }
      }
      if (krakenFound === true) {
        break
      }
      for (let i = 0; i < hand.length; i++) {
        const isKraken = hand[i]
        if (isKraken.name === "Kraken") {
          hand[i].attack += totalAttack
          hand[i].defense += totalDefense
          krakenFound = true
          if (hand[i] < 8) {
            hand[i].cost += 2
          } else {
            hand[i].cost = 10
          }
        }
      }
      if (krakenFound === true) {
        break
      }
      for (let i = 0; i < myGY.length; i++) {
        const isKraken = myGY[i]
        if (isKraken.name === "Kraken") {
          myGY[i].attack += totalAttack
          myGY[i].defense += totalDefense
          krakenFound = true
          let kraken = myGY.splice(i, 1)[0]
          if (kraken.cost < 8) {
            kraken.cost += 2
          } else {
            kraken.cost = 10
          }
          hand.push(kraken)
        }
      }
      if (krakenFound === true) {
        break
      }
      for (let i = 0; i < myBoard.length; i++) {
        const isKraken = myBoard[i]
        if (isKraken.name === "Kraken") {
          myBoard[i].attack += totalAttack
          myBoard[i].defense += totalDefense
          krakenFound = true
          let kraken = myBoard.splice(i, 1)[0]
          if (kraken.cost < 8) {
            kraken.cost += 2
          } else {
            kraken.cost = 10
          }
          hand.push(kraken)
        }
      }
      if (krakenFound === true) {
        break
      }
      break
    }
    data.myCurrentGY = [...myGY]
    data.myHand = [...hand]
    data.deck = [...myDeck]
    data.attackerCurrentBoard = [...myBoard]
    return data
  },
  tentacles: (data) => {
    const originalCreature = { ...data.creature }
    for (let i = 0; i < 2; i++) {
      let tentacles = { ...originalCreature }
      tentacles.id = Math.ceil(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      )
      tentacles.name = "Kraken Tentacle"
      tentacles.cost = 1
      tentacles.attack = Math.ceil(tentacles.attack / 2)
      tentacles.defense = Math.ceil(tentacles.defense / 2)
      tentacles.text =
        "Haste:\nETB: Spawn 2 tentacles when played with half the stats rounded up"
      tentacles.image = "tentacles"
      tentacles.effects = ["haste"]
      tentacles.token = true
      tentacles.mythology = "token"
      data = data.playCreature({
        ...data,
        creature: tentacles,
        castSpell: false,
      })
    }
    data.creature = originalCreature
    return data
  },
  godAboveAll: (data) => {
    const vajra = {
      name: "Vajra",
      type: "creature",
      class: "weapon",
      attack: 0,
      defense: 5,
      damageTaken: 0,
      cost: 5,
      text: "Cast an improved version of Zeus' Wrath at the start of every turn dealing 5 damage to all enemies",
      abilities: ["vajra"],
      activatesOn: ["noTarget", "startTurnOnly", "startTurn"],
      effects: [],
      rarity: "mythic",
      image: "vajra",
      id: data.randomId(data),
      token: true,
      mythology: "token",
    }

    data = data.boardAreaOfEffect(data, 0, "both", true)

    data.attackerCurrentBoard.push(vajra)
    data = data.drawCards(data, 3)
    return data
  },
  thanatosDecree: (data) => {
    if (data?.target.type === "artifact") {
      data = data.meltArtifact(data, data?.target, Infinity)
    } else {
      data = data.calculateHealing(
        data,
        data.defenderCurrentBoard[data.index]?.cost
      )
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard?.[data.index],
        kill: true,
      })
    }

    return data
  },
  thanatosDecreeSpell: (data) => {
    if (data?.target.type === "artifact") {
      data = data.meltArtifact(data, data?.target, Infinity)
    } else {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard?.[data.index],
        kill: true,
      })
    }
    data = data.spellSplice(data)
    return data
  },
  iapetus: (data) => {
    data = data.killCreature({
      ...data,
      location: "defender",
      card: data.defenderCurrentBoard[data.index],
      kill: true,
    })
    data = data.drawCards(data, 1)
    return data
  },
  discipleOfIapetus: (data) => {
    let count
    if (data.deck.length > 4) {
      count = 4
    } else {
      count = data.deck.length
    }
    const movedCards = data.deck.splice(0, count)
    data.myCurrentGY = [...movedCards, ...data.myCurrentGY]
    return data
  },
  rallyingCry: (data) => {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      data.attackerCurrentBoard[i].attack += 1
    }
    return data
  },
  chaosIncarnate: (data) => {
    data.myPlayer.userName = "Chaos"
    data.myPlayer.type = "self"
    data.myPlayer.abilities = ["theCreator", "theVoid", "theAbyssal"]
    data.myPlayer.abilityDescription = [
      "Draw a god or titan card",
      "Each player discards their hand you lose 10 life",
      "Summon a 6/6 Abyssal Titan with provoke",
    ]
    data = data.spellSplice(data)
    data.usedPlayerAbility = false
    return data
  },
  theCreator: (data) => {
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.deck[i].class === "god" || data.deck[i].class === "titan") {
          data.myHand = [...data.myHand, data.deck.splice(i, 1)[0]]
          break
        }
      }
    } else {
      const values = data.myCurrentGY.filter(
        (card) => card.class === "god" || card.class === "titan"
      )
      const randomIndex = Math.floor(Math.random() * values.length)
      if (values.length > 0) {
        const drawnCard = values.splice(randomIndex, 1)[0]
        data.myHand = [...data.myHand, drawnCard]
        data.myCurrentGY = data.myCurrentGY.filter(
          (card) => card.id !== drawnCard.id
        )
      }
    }
    return data
  },
  theAbyssal: (data) => {
    const myBoard = [...data.attackerCurrentBoard]
    const abyssal = {
      name: "Void Abyssal",
      type: "creature",
      class: "titan",
      attack: 6,
      defense: 6,
      damageTaken: 0,
      cost: 5,
      text: "Provoke",
      abilities: [],
      activatesOn: [],
      effects: ["provoke"],
      rarity: "common",
      id: data.randomId(data),
      image: "abyssal",
      mythology: "token",
      token: true,
    }
    myBoard.push(abyssal)
    data.attackerCurrentBoard = [...myBoard]
    data.attacked.push(abyssal)
    return data
  },
  theVoid: (data) => {
    data = data.discardCards(data, data.myHand.length, true, "self")
    data = data.discardCards(data, data.theirHand.length, true, "opponent")

    data.myPlayer.damageTaken += 10

    return data
  },
  lawOrder: (data) => {
    data.myPlayer.userName = "Themis"
    data.myPlayer.type = "self"
    data.myPlayer.abilities = ["deliverJustice", "enforceOrder", "pronounceLaw"]
    data.myPlayer.abilityDescription = [
      "Attack the enemy player for 4 damage",
      "Reduce the life of a random enemy minion to 1",
      "Buff one of your minions randomly with +2/+1",
    ]
    data = data.spellSplice(data)
    data.usedPlayerAbility = false
    return data
  },
  deliverJustice: (data) => {
    data.theirPlayer.block > 4
      ? (data.theirPlayer.damageTaken += 0)
      : (data.theirPlayer.damageTaken += 4 - data.theirPlayer.block)
    if (data.theirPlayer.durability > 0) {
      data.theirPlayer.durability -= 1
    }
    if (data.theirPlayer.durability === 0) {
      data.theirPlayer.block = 0
    }
    return data
  },
  enforceOrder: (data) => {
    if (data.defenderCurrentBoard.length > 0) {
      let theirBoard = [...data.defenderCurrentBoard]
      const randomIndex = Math.floor(Math.random() * theirBoard.length)
      theirBoard[randomIndex].damageTaken = theirBoard[randomIndex].defense - 1
      data.defenderCurrentBoard = theirBoard
    }
    return data
  },
  pronounceLaw: (data) => {
    if (data.attackerCurrentBoard.length > 0) {
      let myBoard = [...data.attackerCurrentBoard]
      const randomIndex = Math.floor(Math.random() * myBoard.length)
      myBoard[randomIndex].attack += 2
      myBoard[randomIndex].defense += 1
      data.attackerCurrentBoard = myBoard
    }
    return data
  },
  rageOfHephaestus: (data) => {
    data.myPlayer.block += 2
    data.myPlayer.durability += 4
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
        kill: Math.floor(Math.random() * 2) === 1 ? true : false,
      })
      if (!data.defenderSurvive) {
        i--
      }
      data.defenderSurvive = true
    }
    return data
  },
  poolOfSouls: (data) => {
    if (data.target.type === "creature" || data.target.type === "spellcaster") {
      data = data.resolveDamage(data, 7)
      if (data.attackerKill) {
        for (let i = 0; i < data.myHand.length; i++) {
          if (data.myHand[i].cost > 0) {
            data.myHand[i].cost = Math.max(1, data.myHand[i].cost - 1)
            if (data.myHand[i].recursionCost) {
              data.myHand[i].recursionCost =
                data.myHand[i].cost + data.myHand[i].recursionCost
            }
          }
        }
      }
      data = data.spellSplice(data)
    }
    return data
  },
  soulDrain: (data) => {
    if (data.target.type === "creature" || data.target.type === "spellcaster") {
      data = data.resolveDamage(data, 3)
      if (data.attackerKill) {
        data = data.drawCards(data, 1)
      }
      data = data.spellSplice(data)
    }
    return data
  },
  coeusForesight: (data) => {
    let count = 0
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.deck[i].type === "spell" && data.deck[i].class === "sorcery") {
          const cardDrawn = data.deck.splice(i, 1)[0]
          data.myHand.push(cardDrawn)
          count++
          if (count === 3) {
            break
          }
        }
      }
    } else {
      const values = data.myCurrentGY.filter(
        (card) => card.type === "spell" && card.class === "sorcery"
      )
      for (let i = 0; i < 3; i++) {
        if (i > values.length) {
          break
        }
        const randomIndex = Math.floor(Math.random() * values.length)
        const drawnCard = values.splice(randomIndex, 1)[0]
        data.myCurrentGY = data.myCurrentGY.filter(
          (card) => drawnCard.id !== card.id
        )
        data.myHand = [...data.myHand, drawnCard]
        data.myPlayer.damageTaken += 1
      }
    }
    data = data.spellSplice(data)
    return data
  },
  zeusAegis: (data) => {
    if (data.target.type === "creature" || data.target.type === "spellcaster") {
      data.myPlayer.block += 2
      data.myPlayer.durability += 1
      data = data.resolveDamage(data, data.myPlayer.block)
      data = data.spellSplice(data)
    }
    return data
  },
  scorchingLight: (data) => {
    if (
      data.target.type === "creature" ||
      data.target.type === "spellcaster" ||
      data.target.type === "opponent"
    ) {
      data = data.resolveDamage(data, 4)
      data = data.calculateHealing(data, 4)
      data = data.spellSplice(data)
    }
    return data
  },
  dolosTrickery: (data) => {
    let myOldDeck = [...data.deck]
    for (let i = 0; i < 2; i++) {
      let duplicateCard = { ...data.target }
      let duplicateCardTwo = { ...data.target }
      duplicateCard.damageTaken = 0
      duplicateCardTwo.damageTaken = 0

      duplicateCard.cost = Math.max(1, duplicateCard.cost - 4)
      duplicateCardTwo.cost = Math.max(1, duplicateCardTwo.cost - 4)
      if(duplicateCard.recursionCost){
        duplicateCard.recursionCost = Math.max(1, (duplicateCard.recursionCost + duplicateCard.resurrection) - 4)
        duplicateCardTwo.recursionCost = Math.max(1, (duplicateCardTwo.recursionCost + duplicateCardTwo.resurrection) - 4)
      }

      duplicateCard.attack = 1
      duplicateCard.defense = 1
      duplicateCard.id = data.randomId(data)
      duplicateCardTwo.attack = 1
      duplicateCardTwo.defense = 1
      duplicateCardTwo.id = data.randomId(data)
      myOldDeck.push(duplicateCard)
      data.myCurrentGY.unshift(duplicateCardTwo)
    }
    let shuffledDeck = []
    while (myOldDeck.length > 0) {
      const randomIndex = Math.floor(Math.random() * myOldDeck.length)
      let cardToBeAdded = myOldDeck.splice(randomIndex, 1)[0]
      shuffledDeck.push(cardToBeAdded)
    }
    data = data.spellSplice(data)

    data.deck = [...shuffledDeck]
    return data
  },
  riseOfTartarus: (data) => {
    const costReduce = (card) => {
      if (card.type === "creature" || card.type === "spellcaster") {
        card.cost = card.cost > 0 ? (card.cost -= 1) : 0
      }
      data.drawFromGraveyard = false
      return card
    }
    data.myCurrentGY.map(costReduce)
    let oldGY = [...data.myCurrentGY]
    const cardSplit = oldGY.reduce(
      (acc, card) => {
        if (card.type === "creature" || card.type === "spellcaster") {
          acc.creatures.push(card)
        } else {
          acc.newGY.push(card)
        }
        return acc
      },
      { newGY: [], creatures: [] }
    )
    data.myCurrentGY = [...cardSplit.newGY]
    let tempDeck = [...data.deck, ...cardSplit.creatures]
    let shuffledDeck = []
    while (tempDeck.length > 0) {
      const randomIndex = Math.floor(Math.random() * tempDeck.length)
      let shuffledCard = tempDeck.splice(randomIndex, 1)[0]
      shuffledDeck.push(shuffledCard)
    }
    data = data.spellSplice(data)
    data.deck = [...shuffledDeck]
    data = data.drawCards(data, 1)

    return data
  },
  sunTitan: (data) => {
    data.myPlayer.userName = "Thea"
    data.myPlayer.type = "self"
    data.myPlayer.abilities = ["heavenlyLight", "theasForesight", "divineLight"]
    data.myPlayer.abilityDescription = [
      "Heal all your characters for 2 life, and increase their maximum life by 1",
      "Increase your maximum life by 4",
      "Pay 100 life win the game",
    ]
    data = data.spellSplice(data)
    data.usedPlayerAbility = false
    return data
  },
  heavenlyLight: (data) => {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      data.attackerCurrentBoard[i].damageTaken > 2
        ? (data.attackerCurrentBoard[i].damageTaken -= 2)
        : (data.attackerCurrentBoard[i].damageTaken = 0)
      data.attackerCurrentBoard[i].defense += 1
    }
    data = data.calculateHealing(data, 2)
    data.myPlayer.defense += 1
    return data
  },
  theasForesight: (data) => {
    data.myPlayer.defense += 4
    return data
  },
  divineLight: (data) => {
    if (data.myPlayer.defense - data.myPlayer.damageTaken >= 100) {
      data.theirPlayer.damageTaken = data.theirPlayer.defense
    }
    return data
  },
  aresMorph: (data) => {
    const ares = data.findCard(data, data.target.id)
    // data.attackerCurrentBoard.find(
    //   (card) => card.id === data.target.id
    // )
    ares.name = "Ares, God of Battlelust"
    ares.attack += 4
    ares.defense += 3
    ares.text = ares.text.replace(
      "Haste\nOn kill transform into Ares, God of Battlelust gaining +4/+3 that gives all minions on your side of the board +1/+1 at the start of every turn",
      "Buff all creatures on your board with +1/+1 at the start of turn"
    )
    ares.abilities = ["bloodlust"]
    ares.activatesOn = ["noTarget", "startTurn", "startTurnOnly"]
    ares.image = "aresTransformed"
    ares.effects = []

    return data
  },
  bloodlust: (data) => {
    const newAttackerBoard = data.attackerCurrentBoard.map((card) => {
      card.attack += 1
      card.defense += 1
      return card
    })
    data.attackerCurrentBoard = [...newAttackerBoard]
    return data
  },
  chaosBorn: (data) => {
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.deck[i].class === "titan" || data.deck[i].class === "god") {
          const drawnCard = data.deck.splice(i, 1)[0]
          if (!drawnCard) continue
          data.myHand = [...data.myHand, drawnCard]
          if (
            drawnCard.abilities.length > 0 &&
            (drawnCard.activatesOn.length === 0 ||
              drawnCard.activatesOn.includes("friendly") ||
              drawnCard.activatesOn.includes("myGraveyard")) &&
            drawnCard.type !== "spellcaster" &&
            !drawnCard.activatesOn.includes("doNotCast")
          ) {
            data.castingSpell = true
            data.spellCast = drawnCard
          } else if (
            drawnCard.abilities.length > 0 &&
            !drawnCard.activatesOn.includes("onKill") &&
            !drawnCard.abilities.includes("extraTurn") &&
            drawnCard.type !== "spellcaster" &&
            !drawnCard.abilities.includes(
              "odinAllfather" && !drawnCard.activatesOn.includes("doNotCast")
            )
          ) {
            data = data.resolveSpell(
              { ...data, myFunction: spellFunctions[drawnCard.abilities[0]] },
              false
            )
            data.castingSpell = false
            data.spellCast = null
          }
          break
        }
      }
    } else {
      const values = data.myCurrentGY.filter(
        (card) => card.class === "god" || card.class === "titan"
      )
      const randomIndex = Math.floor(Math.random() * values.length)
      const drawnCard = values.splice(randomIndex, 1)[0]
      data.myHand = [...data.myHand, drawnCard]
      data.myCurrentGY = data.myCurrentGY.filter(
        (card) => card.id !== drawnCard.id
      )
      if (
        drawnCard.abilities.length > 0 &&
        (drawnCard.activatesOn.length === 0 ||
          drawnCard.activatesOn.includes("friendly") ||
          drawnCard.activatesOn.includes("myGraveyard"))
      ) {
        data.castingSpell = true
        data.spellCast = drawnCard
      } else if (
        drawnCard.abilities.length > 0 &&
        !drawnCard.abilities.includes("onKill") &&
        !drawnCard.abilities.includes("extraTurn")
      ) {
        data = data.resolveSpell(
          { ...data, myFunction: spellFunctions[drawnCard.abilities[0]] },
          false
        )
        data.castingSpell = false
        data.spellCast = null
      }
    }
    return data
  },
  aresCourage: (data) => {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      data.attackerCurrentBoard[i].defense += 2
    }
    return data
  },
  theaDivine: (data) => {
    data.attackerKill = false
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      data.attackerCurrentBoard[i].damageTaken > 7
        ? (data.attackerCurrentBoard[i].damageTaken -= 7)
        : (data.attackerCurrentBoard[i].damageTaken = 0)
    }
    data = data.calculateHealing(data, 7)
    data = data.boardAreaOfEffect(data, 4)
    data = data.resolveDamage(data, 4, "opponent")
    return data
  },
  theaDivineSpell: (data) => {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      data.attackerCurrentBoard[i].damageTaken > 5
        ? (data.attackerCurrentBoard[i].damageTaken -= 5)
        : (data.attackerCurrentBoard[i].damageTaken = 0)
    }
    data = data.calculateHealing(data, 5)
    data = data.boardAreaOfEffect(data, 3)
    data = data.resolveDamage(data, 3, "opponent")
    data = data.spellSplice(data)

    return data
  },
  crownOfAres: (data) => {
    data.attackerCurrentBoard[data.index].attack += 2
    data.attackerCurrentBoard[data.index].defense += 2
    data = data.spellSplice(data)
    return data
  },
  themisOfOrder: (data) => {
    if (data.defenderCurrentBoard.length > 0) {
      let theirBoard = [...data.defenderCurrentBoard]
      for (let i = 0; i < theirBoard.length; i++) {
        theirBoard[i].damageTaken = 0
        theirBoard[i].defense = 1
      }
      data.defenderCurrentBoard = [...theirBoard]
      return data
    }
  },
  themisOfOrderSpell: (data) => {
    if (data.defenderCurrentBoard.length > 0) {
      let theirBoard = [...data.defenderCurrentBoard]
      for (let i = 0; i < theirBoard.length; i++) {
        theirBoard[i].damageTaken = theirBoard[i].defense - 1
      }
      data.defenderCurrentBoard = [...theirBoard]
      data = data.spellSplice(data)
      return data
    }
  },
  themisOfLaw: (data) => {
    if (
      data.attackerCurrentBoard.length > 0 ||
      data.defenderCurrentBoard.length > 0
    ) {
      let myBoard = [...data.attackerCurrentBoard]
      let theirBoard = [...data.defenderCurrentBoard]
      for (let i = 0; i < myBoard.length; i++) {
        myBoard[i].damageTaken = 0
        myBoard[i].defense = 3
        myBoard[i].attack = 3
      }
      for (let i = 0; i < theirBoard.length; i++) {
        theirBoard[i].damageTaken = 0
        theirBoard[i].defense = 3
        theirBoard[i].attack = 3
      }
      data.attackerCurrentBoard = [...myBoard]
      data.defenderCurrentBoard = [...theirBoard]
    }
    return data
  },
  gaeasNourishment: (data) => {
    data.mana += 1
    data = data.calculateHealing(data, 3)
    data = data.spellSplice(data)
    return data
  },
  motherOfGeneration: (data) => {
    data = data.drawCards(data, 1)
    data.mana += 2
    data = data.spellSplice(data)
    return data
  },
  gaeasBount: (data) => {
    data.mana += 2
    data.myPlayer.defense += 5
    data = data.drawCards(data, 3)
    data = data.calculateHealing(data, 5)
    data = data.spellSplice(data)
    return data
  },
  motherOfGods: (data) => {
    const costReduce = (card) => {
      if (data.isAGod(card)) {
        card.cost = card.cost > 1 ? (card.cost -= 1) : card.cost
      }
      return card
    }

    data.myHand.map(costReduce)
    data.deck.map(costReduce)
    data.myCurrentGY.map(costReduce)
    data.attackerCurrentBoard.map(costReduce)

    return data
  },
  gaeaEarthQueen: (data) => {
    const suffixes = ["One", "Two", "Three"]

    const randomImageIndex = () => Math.floor(Math.random() * 3)

    const transformCard = (card) => {
      card.type = "creature"
      card.class = "monster"
      card.attack = card.cost > 0 ? card.cost : 1
      card.defense = card.cost > 0 ? card.cost : 1
      card.cost += 2
      const imageIndex = randomImageIndex()
      const suffix = suffixes[imageIndex]
      card.image = `earthCreature${suffix}`
    }

    const transformHand = (hand) => {
      hand.forEach((card) => {
        if (card.type === "spell" && card.class !== "incarnate") {
          transformCard(card)
        }
      })
    }

    const drawFromDeck = (deck) => {
      const sorceryCards = deck.filter(
        (card) => card.type === "spell" && card.class === "sorcery"
      )

      for (let i = 0; i < 4; i++) {
        if (!sorceryCards.length) break
        const randomIndex = Math.floor(Math.random() * sorceryCards.length)
        const card = data.findCard(data, sorceryCards[randomIndex].id)
        transformCard(card)
        sorceryCards.splice(randomIndex, 1)
      }
    }

    const drawFromGraveyard = (graveyard) => {
      const sorceryCards = graveyard.filter(
        (card) => card.type === "spell" && card.class === "sorcery"
      )

      for (let i = 0; i < 4; i++) {
        if (!sorceryCards.length) break
        const randomIndex = Math.floor(Math.random() * sorceryCards.length)
        const card = data.findCard(data, sorceryCards[randomIndex].id)
        transformCard(card)
        sorceryCards.splice(randomIndex, 1)
      }
    }

    transformHand(data.myHand)
    if (!data.drawFromGraveyard) {
      drawFromDeck(data.deck)
    } else {
      drawFromGraveyard(data.myCurrentGY)
    }

    return data
  },
  rheaLifegiver: (data) => {
    const returnedTarget = data.myCurrentGY.splice(data.index, 1)[0]
    data.myHand = [...data.myHand, returnedTarget]
    data = data.spellSplice(data)
    return data
  },
  discipleOfHades: (data) => {
    data = spellFunctions.rheaLifegiver(data)
    return data
  },
  gaeaDreamGiver: (data) => {
    const targetEffect = data.target
    if (
      targetEffect.abilities.length > 0 &&
      (targetEffect.activatesOn.length === 0 ||
        targetEffect.activatesOn.includes("friendly") ||
        targetEffect.activatesOn.includes("myGraveyard")) &&
      !targetEffect.abilities.includes("doNotCast")
    ) {
      data.castingSpell = true
      data.spellCast = targetEffect
    } else if (
      targetEffect.abilities.length > 0 &&
      !targetEffect.activatesOn.includes("onKill") &&
      !targetEffect.abilities.includes("odinAllfather") &&
      !targetEffect.abilities.includes("doNotCast")
    ) {
      data = data.resolveSpell(
        {
          ...data,
          creature: targetEffect,
          myFunction: spellFunctions[targetEffect.abilities[0]],
        },
        false
      )
      data.spellCast = null
      data.castingSpell = false
    }
    if (data.buffCard) {
      const gabriel = data.findCard(data, targetEffect.id)
      gabriel.attack += data.buffCard.attack
      gabriel.defense += data.buffCard.defense
    }
    return data
  },
  harpySwarm: (data) => {
    let newBoard = data.attackerCurrentBoard
    let newGY = data.myCurrentGY
    let newDeck = data.deck
    let newHand = data.myHand

    data.buffCard = { attack: 1, defense: 1 }

    const findHarpy = (card) => {
      if (card.name === "Harpy") {
        card.attack += 1
        card.defense += 1
      }
      return card
    }
    data.attackerCurrentBoard = newBoard.map(findHarpy)
    data.myCurrentGY = newGY.map(findHarpy)
    data.deck = newDeck.map(findHarpy)
    data.myHand = newHand.map(findHarpy)

    return data
  },
  nemeanLion: (data) => {
    if (data.theirPlayer.durability > 0) {
      data.theirPlayer.durability -= 1
      if (data.theirPlayer.durability === 0) {
        data.theirPlayer.block = 0
      }
    }
    data = data.boardAreaOfEffect(data, 1)
    return data
  },
  sirenSisters: (data) => {
    const sirenConstructor = () => {
      return {
        name: "Siren",
        type: "creature",
        class: "monster",
        attack: 3,
        defense: 4,
        damageTaken: 0,
        cost: 1,
        text: "Provoke",
        abilities: [],
        activatesOn: [],
        effects: ["provoke"],
        rarity: "common",
        id: data.randomId(data),
        image: "sirenTwinOne",
        mythology: "token",
        token: true,
      }
    }
    for (let i = 0; i < 2; i++) {
      data = data.playCreature({
        ...data,
        creature: sirenConstructor(),
        castSpell: false,
      })
    }

    data = data.spellSplice(data)
    return data
  },
  aphroditesCharm: (data) => {
    const charmedCreature = data.defenderCurrentBoard.splice(data.index, 1)[0]
    data = data.playCreature({
      ...data,
      creature: charmedCreature,
      castSpell: false,
    })
    return data
  },
  aphroditesCharmSpell: (data) => {
    const charmedCreature = data.defenderCurrentBoard.splice(data.index, 1)[0]
    data = data.playCreature({
      ...data,
      creature: charmedCreature,
      castSpell: false,
    })
    data = data.spellSplice(data)
    return data
  },
  stoneGaze: (data) => {
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data.defenderCurrentBoard[i].attack = 0
      if (!data.defenderCurrentBoard[i].effects.includes("provoke")) {
        const newText = data.defenderCurrentBoard[i].text.split("")
        newText.unshift("Provoke\n")
        data.defenderCurrentBoard[i].text = newText.join("")
        data.defenderCurrentBoard[i].effects.push("provoke")
      }
    }
    data.theirPlayer.poison += 2
    return data
  },
  stoneGazeSpell: (data) => {
    data.defenderCurrentBoard[data.index].attack = 0
    if (!data.defenderCurrentBoard[data.index].effects.includes("provoke")) {
      const newText = data.defenderCurrentBoard[data.index].text.split("")
      newText.unshift("Provoke\n")
      data.defenderCurrentBoard[data.index].text = newText.join("")
      data.defenderCurrentBoard[data.index].effects.push("provoke")
    }
    data = data.spellSplice(data)
    return data
  },
  charybdisJaws: (data) => {
    const charybdisID = data.mySpellCast.id
    data.creature = data.defenderCurrentBoard[data.index]
    data = data.killCreature({
      ...data,
      location: "defender",
      kill: true,
      card: data.defenderCurrentBoard[data.index],
    })
    data.kill = false
    if (
      data.card.abilities.length > 0 &&
      (data.card.activatesOn.length === 0 ||
        data.card.activatesOn.includes("friendly") ||
        data.card.activatesOn.includes("myGraveyard"))
    ) {
      data = data.handleCallStack(data, { ...data.creature, id: charybdisID })
      // data.castingSpell = true
      // data.spellCast = data.card
    } else if (
      data.card.abilities.length > 0 &&
      (!data.card.activatesOn.includes("onKill") ||
        !data.card.activatesOn.includes("spellCast") ||
        !data.card.activatesOn.includes("doNotCast") ||
        !data.card.activatesOn.includes("startTurnOnly") ||
        !data.card.activatesOn.includes("onAttack"))
    ) {
      data = data.resolveSpell(
        { ...data, myFunction: spellFunctions[data.card.abilities[0]] },
        false
      )
    }
    return data
  },
  sphinx: (data) => {
    const costReduce = (card) => {
      if (data.isAMonster(card)) {
        card.cost = card.cost > 1 ? (card.cost -= 1) : card.cost
      }
      return card
    }

    data.myHand.map(costReduce)
    data.deck.map(costReduce)
    data.myCurrentGY.map(costReduce)
    data.attackerCurrentBoard.map(costReduce)

    return data
  },
  satyr: (data) => {
    const oldHandSize = data.myHand.length
    let count = 0
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.isAMonster(data.attackerCurrentBoard[i])) {
        count++
      }
    }
    if (count > 0) {
      data = data.drawCards(data, count)
    }
    for(let i = oldHandSize; i < data.myHand.length; i++) {
      if(data.isAMonster(data.myHand[i])){
        data.myHand[i].attack += 1
        data.myHand[i].defense += 1
      }
    }
    return data
  },
  scylla: (data) => {
    data = data.calculateHealing(data, 6)
    let count = 6
    while (count > 0) {
      const randomTarget = Math.floor(
        Math.random() * (data.defenderCurrentBoard.length + 1)
      )
      if (randomTarget === data.defenderCurrentBoard.length) {
        data = data.resolveDamage(data, 1, "opponent")
        if (data.theirPlayer.durability > 0) {
          data.theirPlayer.durability -= 1
          if (data.theirPlayer.durability === 0) {
            data.theirPlayer.block = 0
          }
        }
      } else {
        if (
          data.defenderCurrentBoard[randomTarget].damageTaken <
          data.defenderCurrentBoard[randomTarget].defense
        ) {
          data.defenderCurrentBoard[randomTarget].damageTaken +=
            data.resolveDamage(
              data,
              1,
              "dontKill",
              false,
              false,
              data.defenderCurrentBoard[randomTarget]
            )
        } else {
          continue
        }
      }
      count--
    }
    data.attackerKill = false
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
      })
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }
    return data
  },
  lamia: (data) => {
    data = data.calculateHealing(data, 3, "opponent")
    return data
  },
  chimera: (data) => {
    let count = 0
    const hitIDs = []
    let opponentHit = 1
    while (count < 3 && count <= data.defenderCurrentBoard.length) {
      const randomTarget = Math.floor(
        Math.random() * (data.defenderCurrentBoard.length + opponentHit)
      )
      if (randomTarget === data.defenderCurrentBoard.length) {
        opponentHit--
        data = data.resolveDamage(data, 2, "opponent")
        count++
      } else if (!hitIDs.includes(data.defenderCurrentBoard[randomTarget].id)) {
        data.defenderCurrentBoard[randomTarget].damageTaken +=
          data.resolveDamage(
            data,
            2,
            "dontKill",
            false,
            false,
            data.defenderCurrentBoard[randomTarget]
          )
        hitIDs.push(data.defenderCurrentBoard[randomTarget].id)
        count++
      }
    }
    data.attackerKill = false
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
      })
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }
    return data
  },
  hector: (data) => {
    data.myPlayer.block += 2
    data.myPlayer.durability += 2
    data.card.attack += data.myPlayer.block
    data.card.defense += data.myPlayer.durability
    data.myPlayer.block = 0
    data.myPlayer.durability = 0
    return data
  },
  odysseus: (data) => {
    data.myPlayer.durability += 1
    data.myPlayer.block += 1
    return data
  },
  theseus: (data) => {
    data = { ...data.resolveDamage(data, data.myPlayer.block) }
    return data
  },
  hephaestusIncarnate: (data) => {
    data.myPlayer.userName = "Hephaestus"
    data.myPlayer.type = "self"
    data.myPlayer.abilities = [
      "volcanicEruption",
      "hephaestus'Forge",
      "hephaestus'Provision",
    ]
    data.myPlayer.abilityDescription = [
      "Kill a random creature on your opponents board",
      "+1 block/+2 Durability",
      "Give provoke creatures +1/+1",
    ]

    data = data.spellSplice(data)
    data.usedPlayerAbility = false
    return data
  },
  "hephaestus'Forge": (data) => {
    data.myPlayer.durability += 2
    data.myPlayer.block += 1
    return data
  },
  "hephaestus'Provision": (data) => {
    if (data.attackerCurrentBoard.length > 0) {
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        if (data.attackerCurrentBoard[i].effects.includes("provoke")) {
          data.attackerCurrentBoard[i].attack += 1
          data.attackerCurrentBoard[i].defense += 1
        }
      }
    }
    return data
  },
  volcanicEruption: (data) => {
    if (data.defenderCurrentBoard.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
        location: "defender",
      })
    }
    return data
  },
  discipleOfGaea: (data) => {
    data.myCurrentMana += 1
    data.myPlayer.defense += 1
    return data
  },
  thor: (data) => {
    if (data.target.attack > 0) {
      let count = 0
      let targets = 3
      const hitIDs = []
      const hitOpponent = []
      while (
        count < 3 &&
        targets > 0 &&
        count <= data.defenderCurrentBoard.length
      ) {
        const randomTarget = Math.floor(
          Math.random() * (data.defenderCurrentBoard.length + 1)
        )
        if (randomTarget === data.defenderCurrentBoard.length) {
          if (hitOpponent[0] !== "opponent") {
            data = data.resolveDamage(data, data.target.attack, "opponent")
            hitOpponent.unshift("opponent")
            count++
            targets--
          } else {
            continue
          }
        } else if (
          !hitIDs.includes(data.defenderCurrentBoard[randomTarget].id)
        ) {
          data.defenderCurrentBoard[randomTarget].damageTaken +=
            data.resolveDamage(
              data,
              data.target.attack,
              "dontKill",
              false,
              false,
              data.defenderCurrentBoard[randomTarget]
            )
          hitIDs.push(data.defenderCurrentBoard[randomTarget].id)
          if (
            data.defenderCurrentBoard[randomTarget].damageTaken >=
            data.defenderCurrentBoard[randomTarget].defense
          ) {
            data = data.killCreature({
              ...data,
              card: data.defenderCurrentBoard[randomTarget],
              location: "defender",
            })
            count--
            targets--
          }
          count++
        }
      }
    }
    return data
  },
  underworldUnleashed: (data) => {
    if (data.location === "attacker") {
      data.drawFromGraveyard = true
      data.myCurrentGY = [
        ...data.deck.splice(0, data.deck.length),
        ...data.myCurrentGY,
      ]
    } else {
      data.opponentDrawFromGraveyard = true
      data.theirCurrentGY = [
        ...data.theirDeck.splice(0, data.theirDeck.length),
        ...data.theirCurrentGY,
      ]
    }

    return data
  },
  discipleOfHephaestus: (data) => {
    data.myPlayer.block += 1
    data.myPlayer.durability += 3
    return data
  },
  vikingIncursionDraw: (data) => {
    data = data.drawCards(data, 2)
    data = data.spellSplice(data)
    return data
  },
  vikingIncursionBurn: (data) => {
    data.theirPlayer.damageTaken += 6
    data = data.spellSplice(data)
    return data
  },
  vikingIncursionDiscard: (data) => {
    data = data.discardCards(data, 2, true, "opponent")
    data = data.spellSplice(data)
    return data
  },
  tributeSacrifice: (data) => {
    if (data.defenderCurrentBoard.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        kill: true,
        card: data.defenderCurrentBoard[randomIndex],
      })
    }
    data = data.spellSplice(data)
    return data
  },
  tributeDiscard: (data) => {
    data = data.discardCards(data, 2, true, "opponent")
    data = data.spellSplice(data)
    return data
  },
  tributeHealDraw: (data) => {
    data = data.calculateHealing(data, 5)
    data = data.drawCards(data, 2)
    data = data.spellSplice(data)
    return data
  },
  draugr: (data) => {
    if (data.location === "attacker") {
      data = data.drawCards(data, 1)
    } else if (data.location === "defender") {
      data = data.drawCards(data, 1, "opponent")
    }
    return data
  },
  fenrirSpawn: (data) => {
    for (let i = 0; i < 2; i++) {
      if (data.theirCurrentGY.length > 0) {
        const randomIndex = Math.floor(
          Math.random() * data.theirCurrentGY.length
        )
        const exiledCard = data.theirCurrentGY.splice(randomIndex, 1)[0]
        if (
          exiledCard.type === "creature" ||
          exiledCard.type === "spellcaster"
        ) {
          if (data?.buffCard) {
            data.buffCard.attack += 1
            data.buffCard.defense += 1
          } else {
            data.buffCard = { attack: 1, defense: 1 }
          }
        } else if (exiledCard) {
          data = data.resolveDamage(data, 2, "opponent", false)
          data = data.calculateHealing(data, 2)
        }
      }
    }
    return data
  },
  ragnarok: (data) => {
    const skroll = {
      name: "Skroll",
      type: "creature",
      class: "monster",
      attack: 8,
      defense: 8,
      damageTaken: 0,
      cost: 8,
      text: "",
      abilities: [],
      activatesOn: [],
      effects: [],
      rarity: "mythic",
      id: Math.floor(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      ),
      image: "skroll",
      mythology: "token",
      token: true,
    }
    const hati = {
      name: "Hati",
      type: "creature",
      class: "monster",
      attack: 8,
      defense: 8,
      damageTaken: 0,
      cost: 8,
      text: "",
      abilities: [],
      activatesOn: [],
      effects: [],
      rarity: "mythic",
      id: Math.floor(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      ),
      image: "hati",
      mythology: "token",
      token: true,
    }
    const originalLength = data.attackerCurrentBoard.length
    let count = 0
    let index = 0
    const ragnarokThreeAttacker = {
      "fenrir, world eater": false,
      skroll: false,
      hati: false,
    }
    const ragnarokThreeDefender = {
      "fenrir, world eater": false,
      skroll: false,
      hati: false,
    }
    while (count < originalLength) {
      if (
        data.attackerCurrentBoard[index].name === "Skroll" ||
        data.attackerCurrentBoard[index].name === "Hati" ||
        data.attackerCurrentBoard[index].name === "Fenrir, World Eater"
      ) {
        ragnarokThreeAttacker[
          data.attackerCurrentBoard[index].name.toLowerCase()
        ] = true
        index++
      } else {
        data = data.killCreature({
          ...data,
          location: "attacker",
          card: data.attackerCurrentBoard[index],
          kill: true,
        })
      }
      count++
    }
    const theirOriginalLength = data.defenderCurrentBoard.length
    count = 0
    index = 0
    while (count < theirOriginalLength) {
      if (
        data.defenderCurrentBoard[index].name === "Skroll" ||
        data.defenderCurrentBoard[index].name === "Hati" ||
        data.defenderCurrentBoard[index].name === "Fenrir, World Eater"
      ) {
        ragnarokThreeDefender[
          data.defenderCurrentBoard[index].name.toLowerCase()
        ] = true
        index++
      } else {
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[index],
          kill: true,
        })
      }
      count++
    }
    data = data.spellSplice(data)
    if (
      ragnarokThreeAttacker["fenrir, world eater"] &&
      ragnarokThreeAttacker.skroll &&
      ragnarokThreeAttacker.hati
    ) {
      data.theirPlayer.damageTaken = data.theirPlayer.defense
    } else if (ragnarokThreeAttacker["fenrir, world eater"]) {
      data = data.playCreature({
        ...data,
        location: "attacker",
        creature: skroll,
        castSpell: false,
      })
      data = data.playCreature({
        ...data,
        location: "attacker",
        creature: hati,
        castSpell: false,
      })
    }
    if (
      ragnarokThreeDefender["fenrir, world eater"] &&
      ragnarokThreeDefender.skroll &&
      ragnarokThreeDefender.hati
    ) {
      data.myPlayer.damageTaken = data.myPlayer.defense
    } else if (ragnarokThreeDefender["fenrir, world eater"]) {
      data = data.playCreatureOnOpponentBoard({
        ...data,
        location: "defender",
        creature: skroll,
        castSpell: false,
      })
      data = data.playCreatureOnOpponentBoard({
        ...data,
        location: "defender",
        creature: hati,
        castSpell: false,
      })
    }
    return data
  },
  hela: (data) => {
    data = data.discardCards(
      data,
      data.attackerCurrentBoard.length,
      true,
      "self"
    )
    // if (data.myHand.length === 0) {
      data.attackerCurrentBoard = data.attackerCurrentBoard.map((card) => {
        card.attack += 1
        card.defense += 1
        return card
      })
    // }
    return data
  },
  mjollnir: (data) => {
    let thorFound = false
    while (!thorFound) {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.deck[i].name === "Thor Stormbreaker") {
          const thor = data.deck.splice(i, 1)[0]
          thor.attack += 4
          thor.defense += 2
          data.myHand = [...data.myHand, thor]
          thorFound = true
        }
      }
      if (thorFound) {
        break
      }
      for (let i = 0; i < data.myHand.length; i++) {
        if (data.myHand[i].name === "Thor Stormbreaker") {
          data.myHand[i].attack += 4
          data.myHand[i].defense += 2
          thorFound = true
        }
      }
      if (thorFound) {
        break
      }
      for (let i = 0; i < data.myCurrentGY.length; i++) {
        if (data.myCurrentGY[i].name === "Thor Stormbreaker") {
          const thor = data.myCurrentGY.splice(i, 1)[0]
          thor.attack += 4
          thor.defense += 2
          data.myHand = [...data.myHand, thor]
          thorFound = true
        }
      }
      if (thorFound) {
        break
      }
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        if (data.attackerCurrentBoard[i].name === "Thor Stormbreaker") {
          data.attackerCurrentBoard[i].attack += 4
          data.attackerCurrentBoard[i].defense += 2
          thorFound = true
        }
      }
      break
    }
    data = data.spellSplice(data)
    return data
  },
  thorsLightning: (data) => {
    if (data.defenderCurrentBoard.length > 0) {
      let count = 0
      let activatesOn = 2
      const hitIDs = []
      while (
        count < 2 &&
        activatesOn > 0 &&
        count < data.defenderCurrentBoard.length
      ) {
        const randomTarget = Math.floor(
          Math.random() * data.defenderCurrentBoard.length
        )
        if (!hitIDs.includes(data.defenderCurrentBoard[randomTarget].id)) {
          data.defenderCurrentBoard[randomTarget].damageTaken +=
            data.resolveDamage(
              data,
              5,
              "dontKill",
              false,
              false,
              data.defenderCurrentBoard[randomTarget]
            )
          hitIDs.push(data.defenderCurrentBoard[randomTarget].id)
          if (
            data.defenderCurrentBoard[randomTarget].damageTaken >=
            data.defenderCurrentBoard[randomTarget].defense
          ) {
            data = data.killCreature({
              ...data,
              card: data.defenderCurrentBoard[randomTarget],
              location: "defender",
            })
            count--
            activatesOn--
          }
          count++
        }
      }
    }
    const cardCost = data.mySpellCast.cost
      if (data.mySpellCast.type === "spell") {
        const {...myCastedSpell} = data.findCard(data, data.mySpellCast.id)
        myCastedSpell.id = data.randomId(data)
        data.mySpellCast.token = true
        const originalCard = data.findCard(data, data.mySpellCast.id)
        originalCard.token = true
        myCastedSpell.name = "Thor's Wrath"
        myCastedSpell.type = "spell"
        myCastedSpell.class = "sorcery"
        myCastedSpell.cost = cardCost + 4
        myCastedSpell.text =
          "Deal 7 damage to a random enemy target then bounce to a new enemy target for 1 less damage until damage reaches 0 or no targets are left to bounce to"
        myCastedSpell.abilities = ["thorsWrath"]
        myCastedSpell.activatesOn = ["noTarget"]
        myCastedSpell.effects = []
        myCastedSpell.image = "thorsWrath"
        
        data.myHand.push(myCastedSpell)
      }
    data = data.spellSplice(data)
    return data
  },
  thorsWrath: (data, damage = 7, previousTarget) => {
    // Filter out the previous target to ensure it doesn't get hit again
    const availableTargets = data.defenderCurrentBoard.filter(
      (card) => card.id !== previousTarget?.id
    )
    const canHitFace = previousTarget?.type === "opponent" ? 0 : 1

    // If there are no available targets and the opponent's face can't be hit, return the data
    if (availableTargets.length === 0 && canHitFace === 0) {
      data = data.spellSplice(data)
      return data
    }

    // Determine the target: either an opponent's face or one of the available targets
    const targetIndex = Math.floor(
      Math.random() * (availableTargets.length + canHitFace)
    )

    if (targetIndex === availableTargets.length && canHitFace === 1) {
      data = data.resolveDamage(data, damage, "opponent")
      previousTarget = { type: "opponent" }
    } else {
      const enemyTarget = availableTargets[targetIndex]

      data.target = enemyTarget
      enemyTarget.damageTaken += data.resolveDamage(
        data,
        damage,
        "dontKill",
        false,
        false,
        enemyTarget
      )
      data = data.killCreature({
        ...data,
        location: "defender",
        card: enemyTarget,
      })
      previousTarget = enemyTarget
    }

    if (
      damage > 1 &&
      (data.defenderCurrentBoard.length > 0 ||
        previousTarget.type !== "opponent")
    ) {
      data = spellFunctions["thorsWrath"](data, damage - 1, previousTarget)
    } else {
      data = data.spellSplice(data)
    }

    return data
  },
  bargainWithHel: (data) => {
    if (!data?.handleMyGraveyard) {
      if (data.deck.length > 4) {
        data.myCurrentGY = [...data.deck.splice(0, 5), ...data.myCurrentGY]
      } else {
        data.myCurrentGY = [
          ...data.deck.splice(0, data.deck.length),
          ...data.myCurrentGY,
        ]
      }
      data = data.handleCallStack(data, data.mySpellCast)
      data = data.spellSplice(data)
    } else {
      if (data.target.name !== "Bargain with Hel") {
        const returnedTarget = data.myCurrentGY.splice(data.index, 1)[0]
        data.myHand = [...data.myHand, returnedTarget]
      }
    }

    return data
  },
  cunningTrickster: (data) => {
    data = data.discardCards(data, 3, true, "self")
    data = data.drawCards(data, 3)
    data = data.spellSplice(data)
    return data
  },
  niflheim: (data) => {
    if (data.castAbility) {
      data = data.drawCards(data, 1)
      data = data.spellSplice(data)
    } else {
      if (data.target.construction < 3) {
        data.myCurrentGY[data.index].construction += 1
      } else {
        data = data.drawCards(data, 1)
      }
    }

    return data
  },
  midgard: (data) => {
    data.myCurrentMana += 1
    if (data.target.construction < 4) {
      data.myCurrentGY[data.index].construction += 1
    } else {
      data.myCurrentMana += 1
    }
    return data
  },
  discipleOfHel: (data) => {
    data.theirCurrentGY.splice(0, 1)
    data = data.calculateHealing(data, 2)
    return data
  },
  eir: (data) => {
    if (!data.startTurn) {
      data.myPlayer.poison > 2
        ? (data.myPlayer.poison -= 2)
        : (data.myPlayer.poison = 0)
      data.theirPlayer.durability > 2
        ? (data.theirPlayer.durability -= 2)
        : (data.theirPlayer.durability = 0)
      data.theirPlayer.block =
        data.theirPlayer.durability === 0 ? 0 : data.theirPlayer.block
      data = data.calculateHealing(data, 2)
      for (let i = 0; i < 2; i++) {
        if (data.theirCurrentGY.length > 0) {
          data.theirCurrentGY.splice(0, 1)
        }
      }
    } else {
      data.myPlayer.poison > 1
        ? (data.myPlayer.poison -= 1)
        : (data.myPlayer.poison = 0)
      data.theirPlayer.durability > 2
        ? (data.theirPlayer.durability -= 2)
        : (data.theirPlayer.durability = 0)
      data.theirPlayer.block =
        data.theirPlayer.durability === 0 ? 0 : data.theirPlayer.block
      data = data.calculateHealing(data, 2)
      for (let i = 0; i < 2; i++) {
        if (data.theirCurrentGY.length > 0) {
          data.theirCurrentGY.splice(0, 1)
        }
      }
    }

    return data
  },
  tyr: (data) => {
    data = data.calculateHealing(data, data.attacker.attack)
    data = data.resolveDamage(data, 3, "opponent")
    return data
  },
  jormungandr: (data) => {
    data.theirPlayer.poison += 4
    return data
  },
  odinAllfather: (data) => {
    const theirOldHand = data.theirHand.splice(0)
    const theirOldGraveyard = data.theirCurrentGY.splice(0)
    const theirOldBoard = data.defenderCurrentBoard.splice(0)
    const theirOldDeck = data.theirDeck
    const myOldBoard = data.attackerCurrentBoard.splice(0)
    const myOldHand = data.myHand.splice(0)
    const myOldGraveyard = data.myCurrentGY.splice(0)
    const myOldDeck = data.deck
    const myNewDeck = (board, hand, graveyard, deck) => {
      const unshuffledDeck = [...board, ...hand, ...graveyard, ...deck]
      const shuffledDeck = []
      while (unshuffledDeck.length > 0) {
        const randomIndex = Math.floor(Math.random() * unshuffledDeck.length)
        shuffledDeck.push(unshuffledDeck.splice(randomIndex, 1)[0])
      }
      return shuffledDeck
    }
    data.onDeathGlobals = []
    data.opponentDeathGlobals = []
    data.deck = myNewDeck(myOldBoard, myOldHand, myOldGraveyard, myOldDeck)
    data.theirDeck = myNewDeck(
      theirOldBoard,
      theirOldHand,
      theirOldGraveyard,
      theirOldDeck
    )
    data.myPlayer = {
      ...data.myPlayer,
      defense: 30,
      damageTaken: 0,
      type: "self",
      block: 0,
      durability: 0,
      poison: 0,
      revitalize: 0,
      spellsCasted: 0,
      healAbsorb: 0,
      sacrifices: 0,
    }
    data.theirPlayer = {
      ...data.theirPlayer,
      defense: 30,
      damageTaken: 0,
      type: "opponent",
      block: 0,
      durability: 0,
      poison: 0,
      revitalize: 0,
      spellsCasted: 0,
      healAbsorb: 0,
      sacrifices: 0,
    }
    data.drawFromGraveyard = false
    data.opponentDrawFromGraveyard = false
    data.myKingdomOfHeaven = false
    data.opponentKingdomOfHeaven = false
    data = data.removeGlobals(data, "allGlobals")
    data = data.drawCards(data, 3, "both")
    return data
  },
  vidar: (data) => {
    data.attackerKill = false
    const damage =
      Math.ceil(data.myPlayer.damageTaken / data.defenderCurrentBoard.length) ||
      0
    // const values = data.defenderCurrentBoard.reduce((acc, card) =>  {
    //     card.damageTaken += data.resolveDamage(data, damage, 'dontKill')
    //     if(card.damageTaken >= card.defense){
    //       card.damageTaken = 0
    //       acc.destroyedCards.push(card)
    //     }else{
    //       acc.newBoard.push(card)
    //     }
    //     return acc
    //   }, {newBoard: [], destroyedCards: []})
    //   data.defenderCurrentBoard = [...values.newBoard]
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data.defenderCurrentBoard[i].damageTaken += data.resolveDamage(
        data,
        damage,
        "dontKill",
        false,
        false,
        data.defenderCurrentBoard[i]
      )
    }
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
      })
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }
    return data
  },
  dreadBiter: (data) => {
    data.theirPlayer.poison += 2
    return data
  },
  headOfMimir: (data) => {
    let incarnateFound = false
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.deck[i].rarity === "omnipotent") {
          data.myHand = [...data.myHand, data.deck.splice(i, 1)[0]]
          incarnateFound = true
          break
        }
      }
    } else {
      for (let i = 0; i < data.myCurrentGY.length; i++) {
        if (data.myCurrentGY[i].rarity === "omnipotent") {
          data.myHand = [...data.myHand, data.myCurrentGY.splice(i, 1)[0]]
          incarnateFound = true
          break
        }
      }
    }
    if (!incarnateFound) {
      data = data.drawCards(data, 1)
    }
    data = data.spellSplice(data)
    return data
  },
  emptyTheHalls: (data) => {
    const values = data.myCurrentGY.reduce(
      (acc, card) => {
        if (
          card.cost <= 2 &&
          (card.type === "creature" || card.type === "spellcaster")
        ) {
          data = data.resolveDamage(data, 1, "self")
          acc.newBoard.push(card)
          if (!card.effects.includes("haste")) {
            data.attacked.push(card)
          } else {
            for (let i = 0; i < data.attacked.length; i++) {
              if (data.attacked[i].id === card.id) {
                data.attacked.splice(i, 1)
              }
            }
          }
        } else {
          acc.newGraveyard.push(card)
        }
        return acc
      },
      { newGraveyard: [], newBoard: [] }
    )
    for (let i = 0; i < values.newBoard.length; i++) {
      data = data.playCreature({
        ...data,
        creature: values.newBoard[i],
        castSpell: false,
      })
    }
    // data.attackerCurrentBoard = [...data.attackerCurrentBoard,...values.newBoard]
    data.myCurrentGY = [...values.newGraveyard]
    data = data.spellSplice(data)
    return data
  },
  cascadingMagic: (data) => {
    data = data.resolveDamage(
      data,
      data.myPlayer.spellsCasted > 8 ? data.myPlayer.spellsCasted - 8 : 0
    )
    data = data.spellSplice(data)

    return data
  },
  banish: (data) => {
    if (data.target.cost <= 5) {
      const removedCreature = data.defenderCurrentBoard.splice(data.index, 1)[0]
      data = data.calculateHealing(data, 5, "opponent")
      data = data.spellSplice(data)
      data = data.removeGlobals(data, "targetedGlobal", removedCreature)
    }

    return data
  },
  gefionsGamble: (data) => {
    const handSize = data.myHand.length
    data = data.spellSplice(data)
    data = data.discardCards(data, handSize, true, "self")
    data = data.resolveDamage(data, handSize, "self")
    data = data.drawCards(data, handSize)
    
    return data
  },
  fenrir: (data) => {
    const ragnarok = {
      name: "Ragnarok",
      type: "spell",
      class: "sorcery",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 6,
      text: "Destroy all minions",
      abilities: ["ragnarok"],
      activatesOn: ["noTarget"],
      effects: [],
      rarity: "legendary",
      id: Math.floor(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      ),
      image: "ragnarok",
      mythology: "norse",
    }
    if (data.location === "attacker") {
      data.attackerCurrentBoard[data.attacker.index].attack += 3
      data.attackerCurrentBoard[data.attacker.index].defense += 3
      if (data.attackerCurrentBoard[data.attacker.index].attack >= 12) {
        data.attackerCurrentBoard[data.attacker.index].image =
          "fenrirWorldEater"
        data.attackerCurrentBoard[data.attacker.index].name =
          "Fenrir, World Eater"
        data.attackerCurrentBoard[data.attacker.index].effects = []
        data.attackerCurrentBoard[data.attacker.index].abilities = []
        data.attackerCurrentBoard[data.attacker.index].effects = ["haste"]
        data.attackerCurrentBoard[data.attacker.index].text =
          data.attackerCurrentBoard[data.attacker.index].text.replace(
            "Haste\nOn Kill: Deal 5 damage to your opponent and gain +3/+3\nTransform: This card has 12 attack transform into Fenrir, World Eater and remove all damage taken. Then add Ragnarok to your hand.",
            "Cannot be destroyed by Ragnarok\nHaste\nIf Ragnarok is cast while you control this card summon Skroll and Hati. If Ragnarok is cast while you control this card, Skroll, and Hati, you win the game."
          )
        data.attackerCurrentBoard[data.attacker.index].attack = 15
        data.attackerCurrentBoard[data.attacker.index].defense = 15
        data.attackerCurrentBoard[data.attacker.index].damageTaken = 0
        data.myHand.push(ragnarok)
      }
      data.theirPlayer.damageTaken += 5
    } else {
      data.defenderCurrentBoard[data.index].attack += 3
      data.defenderCurrentBoard[data.index].defense += 3
      if (data.defenderCurrentBoard[data.index].attack >= 12) {
        data.defenderCurrentBoard[data.index].image = "fenrirWorldEater"
        data.defenderCurrentBoard[data.index].name = "Fenrir, World Eater"
        data.defenderCurrentBoard[data.index].effects = ["haste"]
        data.defenderCurrentBoard[data.index].abilities = []
        data.defenderCurrentBoard[data.index].effects = []
        data.defenderCurrentBoard[data.index].text = data.defenderCurrentBoard[
          data.index
        ].text.replace(
          "Haste\nOn Kill: Deal 5 damage to your opponent and gain +3/+3\nTransform: This card has 12 attack transform into Fenrir, World Eater and remove all damage taken. Then add Ragnarok to your hand.",
          "Cannot be destroyed by Ragnarok\nHaste\nIf Ragnarok is cast while you control this card summon Skroll and Hati. If Ragnarok is cast while you control this card, Skroll, and Hati, you win the game."
        )
        data.defenderCurrentBoard[data.index].attack = 15
        data.defenderCurrentBoard[data.index].defense = 15
        data.defenderCurrentBoard[data.index].damageTaken = 0
        data.theirHand.push(ragnarok)
        data.myPlayer.damageTaken += 5
      }
    }
    return data
  },
  cthulu: (data) => {
    const firstPieceOfRlyeh = {
      name: "First piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: All minions on your board take 1 damage",
      abilities: ["firstRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "firstPiece",
      mythology: "lovecraft",
    }
    const secondPieceOfRlyeh = {
      name: "Second piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: Your opponent drains you for 3",
      abilities: ["secondRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "secondPiece",
      mythology: "lovecraft",
    }
    const thirdPieceOfRlyeh = {
      name: "Third piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: Your player receives 2 poison at the start of turn",
      abilities: ["thirdRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "thirdPiece",
      mythology: "lovecraft",
    }
    const fourthPieceOfRlyeh = {
      name: "Fourth piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: Your opponent summons a random minion from their graveyard to the board",
      abilities: ["fourthRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "fourthPiece",
      mythology: "lovecraft",
    }
    const fifthPieceOfRlyeh = {
      name: "Fifth piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: The sunken city rises if all five pieces are in the graveyard and releases C'Thulu from his prison if he is on the board or when he is played",
      abilities: [],
      activatesOn: [],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "fifthPiece",
      mythology: "lovecraft",
    }
    const piecesOfRlyeh = [
      firstPieceOfRlyeh,
      secondPieceOfRlyeh,
      thirdPieceOfRlyeh,
      fourthPieceOfRlyeh,
      fifthPieceOfRlyeh,
    ]
    let piecesFound = [false, false, false, false, false]
    for (let i = 0; i < data.theirCurrentGY.length; i++) {
      if (data.theirCurrentGY.length === 0) {
        break
      }
      const cardName = data.theirCurrentGY[i].name
      if (cardName.includes("First piece of R'lyeh")) {
        piecesFound[0] = true
      }
      if (cardName.includes("Second piece of R'lyeh")) {
        piecesFound[1] = true
      }
      if (cardName.includes("Third piece of R'lyeh")) {
        piecesFound[2] = true
      }
      if (cardName.includes("Fourth piece of R'lyeh")) {
        piecesFound[3] = true
      }
      if (cardName.includes("Fifth piece of R'lyeh")) {
        piecesFound[4] = true
      }
    }
    for (let i = 0; i < piecesFound.length; i++) {
      if (piecesFound[i] === false) {
        if (i === 4) {
          piecesFound[4] = true
        }
        data.theirCurrentGY.unshift(piecesOfRlyeh[i])
        break
      }
    }
    for (let i = 0; i <= piecesFound.length; i++) {
      if (i === piecesFound.length) {
        data.attackerCurrentBoard = data.attackerCurrentBoard.filter(
          (card) => card.name !== "C'Thulu"
        )
        data.myPlayer.userName = "C'Thulu"
        data.myPlayer.type = "self"
        data.myPlayer.defense += 15
        data.myPlayer.abilities = [
          "siphonWorld",
          "deliverMadness",
          "corruptionTendrils",
        ]
        data.myPlayer.abilityDescription = [
          "Drain 3 from all enemies",
          "Draw 2 cards from your opponents hand",
          "Poison your opponent for 2 then drain them for their poison amount",
        ]
        data.usedPlayerAbility = false
        for (let i = 0; i < data.theirCurrentGY.length; i++) {
          if (data.theirCurrentGY[i].name.includes("piece of R'lyeh")) {
            data.theirCurrentGY.splice(i, 1)
            i--
          }
        }
        data.dontPlayCreature = true
      }
      if (piecesFound[i] === true) {
        continue
      } else {
        break
      }
    }
    return data
  },
  firstRlyeh: (data) => {
    data = data.boardAreaOfEffect(data, 1, "self")
    return data
  },
  secondRlyeh: (data) => {
    data.myPlayer.damageTaken += 3
    data.theirPlayer.damageTaken > 3
      ? (data.theirPlayer.damageTaken -= 3)
      : (data.theirPlayer.damageTaken = 0)
    return data
  },
  thirdRlyeh: (data) => {
    data.myPlayer.poison += 2
    return data
  },
  fourthRlyeh: (data) => {
    const values = data.theirCurrentGY.filter(
      (card) => card.type === "creature" || card.type === "spellcaster"
    )
    const randomIndex = Math.floor(Math.random() * values.length)
    const revivedCard = values.splice(randomIndex, 1)[0]
    if (revivedCard) {
      data.theirCurrentGY = data.theirCurrentGY.filter(
        (card) => revivedCard?.id !== card.id
      )
      data.defenderCurrentBoard = [...data.defenderCurrentBoard, revivedCard]
    }
    return data
  },
  siphonWorld: (data) => {
    data = data.calculateHealing(data, 3 + data.defenderCurrentBoard.length * 3)
    data = data.boardAreaOfEffect(data, 3)
    data = data.resolveDamage(data, 3, "opponent")
    return data
  },
  deliverMadness: (data) => {
    let count = 2
    while (count > 0 && data.theirHand.length > 0) {
      const randomIndex = Math.floor(Math.random() * data.theirHand.length)
      data.myHand.push(data.theirHand.splice(randomIndex, 1)[0])
      count--
    }
    return data
  },
  corruptionTendrils: (data) => {
    data.theirPlayer.poison += 2
    data.theirPlayer.damageTaken += data.theirPlayer.poison
    data.myPlayer.damageTaken =
      data.myPlayer.damageTaken > data.theirPlayer.poison
        ? (data.myPlayer.damageTaken -= data.theirPlayer.poison)
        : (data.myPlayer.damageTaken = 0)
    return data
  },
  himinbjorg: (data) => {
    data.myPlayer.block += 2
    data.myPlayer.durability += 3
    return data
  },
  troy: (data) => {
    const block = Math.ceil(data.myPlayer.block / 2) || 0
    const durability = Math.ceil(data.myPlayer.durability / 2) || 0
    if (block + durability > 0) {
      data.attackerKill = false
      data.myPlayer.block = Math.ceil(data.myPlayer.block / 2)
      data.myPlayer.durability = Math.ceil(data.myPlayer.durability / 2)
      data = data.boardAreaOfEffect(data, block + durability)
    }
    data = data.resolveDamage(data, block + durability, "opponent", false)
    return data
  },
  asgard: (data) => {
    data.myPlayer.defense += data.target.construction
    if (data.target.construction < 4) {
      data.myCurrentGY[data.index].construction += 1
    }
    if (data.target.construction === 4) {
      data = data.drawCards(data, 1)
    }
    return data
  },
  recklessAbandon: (data) => {
    data = data.resolveDamage(data, 8, "self")
    data = data.drawCards(data, 4)

    data = data.spellSplice(data)
    return data
  },
  naturesCure: (data) => {
    if (data.myPlayer.poison) {
      const removedPoison = Math.ceil(data.myPlayer.poison / 2)
      data.myPlayer.poison -= removedPoison
      data = data.calculateHealing(data, removedPoison * 2)
    }
    data = data.spellSplice(data)
    return data
  },
  crumplingDefense: (data) => {
    const provokeReduction = (card) => {
      if (card.effects.includes("provoke")) {
        card.attack = 0
      }
      return card
    }
    data.defenderCurrentBoard = data.defenderCurrentBoard.map((card) =>
      provokeReduction(card)
    )
    data.attackerCurrentBoard = data.attackerCurrentBoard.map((card) =>
      provokeReduction(card)
    )
    if (data.theirPlayer.durability) {
      const blockDamage = Math.floor(data.theirPlayer.block / 2)
      const durabilityDamage = Math.floor(data.theirPlayer.durability / 2)
      data.theirPlayer.damageTaken += blockDamage + durabilityDamage
      data.theirPlayer.block -= blockDamage
      data.theirPlayer.durability -= durabilityDamage
      if (data.theirPlayer.durability === 0) {
        data.theirPlayer.block = 0
      }
    }
    data = data.spellSplice(data)
    return data
  },
  heimdallEagleEye: (data) => {
    if (data.target.effects.includes("provoke")) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[data.index],
        kill: true,
      })
      data.theirPlayer.damageTaken += data.card.cost
    }
    return data
  },
  heimdallTheBrightGod: (data) => {
    data.myPlayer.defense += 5
    const damage = Math.floor(data.myPlayer.defense / 6)
    data = data.resolveDamage(data, damage)
    return data
  },
  godOfDisease: (data) => {
    if (!data.startTurn) {
      data.theirPlayer.poison += 2
      data.theirPlayer.healAbsorb += 6
    } else {
      data.theirPlayer.poison += 1
      data.theirPlayer.healAbsorb += 2
    }

    return data
  },
  blightspawn: (data) => {
    if (!data.startTurn) {
      data.theirPlayer.healAbsorb += 5
    } else {
      data.theirPlayer.healAbsorb += 3
    }

    return data
  },
  loki: (data) => {
    if (data.counterSpellFunction) {
      data = data.calculateHealing(data, 4, "opponent")
      data = data.drawCards(data, 1)
      data = data.spellSplice(data)
      delete data.counterSpellFunction
    } else {
      data.opponentsCountersArray.unshift(data.card)
      data.opponentsCountersArray.unshift(data.card)
    }
    return data
  },
  lokiDeceit: (data) => {
    if (data.counterSpellFunction) {
      if (data.myHand.length >= 1) {
        data = data.spellSplice(data)
        if (data.myHand.length > 0) {
          data = data.discardCards(data, 1, true, "self")
        }
      }
      delete data.counterSpellFunction
    } else {
      data.opponentsCountersArray.unshift(data.mySpellCast)
      data = data.spellSplice(data)
    }
    return data
  },
  lokiTrickery: (data) => {
    if (data.counterSpellFunction) {
      data = data.resolveDamage(data, 4, "self")
      data = data.spellSplice(data)
      delete data.counterSpellFunction
    } else {
      data.opponentsCountersArray.unshift(data.mySpellCast)
      data = data.spellSplice(data)
    }
    return data
  },
  volsung: (data) => {
    for (let i = 0; i < data.myCurrentGY.length; i++) {
      if (data.myCurrentGY[i].class === "realm") {
        if (data.buffCard) {
          data.buffCard.attack += 2
          data.buffCard.defense += 2
        } else {
          data.buffCard = { attack: 2, defense: 2 }
        }
        data.target = data.myCurrentGY[i]
        data.index = i
        data = spellFunctions[data.myCurrentGY[i].abilities[0]](data)
      }
    }
    return data
  },
  astralShift: (data) => {
    let realmFound = false
    for (let i = 0; i < data.deck.length; i++) {
      if (data.deck[i].class === "realm") {
        data.myCurrentGY = [data.deck.splice(i, 1)[0], ...data.myCurrentGY]
        realmFound = true
        break
      }
    }
    if (!realmFound) data = data.drawCards(data, 1)
    data = data.spellSplice(data)
    return data
  },
  hlin: (data) => {
    const buffedAttacker = data.attackerCurrentBoard[data.index]
    buffedAttacker.attack += 1
    buffedAttacker.defense += 1
    let gods = 0
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.isAGod(data.attackerCurrentBoard[i])) {
        gods += 1
      }
    }
    if (data.isAGod(buffedAttacker)) {
      buffedAttacker.attack += gods
      buffedAttacker.defense += gods
    }
    if (!buffedAttacker.effects.includes("provoke")) {
      buffedAttacker.effects.push("provoke")
      const newText = buffedAttacker.text.split("")
      newText.unshift("Provoke\n")
      buffedAttacker.text = newText.join("")
    }
    data.attackerCurrentBoard[data.index] = buffedAttacker
    return data
  },
  tyrBattleGod: (data) => {
    const tyrIndex = data.attackerCurrentBoard.findIndex(
      (card) => card.id === data.mySpellCast.id
    )
    data = data.resolveDamage(
      data,
      data.attackerCurrentBoard[tyrIndex].attack + 3
    )
    data.attackerCurrentBoard[tyrIndex].damageTaken +=
      data.target.attack > 3 ? data.target.attack - 3 : 0
    data = data.killCreature({
      ...data,
      location: "attacker",
      card: data.attackerCurrentBoard[tyrIndex],
    })
    return data
  },
  windBackTheClock: (data) => {
    data.attacked = data.attacked.filter((card) => card.id !== data.target.id)
    data = data.spellSplice(data)
    return data
  },
  jotunheimsWinter: (data) => {
    const iceWall = {
      name: "Ice Wall",
      type: "creature",
      class: "wall",
      attack: 1,
      defense: 4,
      damageTaken: 0,
      cost: 1,
      text: "Provoke: Can't Attack",
      abilities: [],
      activatesOn: [],
      effects: ["cantAttack", "provoke"],
      rarity: "common",
      id: Math.floor(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      ),
      image: "iceWall",
      token: true,
      mythology: "token",
    }
    data = data.playCreature({
      ...data,
      creature: iceWall,
      castSpell: false,
    })
    if (data.attackerCurrentBoard.length < data.defenderCurrentBoard.length) {
      data = spellFunctions["jotunheimsWinter"](data)
    } else {
      data = data.spellSplice(data)
    }
    return data
  },
  azathoth: (data) => {
    if (!data.startTurn) {
      const avatarOne = {
        name: "Avatar of Azathoth",
        type: "creature",
        class: "avatar",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Provoke\nAt the start of turn for each card on your board that includes Azathoth in it's name deal 1 damage randomly to a random enemy minion or opponent",
        abilities: ["azathothRay"],
        activatesOn: ["startTurnOnly", "startTurn"],
        effects: ["provoke"],
        rarity: "mythic",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: "avatarOne",
        mythology: "token",
        token: true,
      }
      const avatarTwo = {
        name: "Avatar of Azathoth",
        type: "creature",
        class: "avatar",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Provoke\nAt the start of turn for each card on your board that includes Azathoth in it's name deal 1 damage randomly to a random enemy minion or opponent",
        abilities: ["azathothRay"],
        activatesOn: ["startTurnOnly", "startTurn"],
        effects: ["provoke"],
        rarity: "mythic",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: "avatarTwo",
        mythology: "token",
        token: true,
      }
      const avatarThree = {
        name: "Avatar of Azathoth",
        type: "creature",
        class: "avatar",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Provoke\nAt the start of turn for each card on your board that includes Azathoth in it's name deal 1 damage randomly to a random enemy minion or opponent",
        abilities: ["azathothRay"],
        activatesOn: ["startTurnOnly", "startTurn"],
        effects: ["provoke"],
        rarity: "mythic",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: "avatarThree",
        mythology: "token",
        token: true,
      }
      const avatarFour = {
        name: "Avatar of Azathoth",
        type: "creature",
        class: "avatar",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Provoke\nAt the start of turn for each card on your board that includes Azathoth in it's name deal 1 damage randomly to a random enemy minion or opponent",
        abilities: ["azathothRay"],
        activatesOn: ["startTurnOnly", "startTurn"],
        effects: ["provoke"],
        rarity: "mythic",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: "avatarFour",
        mythology: "token",
        token: true,
      }
      data.attackerCurrentBoard = [
        ...data.attackerCurrentBoard,
        avatarOne,
        avatarTwo,
        avatarThree,
        avatarFour,
      ]
      data.attacked = [
        ...data.attacked,
        avatarOne,
        avatarTwo,
        avatarThree,
        avatarFour,
      ]
    } else {
      data = spellFunctions["azathothRay"](data)
      data.startTurn = false
    }
    return data
  },
  azathothRay: (data) => {
    let targetCount = 0
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.attackerCurrentBoard[i].name.includes("Azathoth")) {
        targetCount += 1
      }
    }
    while (targetCount > 0) {
      const randomTarget = Math.floor(
        Math.random() * (data.defenderCurrentBoard.length + 1)
      )
      if (randomTarget === data.defenderCurrentBoard.length) {
        data = data.resolveDamage(data, 1, "opponent")
      } else {
        data.defenderCurrentBoard[randomTarget].damageTaken +=
          data.resolveDamage(
            data,
            1,
            "dontKill",
            false,
            false,
            data.defenderCurrentBoard[randomTarget]
          )
        data = data.killCreature({
          ...data,
          card: data.defenderCurrentBoard[randomTarget],
          location: "defender",
        })
      }
      targetCount--
    }
    return data
  },
  friggIncarnate: (data) => {
    data.myPlayer.userName = "Frigg"
    data.myPlayer.type = "self"
    data.myPlayer.abilities = ["theProphesied", "theClairvoyant", "theAesir"]
    data.myPlayer.abilityDescription = [
      "Gain 1 maximum mana",
      "Add a card to your hand that costs 0 and Gains you 1 mana when played and heals you for 3",
      "Call upon the sky gods to deal your maximum mana divided by 3 rounded down damage to random enemy",
    ]
    data = data.spellSplice(data)
    data.usedPlayerAbility = false
    return data
  },
  theProphesied: (data) => {
    data.mana += 1
    return data
  },
  theClairvoyant: (data) => {
    const clairvoyance = {
      name: "Clairvoyance",
      type: "spell",
      class: "sorcery",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 0,
      text: "Gain 1 mana and heal for 3",
      abilities: ["clairvoyance"],
      activatesOn: ["noTarget"],
      effects: [],
      rarity: "common",
      id: Math.floor(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      ),
      image: "clairvoyance",
      mythology: "norse",
      token: true,
    }
    data.myHand.push(clairvoyance)
    return data
  },
  clairvoyance: (data) => {
    data.myCurrentMana += 1
    data = data.calculateHealing(data, 3)
    data = data.spellSplice(data)
    return data
  },
  theAesir: (data) => {
    //PRIORITIZE BOARD THEN HIT FACE
    const manaDamage = Math.floor(data.mana / 3)
    const randomTarget = Math.floor(
      Math.random() * (data.defenderCurrentBoard.length + 1)
    )
    if (randomTarget === data.defenderCurrentBoard.length) {
      data = data.resolveDamage(data, manaDamage, "opponent")
    } else {
      data.defenderCurrentBoard[randomTarget].damageTaken += data.resolveDamage(
        data,
        manaDamage,
        "dontKill",
        false,
        false,
        data.defenderCurrentBoard[randomTarget]
      )
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[randomTarget],
      })
    }

    return data
  },
  jotnar: (data) => {
    if (data.target.type === "creature" || data.target.type === "spellcaster") {
      data = data.resolveDamage(data, 3)
    }
    return data
  },
  ymir: (data) => {
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      if (!data.defenderCurrentBoard[i].effects.includes("frozen")) {
        data.defenderCurrentBoard[i].effects.push("frozen")
        data.theirAttacked.push(data.defenderCurrentBoard[i])
      }
    }
    return data
  },
  laufey: (data) => {
    let frozenTargets = 0
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      if (data.defenderCurrentBoard[i].effects.includes("frozen")) {
        frozenTargets++
      }
    }
    let availableTargets = data.defenderCurrentBoard.length - frozenTargets
    let count = 0
    const hitIDs = []
    while (count < 2 && count < availableTargets) {
      const randomTarget = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      if (
        !hitIDs.includes(data.defenderCurrentBoard[randomTarget].id) &&
        !data.defenderCurrentBoard[randomTarget].effects.includes("frozen")
      ) {
        data.defenderCurrentBoard[randomTarget].effects.push("frozen")
        data.theirAttacked.push(data.defenderCurrentBoard[randomTarget])
        hitIDs.push(data.defenderCurrentBoard[randomTarget].id)
        count++
      }
    }
    return data
  },
  revenant: (data) => {
    const reduceCost = (card) => {
      if (card.effects.includes("lastRites")) {
        if (card.cost > 1) {
          card.cost--
        }
      }
      return card
    }
    if (data.location === "attacker") {
      data.myHand = data.myHand.map(reduceCost)
      data.myCurrentGY = data.myCurrentGY.map(reduceCost)
      data.deck = data.deck.map(reduceCost)
    } else if (data.location === "defender") {
      data.theirHand = data.theirHand.map(reduceCost)
      data.theirCurrentGY = data.theirCurrentGY.map(reduceCost)
      data.theirDeck = data.theirDeck.map(reduceCost)
    }
    return data
  },
  glamr: (data) => {
    const buffCard = (card) => {
      if (card.effects.includes("lastRites")) {
        card.attack += 1
        card.defense += 1
      }
      return card
    }
    if (data.location === "attacker") {
      data.myHand = data.myHand.map(buffCard)
      data.attackerCurrentBoard = data.attackerCurrentBoard.map(buffCard)
      data.myCurrentGY = data.myCurrentGY.map(buffCard)
      data.deck = data.deck.map(buffCard)
    } else if (data.location === "defender") {
      data.theirHand = data.theirHand.map(buffCard)
      data.defenderCurrentBoard = data.defenderCurrentBoard.map(buffCard)
      data.theirDeck = data.theirDeck.map(buffCard)
      data.theirCurrentGY = data.theirCurrentGY.map(buffCard)
    }
    return data
  },
  atlas: (data) => {
    const celestialSphere = {
      name: "Celestial Sphere",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: Minions on board get +1/+1",
      abilities: ["celestialSphere"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: Math.floor(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      ),
      image: "celestialSphere",
      mythology: "greek",
    }
    if (data.location === "attacker") {
      data.myCurrentGY.unshift(celestialSphere)
    } else if (data.location === "defender") {
      data.theirCurrentGY.unshift(celestialSphere)
    }
    return data
  },
  celestialSphere: (data) => {
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      data.attackerCurrentBoard[i].attack += 1
      data.attackerCurrentBoard[i].defense += 1
    }
    return data
  },
  haugr: (data) => {
    if (data.location === "attacker") {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.deck[i].effects.includes("lastRites")) {
          const summonedCreature = data.deck.splice(i, 1)[0]
          data = data.playCreature({
            ...data,
            creature: summonedCreature,
            castSpell: false,
          })
          break
        }
      }
    } else if (data.location === "defender") {
      for (let i = 0; i < data.theirDeck.length; i++) {
        if (data.theirDeck[i].effects.includes("lastRites")) {
          data.theirAttacked = [...data.theirAttacked, data.theirDeck[i]]
          data.defenderCurrentBoard = [
            ...data.defenderCurrentBoard,
            data.theirDeck.splice(i, 1)[0],
          ]
          break
        }
      }
    }
    return data
  },
  unstableMage: (data) => {
    if (data.location === "attacker") {
      data = data.resolveDamage(data, 4, "opponent")
      data = data.calculateHealing(data, 4)
    } else if (data.location === "defender") {
      data = data.resolveDamage(data, 4, "self")
      data = data.calculateHealing(data, 4, "opponent")
    }
    return data
  },
  prometheus: (data) => {
    const fireball = {
      name: "Fireball",
      type: "spell",
      class: "sorcery",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 2,
      text: "Deal 5 damage to target enemy",
      abilities: ["fireball"],
      activatesOn: [],
      effects: [],
      rarity: "common",
      id: Math.floor(
        (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          (Math.random() + 1) *
          10000000
      ),
      image: "fireball",
      mythology: "token",
      token: "true",
    }
    if (data.location === "attacker") {
      data.myHand.push(fireball)
    } else if (data.location === "defender") {
      data.theirHand.push(fireball)
    }
    return data
  },
  fireball: (data) => {
    data = data.resolveDamage(data, 5)
    data = data.spellSplice(data)
    return data
  },
  phoenix: (data) => {
    const newPhoenix = JSON.parse(JSON.stringify(data.card))
    const attack = newPhoenix.attack
    const defense = newPhoenix.defense
    newPhoenix.name = "Risen Phoenix"
    newPhoenix.damageTaken = 0
    newPhoenix.attack = defense
    newPhoenix.defense = attack > 0 ? attack : 1
    newPhoenix.text = newPhoenix.text.replace(
      "Last Rites: Summon a copy of this card with reversed stats that heals your player on death for its defense",
      "Last Rites: Heal your player by this cards defense"
    )
    newPhoenix.abilities = ["phoenixReborn"]
    newPhoenix.id = Math.floor(
      (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        10000000
    )
    newPhoenix.image = "phoenixReborn"
    newPhoenix.token = true
    newPhoenix.mythology = "token"
    if (data.location === "attacker") {
      data = data.playCreature({
        ...data,
        creature: newPhoenix,
        castSpell: false,
      })
    } else if (data.location === "defender") {
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: newPhoenix,
        castSpell: false,
      })
    }
    data.attacked.push(newPhoenix)
    return data
  },
  phoenixReborn: (data) => {
    if (data.location === "attacker") {
      data = data.calculateHealing(data, data.card.defense)
    } else if (data.location === "defender") {
      data = data.calculateHealing(data, data.card.defense, "opponent")
    }
    return data
  },
  aztecSacrificialCeremony: (data) => {
    data = data.killCreature({
      ...data,
      sacrifice: true,
      location: "attacker",
      card: data.attackerCurrentBoard[data.index],
      kill: true,
    })
    data = data.drawCards(data, 3)
    data = data.spellSplice(data)
    return data
  },
  huitzilopochtli: (data) => {
    if (data.location === "attacker") {
      data = data.drawCards(data, 1)
      data.myPlayer.damageTaken += 2
    } else if (data.location === "defender") {
      data.theirPlayer.damageTaken += 2
      data = data.drawCards(data, 1, "opponent")
    }
    return data
  },
  tlaloc: (data) => {
    if (data.location === "attacker") {
      data = data.calculateHealing(data, 2)
      data.myPlayer.defense += 1
    } else if (data.location === "defender") {
      data = data.calculateHealing(data, 2, "opponent")
      data.theirPlayer.defense += 1
    }
    return data
  },
  mictlantecuhtli: (data) => {
    if (data.target.type !== "god" && data.target.type !== "titan") {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.attackerCurrentBoard[data.index],
        kill: true,
      })
      data.myPlayer.defense += data.target.defense
      data = data.drawCards(data, 1)
    }
    return data
  },
  tlacaxipehualiztli: (data) => {
    let activatesOn
    if (data.defenderCurrentBoard.length >= 3) {
      activatesOn = 3
    } else {
      activatesOn = data.defenderCurrentBoard.length
    }
    const hitIDs = []
    while (activatesOn > 0) {
      const randomTarget = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      if (!hitIDs.includes(data.defenderCurrentBoard[randomTarget].id)) {
        hitIDs.push(data.defenderCurrentBoard[randomTarget].id)
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[randomTarget],
          kill: true,
        })
        activatesOn--
      }
    }
    data = data.resolveDamage(data, 5, "opponent", false)
    data = data.drawCards(data, 3)
    data = data.calculateHealing(data, 5)
    data = data.spellSplice(data)
    return data
  },
  teteoeco: (data) => {
    data = data.killCreature({
      ...data,
      sacrifice: true,
      location: "attacker",
      card: data.attackerCurrentBoard[data.index],
      kill: true,
    })
    data = data.resolveDamage(data, data.target.attack, "opponent", false)
    if (data.theirHand.length) {
      data = data.discardCards(data, 1, true, "opponent")
    }
    data = data.spellSplice(data)
    return data
  },
  panquetzaliztli: (data) => {
    let count = 3
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (
          data.deck[i].type === "creature" ||
          data.deck[i].type === "spellcaster"
        ) {
          count--
          const creature = data.deck.splice(i, 1)[0]
          data = data.playCreature({
            ...data,
            creature: creature,
            castSpell: false,
          })
        }
        if (count === 0) {
          break
        }
      }
    } else {
      let count = 3
      const values = data.myCurrentGY.filter(
        (card) => card.type === "creature" || card.type === "spellcaster"
      )

      const removeAndPlayCard = (index) => {
        const cardToRemove = data.myCurrentGY.find(
          (card) => card.id === values[index].id
        )
        const updatedGY = data.myCurrentGY.filter(
          (card) => card.id !== cardToRemove.id
        )
        const updatedData = { ...data, myCurrentGY: updatedGY }
        return data.playCreature({
          ...updatedData,
          creature: values[index],
          castSpell: false,
        })
      }

      while (count > 0 && values.length > 0) {
        const randomIndex = Math.floor(Math.random() * values.length)
        data = removeAndPlayCard(randomIndex)
        values.splice(randomIndex, 1)
        count--
      }
    }

    data = data.spellSplice(data)
    return data
  },
  aztecSlaves: (data) => {
    const slaveConstructor = (image) => {
      const slave = {
        name: "Aztec Slaves",
        type: "creature",
        class: "human",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "When sacrificed transfer this creatures stats to a random friendly minion",
        abilities: [],
        activatesOn: [],
        effects: [],
        rarity: "common",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: image,
        sacrificialPawn: "aztecSlave",
        token: true,
        mythology: "token",
      }
      return slave
    }
    const slave1 = slaveConstructor("slaveOne")
    const slave2 = slaveConstructor("slaveTwo")
    if (data.location === "attacker") {
      data = data.playCreature({ ...data, creature: slave1, castSpell: false })
      data = data.playCreature({ ...data, creature: slave2, castSpell: false })
    } else {
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: slave1,
        castSpell: false,
      })
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: slave2,
        castSpell: false,
      })
    }
    data = data.spellSplice(data)
    return data
  },
  aztecSlave: (data) => {
    if (data.location === "attacker") {
      if (data.attackerCurrentBoard.length) {
        const randomIndex = Math.floor(
          Math.random() * data.attackerCurrentBoard.length
        )
        data.attackerCurrentBoard[randomIndex].attack += data.card.attack
        data.attackerCurrentBoard[randomIndex].defense += data.card.defense
      }
    } else if (data.location === "defender") {
      if (data.defenderCurrentBoard.length) {
        const randomIndex = Math.floor(
          Math.random() * data.defenderCurrentBoard.length
        )
        data.defenderCurrentBoard[randomIndex].attack += data.card.attack
        data.defenderCurrentBoard[randomIndex].defense += data.card.defense
      }
    }
    return data
  },
  bloodletting: (data) => {
    data = data.killCreature({
      ...data,
      sacrifice: true,
      location: "attacker",
      card: data.attackerCurrentBoard[data.index],
      kill: true,
    })
    if (data.defenderCurrentBoard.length) {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
      })
    }
    data = data.spellSplice(data)
    return data
  },
  quetzalcoatl: (data) => {
    data.myPlayer.defense += data.attackerCurrentBoard.length * 3
    data = data.drawCards(data, Math.floor(data.myPlayer.defense / 10))
    const originalLength = data.attackerCurrentBoard.length
    let count = 0
    while (count < originalLength) {
      data = data.killCreature({
        ...data,
        location: "attacker",
        card: data.attackerCurrentBoard[0],
        kill: true,
      })
      count++
    }

    return data
  },
  coatlicue: (data) => {
    data.deck = data.deck.filter((card) => card.cost > 3)
    data.myHand = data.myHand.filter((card) => card.cost > 3)
    data.myCurrentGY = data.myCurrentGY.filter((card) => card.cost > 3)
    data.attackerCurrentBoard = data.attackerCurrentBoard.filter(
      (card) => card.cost > 3
    )
    return data
  },
  tezcatlipoca: (data) => {
    const mirrorConstructor = (image) => {
      const mirror = {
        name: "Smoking Mirror",
        type: "creature",
        class: "artifact",
        attack: 1,
        defense: 4,
        damageTaken: 0,
        cost: 1,
        text: "At the start of your turn drain 2 life from you to your opponent",
        abilities: ["smokingMirror"],
        activatesOn: ["noTarget", "startTurnOnly", "startTurn"],
        effects: [],
        rarity: "common",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: image,
        mythology: "token",
        token: true,
      }
      return mirror
    }
    const mirror1 = mirrorConstructor("mirrorOne")
    const mirror2 = mirrorConstructor("mirrorTwo")
    data = data.playCreatureOnOpponentBoard({
      ...data,
      creature: mirror1,
      castSpell: false,
    })
    data = data.playCreatureOnOpponentBoard({
      ...data,
      creature: mirror2,
      castSpell: false,
    })
    return data
  },
  smokingMirror: (data) => {
    data = data.resolveDamage(data, 2, "self")
    data = data.calculateHealing(data, 2, "opponent")
    return data
  },
  popocatepetlIztaccihuatl: (data) => {
    const popocatepetlIztaccihuatlConstructor = (
      name,
      image,
      text,
      effects
    ) => {
      const lover = {
        name: name,
        type: "creature",
        class: "human",
        attack: 6,
        defense: 6,
        damageTaken: 0,
        cost: 3,
        text: text,
        abilities: [],
        activatesOn: [],
        effects: effects,
        rarity: "legendary",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: image,
        token: true,
        mythology: "token",
      }
      return lover
    }
    const loversMountainConstructor = (name, image) => {
      const loversMountain = {
        name: name,
        type: "spell",
        class: "sorcery",
        attack: null,
        defense: null,
        damageTaken: 0,
        cost: 0,
        text: "Give a random minion on your board +2 attack",
        abilities: ["loversMountain"],
        activatesOn: ["noTarget"],
        effects: [],
        rarity: "legendary",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: image,
        mythology: "aztec",
        token: true,
      }
      return loversMountain
    }
    const popocatepetl = popocatepetlIztaccihuatlConstructor(
      "Popocatepetl",
      "popocatepetl",
      "Haste",
      ["haste"]
    )
    const iztaccihuatl = popocatepetlIztaccihuatlConstructor(
      "Iztaccihuatl",
      "iztaccihuatl",
      "Provoke",
      ["provoke"]
    )
    const popocatepetlsMountain = loversMountainConstructor(
      "Popocatepetl's Mountain",
      "popocatepetlsMountain"
    )
    const iztaccihuatlsMountain = loversMountainConstructor(
      "Iztaccihuatl's Mountain",
      "iztaccihuatlsMountain"
    )
    data.myHand = [...data.myHand, popocatepetlsMountain, iztaccihuatlsMountain]
    data = data.playCreature({
      ...data,
      creature: popocatepetl,
      castSpell: false,
    })
    data = data.playCreature({
      ...data,
      creature: iztaccihuatl,
      castSpell: false,
    })
    data = data.spellSplice(data)
    return data
  },
  loversMountain: (data) => {
    if (data.attackerCurrentBoard.length) {
      const randomIndex = Math.floor(
        Math.random() * data.attackerCurrentBoard.length
      )
      data.attackerCurrentBoard[randomIndex].attack += 2
    }
    data = data.spellSplice(data)
    return data
  },
  aztecPriestess: (data) => {
    data.mana += 1
    return data
  },
  templeOfLight: (data) => {
    data = data.drawCards(data, 1)
    if (data.attackerCurrentBoard.length) {
      const randomIndex = Math.floor(
        Math.random() * data.attackerCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.attackerCurrentBoard[randomIndex],
        kill: true,
      })
      data.myHand = data.myHand.map((card) => {
        if (card.type === "creature" || card.type === "spellcaster") {
          card.attack += 1
          card.defense += 1
        }
        return card
      })
    }
    data.i++
    return data
  },
  aztecHuntingParty: (data) => {
    const hunterConstructor = () => {
      const hunter = {
        name: "Aztec Hunters",
        type: "creature",
        class: "human",
        attack: 2,
        defense: 2,
        damageTaken: 0,
        cost: 1,
        text: "",
        abilities: [],
        activatesOn: [],
        effects: [],
        rarity: "common",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: "aztecHunters",
        token: true,
        mythology: "token",
      }
      return hunter
    }
    for (let i = 0; i < 2; i++) {
      data = data.playCreature({
        ...data,
        creature: hunterConstructor(),
        castSpell: false,
      })
    }
    data = data.spellSplice(data)
    return data
  },
  tonochtitlan: (data) => {
    const hunterConstructor = () => {
      const hunter = {
        name: "Aztec Hunters",
        type: "creature",
        class: "human",
        attack: 2,
        defense: 2,
        damageTaken: 0,
        cost: 1,
        text: "",
        abilities: [],
        activatesOn: [],
        effects: [],
        rarity: "common",
        id: Math.floor(
          (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            (Math.random() + 1) *
            10000000
        ),
        image: "aztecHunters",
        token: true,
        mythology: "token",
      }
      return hunter
    }
    data = data.playCreature({
      ...data,
      creature: hunterConstructor(),
      castSpell: false,
    })
    return data
  },
  sunstoneShield: (data) => {
    data.attackerCurrentBoard[data.index].spellImmune = true
    data.attackerCurrentBoard[data.index].attack += 1
    data.attackerCurrentBoard[data.index].defense += 1
    const newString = data.attackerCurrentBoard[data.index].text.split("")
    newString.unshift("Cannot be targeted by spells\n")
    data.attackerCurrentBoard[data.index].text = newString.join("")
    data = data.spellSplice(data)
    return data
  },
  lernaeanHydra: (data) => {
    const hydraCreator = () => {
      return {
        name: "Hydra",
        type: "creature",
        class: "monster",
        attack: 2,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "",
        abilities: ["hydraSplit"],
        activatesOn: ["doNotCast"],
        effects: ["lastRites"],
        rarity: "legendary",
        id: data.randomId(data),
        image: "hydra",
        mythology: "greek",
      }
    }

    if (data.location === "attacker") {
      data = data.playCreature({
        ...data,
        creature: hydraCreator(),
        castSpell: false,
      })
      data = data.playCreature({
        ...data,
        creature: hydraCreator(),
        castSpell: false,
      })
    } else if (data.location === "defender") {
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: hydraCreator(),
        castSpell: false,
      })
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: hydraCreator(),
        castSpell: false,
      })
    }
    return data
  },
  kukulkan: (data) => {
    data = data.resolveDamage(data, 1)
    data = data.drawCards(data, 1)
    return data
  },
  surtr: (data) => {
    if (data.location === "attacker") {
      data = data.resolveDamage(data, 3, "opponent")
      data = data.boardAreaOfEffect(data, 3)
    } else if (data.location === "defender") {
      data = data.resolveDamage(data, 3, "self")
      data = data.boardAreaOfEffect(data, 3, "self")
    }
    return data
  },
  pueo: (data) => {
    data = data.drawCards(data, 1)
    data = data.calculateHealing(data, 6)
  
    return data
  },
  bennu: (data) => {
    const newPhoenix = JSON.parse(JSON.stringify(data.card))
    const attack = Math.ceil(newPhoenix.attack / 2)
    const defense = Math.ceil(newPhoenix.defense / 2)
    newPhoenix.damageTaken = 0
    newPhoenix.attack = attack
    newPhoenix.defense = defense
    newPhoenix.text = newPhoenix.text.replace(
      "Last Rites: Summon a copy of this card with half the stats rounded up and ",
      ""
    )
    newPhoenix.abilities = ["bennuReborn"]
    newPhoenix.id = Math.floor(
      (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        10000000
    )
    newPhoenix.image = "bennuReborn"
    newPhoenix.token = true
    newPhoenix.mythology = "token"
    if (data.location === "attacker") {
      data = data.playCreature({
        ...data,
        creature: newPhoenix,
        castSpell: false,
      })
    } else if (data.location === "defender") {
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: newPhoenix,
        castSpell: false,
      })
    }
    data.attacked.push(newPhoenix)
    return data
  },
  bennuReborn: (data) => {
    const newPhoenix = { ...data.card }
    const newPhoenixTwo = { ...data.card }
    const attackTwo = Math.ceil(newPhoenixTwo.attack / 2)
    const defenseTwo = Math.ceil(newPhoenixTwo.defense / 2)
    newPhoenixTwo.damageTaken = 0
    newPhoenixTwo.attack = attackTwo
    newPhoenixTwo.defense = defenseTwo
    newPhoenixTwo.text = newPhoenixTwo.text.replace(
      "Last Rites: Summon two copies of this card with half the stats rounded up without last rites",
      ""
    )
    newPhoenixTwo.abilities = ["bennuReborn"]
    newPhoenixTwo.id = Math.floor(
      (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        10000000
    )
    newPhoenixTwo.image = "bennuReborn"
    const attack = Math.ceil(newPhoenix.attack / 2)
    const defense = Math.ceil(newPhoenix.defense / 2)
    newPhoenix.damageTaken = 0
    newPhoenix.attack = attack
    newPhoenix.defense = defense
    newPhoenix.text = newPhoenix.text.replace(
      "Last Rites: Summon two copies of this card with half the stats rounded up without last rites",
      ""
    )
    newPhoenix.abilities = []
    newPhoenix.effects.splice(newPhoenix.effects.indexOf("lastRites"), 1)
    newPhoenix.id = Math.floor(
      (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        (Math.random() + 1) *
        10000000
    )
    if (data.location === "attacker") {
      data = data.playCreature({
        ...data,
        creature: newPhoenix,
        castSpell: false,
      })
      data = data.playCreature({
        ...data,
        creature: newPhoenixTwo,
        castSpell: false,
      })
      data.attacked = [...data.attacked, newPhoenix, newPhoenixTwo]
    } else if (data.location === "defender") {
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: newPhoenix,
        castSpell: false,
      })
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: newPhoenixTwo,
        castSpell: false,
      })
      data.theirAttacked = [...data.theirAttacked, newPhoenix, newPhoenixTwo]
    }
    return data
  },
  phoenixAshes: (data) => {
    data = data.resolveDamage(data, 6)
    data.myPlayer.defense += 6
    const {...newSpell} = data.findCard(data, data.mySpellCast.id)
    newSpell.id = data.randomId(data)
    data.mySpellCast.token = true
    const originalCard = data.findCard(data, data.mySpellCast.id)
    originalCard.token = true
    newSpell.image = "phoenixAshesTwo"
    newSpell.abilities = ["phoenixAshesTwo"]
    newSpell.cost += 5
    newSpell.text =
      "Deal 9 damage to target enemy, increase maximum life by 12, and reduce the cost of spells in your hand by 2 to a minimum of 1"
    

    data.myHand.push(newSpell)

    data = data.spellSplice(data)
    return data
  },
  phoenixAshesTwo: (data) => {
    data = data.resolveDamage(data, 9)
    data.myPlayer.defense += 12
    data = data.spellSplice(data)
    for (let i = 0; i < data.myHand.length; i++) {
      if (data.myHand[i].type === "spell") {
        // Set cost to 1 if it is 0
        if (data.myHand[i].cost === 0) {
          continue
        } else {
          // Reduce cost by 2 but not below 1
          data.myHand[i].cost = Math.max(1, data.myHand[i].cost - 2)
        }
      }
    }
    return data
  },
  warElephant: (data) => {
    if (data.myPlayer.durability || data.myPlayer.block) {
      data = data.drawCards(data, 3)
    }
    return data
  },
  typhonIncarnate: (data) => {
    data.myPlayer.userName = "Typhon"
    data.myPlayer.type = "self"
    data.myPlayer.abilities = ["monsterKing", "monsterFather", "monstersWrath"]
    data.myPlayer.abilityDescription = [
      "Put a random monster from your deck into your hand",
      "Summon a random monster from your hand as if you played it",
      "Monsters in hand get +1/+1",
    ]
    data = data.spellSplice(data)
    data.usedPlayerAbility = false
    return data
  },
  monsterKing: (data) => {
    let count = 1
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; ) {
        if (data.isAMonster(data.deck[i])) {
          data.myHand.push(data.deck.splice(i, 1)[0])
          count--
          if (count === 0) {
            break
          }
        } else {
          i++
        }
      }
    } else {
      const values = data.myCurrentGY.filter(
        (card) => card.type === "creature" && card.class === "monster"
      )
      for (let i = 0; i < count; i++) {
        if (values.length) {
          data.myPlayer.damageTaken += 1
          const randomIndex = Math.floor(Math.random() * values.length)
          const drawnCard = values.splice(randomIndex, 1)[0]
          drawnCard.attack += 3
          drawnCard.defense += 3
          data.myCurrentGY = data.myCurrentGY.filter(
            (card) => drawnCard?.id !== card.id
          )
          data.myHand.push(drawnCard)
        } else {
          break
        }
      }
    }
    return data
  },
  monsterFather: (data) => {
    const values = data.myHand.filter((card) => card.class === "monster")
    if (values.length) {
      const randomIndex = Math.floor(Math.random() * values.length)
      const playedCard = values.splice(randomIndex, 1)[0]
      data.myHand = data.myHand.filter((card) => playedCard?.id !== card.id)
      const castSpell = playedCard.activatesOn.includes("doNotCast")
        ? false
        : true
      data = data.playCreature({
        ...data,
        creature: playedCard,
        castSpell: castSpell,
      })
    }
    return data
  },
  monstersWrath: (data) => {
    const monsterHandBuff = (card) => {
      if(data.isAMonster(card)){
        card.attack += 1
        card.defense += 1
      }
      return card
    }

    data.myHand = data.myHand.map(monsterHandBuff)

    return data
  },
  emperorDeathWhistle: (data) => {
    if (data.attackerCurrentBoard.find((card) => card.id === data.target.id)) {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.target,
        kill: true,
      })
      data = data.drawCards(data, 2)
    }
    if (data.defenderCurrentBoard.find((card) => card.id === data.target.id)) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.target,
        kill: true,
      })
    }
    data = data.spellSplice(data)
    return data
  },
  aztecTributeSacrifice: (data) => {
    const myRandomIndex = Math.floor(
      Math.random() * data.attackerCurrentBoard.length
    )
    const theirRandomIndex = Math.floor(
      Math.random() * data.defenderCurrentBoard.length
    )

    if (data.attackerCurrentBoard.length) {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.attackerCurrentBoard[myRandomIndex],
        kill: true,
      })
    }
    if (data.defenderCurrentBoard.length) {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        card: data.defenderCurrentBoard[theirRandomIndex],
        kill: true,
      })
    }

    data = data.spellSplice(data)
    return data
  },
  aztecTributeDraw: (data) => {
    data = data.drawCards(data, 2, "both")
    data.myPlayer.damageTaken += Math.ceil(
      data.drawnCards.reduce((acc, card) => {
        acc += card.cost
        return acc
      }, 0) / 2
    )
    data.theirPlayer.damageTaken += Math.ceil(
      data.theirDrawnCards.reduce((acc, card) => {
        acc += card.cost
        return acc
      }, 0) / 2
    )

    data = data.spellSplice(data)
    return data
  },
  aztecEmperor: (data) => {
    data.attackerCurrentBoard = data.attackerCurrentBoard.map((card) => {
      if (data.isHuman(card)) {
        card.attack += 1
        card.defense += 1
      }
      return card
    })
    data.myHand = data.myHand.map((card) => {
      if (data.isHuman(card)) {
        card.attack += 1
        card.defense += 1
      }
      return card
    })
    return data
  },
  waylandTheSmith: (data) => {
    let count = 0
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.isHuman(data.attackerCurrentBoard[i])) {
        count++
      }
    }
    data.myPlayer.block += count
    data.myPlayer.durability += count

    return data
  },
  aztecRainFrog: (data) => {
    if (!data.startTurn) {
      data.theirPlayer.poison += 2
    } else {
      data.theirPlayer.poison += 1
    }

    return data
  },
  nahualVineLurker: (data) => {
    let count = 0
    const hitIDs = []
    let opponentHit = 1
    while (count < 3 && count <= data.defenderCurrentBoard.length) {
      const randomTarget = Math.floor(
        Math.random() * (data.defenderCurrentBoard.length + opponentHit)
      )
      if (randomTarget === data.defenderCurrentBoard.length) {
        opponentHit--
        data = data.resolveDamage(data, 3, "opponent")
        count++
      } else if (!hitIDs.includes(data.defenderCurrentBoard[randomTarget].id)) {
        data.defenderCurrentBoard[randomTarget].damageTaken +=
          data.resolveDamage(
            data,
            3,
            "dontKill",
            false,
            false,
            data.defenderCurrentBoard[randomTarget]
          )
        hitIDs.push(data.defenderCurrentBoard[randomTarget].id)
        count++
      }
    }
    data.attackerKill = false
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
      })
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }
    if (data.mySpellCast) {
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].attack += 3
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].defense += 4
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].text = data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].text.replace(
        "ETB: Shapeshift into one of the following:\nVine Lurker: Gain +3/+4 and deal 3 damage to 3 unique enemies\nSpider: Gain +4/+2 haste and when this minion hits an opponent poison them for 3 instead of dealing combat damage\nBear: Gain +3/+9 and Provoke",
        "When played lash out with vines on three different enemies dealing 3 damage"
      )
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].abilities = ["nahualVineLurker"]
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].activatesOn = ["noTarget"]
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].image = "nahualVineLurker"
      delete data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].choiceText
    }

    return data
  },
  nahualSpider: (data) => {
    if (data.onAttack) {
      data.theirPlayer.poison += 3
    } else {
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].attack += 4
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].defense += 2
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].text = data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].text.replace(
        "ETB: Shapeshift into one of the following:\nVine Lurker: Gain +3/+4 and deal 3 damage to 3 unique enemies\nSpider: Gain +4/+2 haste and when this minion hits an opponent poison them for 3 instead of dealing combat damage\nBear: Gain +3/+9 and Provoke",
        "Haste\nWhen Nahaul attacks an opponent deal 3 poison damage instead of this minions attack"
      )
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].abilities = ["nahualSpider"]
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].activatesOn = ["doNotCast"]
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].effects = ["haste", "noFaceDamage", "poisonous"]
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].image = "nahualSpider"
      data.attacked.splice(
        data.attacked.findIndex((card) => card.id === data.mySpellCast.id),
        1
      )
    }

    return data
  },
  nahualBear: (data) => {
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].attack += 3
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].defense += 9
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].text = data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].text.replace(
      "ETB: Shapeshift into one of the following:\nVine Lurker: Gain +3/+4 and deal 3 damage to 3 unique enemies\nSpider: Gain +4/+2 haste and when this minion hits an opponent poison them for 3 instead of dealing combat damage\nBear: Gain +3/+9 and Provoke",
      "Provoke"
    )
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].abilities = []
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].activatesOn = []
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].effects = ["provoke"]
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].image = "nahualBear"

    return data
  },
  emperorsGuardCrocodile: (data) => {
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].attack += 7
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].defense += 7
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].text = data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].text.replace(
      "ETB: Shapeshift into one of the following:\nCrocodile: Gain +7/+7 and cannot be targeted by opponents spells and abilities\nEmberfly: Gain +2/+2 and deal 2 damage to all enemies and heal for the amount of damage dealt, activate this effect at the start of turn and on transformation",
      "Cannot be targeted by opponents spells and abilities"
    )
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].abilities = []
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].activatesOn = []
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].effects = []
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].opponentImmune = true
    data.attackerCurrentBoard[
      data.attackerCurrentBoard.findIndex(
        (card) => card.id === data.mySpellCast.id
      )
    ].image = "emperorsGuardCrocodile"

    return data
  },
  emperorsGuardEmberfly: (data) => {
    if (data.mySpellCast) {
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].attack += 2
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].defense += 2
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].text = data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].text.replace(
        "ETB: Shapeshift into one of the following:\nCrocodile: Gain +7/+7 and cannot be targeted by opponents spells and abilities\nEmberfly: Gain +2/+2 and deal 2 damage to all enemies and heal for the amount of damage dealt, activate this effect at the start of turn and on transformation",
        "At the start of your turn and when played deal 2 damage to all enemies and heal for that amount"
      )
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].abilities = ["emperorsGuardEmberfly"]
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].activatesOn = ["noTarget", "startTurn"]
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].effects = []
      data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].image = "emperorsGuardEmberfly"
      delete data.attackerCurrentBoard[
        data.attackerCurrentBoard.findIndex(
          (card) => card.id === data.mySpellCast.id
        )
      ].choiceText
    }
    data = data.calculateHealing(data, 2 + data.defenderCurrentBoard.length * 2)
    data = data.resolveDamage(data, 2, "opponent")
    data = data.boardAreaOfEffect(data, 2)

    return data
  },
  isisTheResurrector: (data) => {
    let count = 3
    const values = data.myCurrentGY.filter(
      (card) => card.type === "creature" || card.type === "spellcaster"
    )

    const removeAndPlayCard = (index) => {
      const cardToRemove = data.myCurrentGY.find(
        (card) => card.id === values[index].id
      )
      const updatedGY = data.myCurrentGY.filter(
        (card) => card.id !== cardToRemove.id
      )
      const updatedData = { ...data, myCurrentGY: updatedGY }
      return data.playCreature({
        ...updatedData,
        creature: values[index],
        castSpell: false,
      })
    }

    while (count > 0 && values.length > 0) {
      const randomIndex = Math.floor(Math.random() * values.length)
      data = removeAndPlayCard(randomIndex)
      values.splice(randomIndex, 1)
      count--
    }
    return data
  },
  scrollOfEternalKnowledge: (data) => {
    data = data.drawCards(data, 3)
    data = data.spellSplice(data)
    const scrollMummy = {
      name: "Mummy",
      type: "creature",
      class: "undead",
      attack: data.myHand.length,
      defense: data.myHand.length,
      damageTaken: 0,
      cost: data.myHand.length,
      text: "",
      abilities: [],
      activatesOn: [],
      effects: [],
      rarity: "common",
      id: data.randomId(data),
      image: "scrollMummy",
      mythology: "token",
      token: true,
    }
    data = data.playCreature({
      ...data,
      creature: scrollMummy,
      castSpell: false,
    })

    return data
  },
  pyramidOfTheDead: (data) => {
    if (data.castAbility) {
      const mummy = {
        name: "Mummy",
        type: "creature",
        class: "undead",
        attack: 2,
        defense: 2,
        damageTaken: 0,
        cost: 1,
        text: "",
        abilities: [],
        activatesOn: [],
        effects: [],
        rarity: "common",
        id: data.randomId(data),
        image: "scrollMummy",
        mythology: "token",
        token: true,
      }
      const mummyTwo = {
        name: "Mummy",
        type: "creature",
        class: "undead",
        attack: 2,
        defense: 2,
        damageTaken: 0,
        cost: 1,
        text: "",
        abilities: [],
        activatesOn: [],
        effects: [],
        rarity: "common",
        id: data.randomId(data),
        image: "scrollMummy",
        mythology: "token",
        token: true,
      }

      data = data.playCreature({ ...data, creature: mummy, castSpell: false })
      data = data.playCreature({
        ...data,
        creature: mummyTwo,
        castSpell: false,
      })
      data = data.spellSplice(data)
    } else {
      data.attackerCurrentBoard = data.attackerCurrentBoard.map((card) => {
        if (data.isUndead(card)) {
          card.attack += 1
          card.defense += 1
        }
        return card
      })
    }

    return data
  },
  sekhmet: (data) => {
    if (data.location === "attacker") {
      data = data.calculateHealing(data, 2)
      data = data.resolveDamage(data, 2, "opponent")
    } else if (data.location === "defender") {
      data = data.calculateHealing(data, 2, "opponent")
      data = data.resolveDamage(data, 2, "self")
    }
    return data
  },
  gravedigger: (data) => {
    let milledCards = 2
    if (data.location === "attacker") {
      if (data.deck.length < 2) {
        milledCards = data.deck.length
      }
      if (milledCards > 0) {
        const removedCards = data.deck.splice(0, milledCards)
        data.myCurrentGY = [...removedCards, ...data.myCurrentGY]
      }
    } else if (data.location === "defender") {
      if (data.theirDeck.length < 2) {
        milledCards = data.theirDeck.length
      }
      if (milledCards > 0) {
        const removedCards = data.theirDeck.splice(0, milledCards)
        data.theirCurrentGY = [...removedCards, ...data.theirCurrentGY]
      }
    }
    return data
  },
  corpseRaider: (data) => {
    if (data.defenderCurrentBoard.length) {
      const theirRandomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        card: data.defenderCurrentBoard[theirRandomIndex],
        kill: true,
      })
    }
    if (data.attackerCurrentBoard.length) {
      const myRandomIndex = Math.floor(
        Math.random() * data.attackerCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.attackerCurrentBoard[myRandomIndex],
        kill: true,
      })
    }
    return data
  },
  deathWatcher: (data) => {
    if (data.draw === "myDraw") {
      data = data.resolveDamage(
        data,
        data.theirDrawnCards.length * 2,
        "opponent",
        false
      )
    } else {
      data = data.resolveDamage(data, data.drawnCards.length * 2, "self", false)
    }
    return data
  },
  thoth: (data) => {
    if (data.draw === "myDraw") {
      let count = 0
      for (let i = 0; i < data.theirDrawnCards.length; i++) {
        count +=
          data.theirDrawnCards[i].cost > 5 ? 5 : data.theirDrawnCards[i].cost
      }
      data = data.resolveDamage(data, count, "opponent", false)
    } else {
      let count = 0
      for (let i = 0; i < data.drawnCards.length; i++) {
        count += data.drawnCards[i].cost > 5 ? 5 : data.drawnCards[i].cost
      }
      data = data.resolveDamage(data, count, "self", false)
    }
    return data
  },
  fafnir: (data) => {
    if (data.myHand.length < 1) {
      data = data.resolveDamage(data, 6, "opponent", false)
      data.card.attack += 1
      data.card.defense += 1
      data = data.drawCards(data, 2)
    }
    return data
  },
  isisBringerOfLife: (data) => {
    return data
  },
  sekhmetDivineHealer: (data) => {
    return data
  },
  anubis: (data) => {
    data.myCurrentGY = data.myCurrentGY.map((card) => {
      if (card.type === "creature" || card.type === "spellcaster") {
        card.attack += 2
        card.defense += 2
      }
      return card
    })
    return data
  },
  gateOfLife: (data) => {
    data.myPlayer.defense += 12
    data = data.resolveDamage(data, 8, "self", false)
    data = data.spellSplice(data)
    return data
  },
  unstableMagic: (data) => {
    data = data.spellSplice(data)
    const values = data.myHand.filter(
      (card) =>
        card.name !== "Unstable Magic" &&
        card.type === "spell" &&
        card.class !== "incarnate" &&
        !card?.prophecy
    )
    if (values.length) {
      const randomIndex = Math.floor(Math.random() * values.length)
      data = data.handleCallStack(data, values[randomIndex])
      data.spellID = values[randomIndex].id
      data.spellReduction.reduceCost = 10000
      data.spellReduction.reducedCost = true
      data.spellReduction.reset = false
      data.spellReduction.dontSplice = true
    }
    return data
  },
  soulstoneSacrifice: (data) => {
    const priorityTargets = data.defenderCurrentBoard.filter(
      (card) => card.spellImmune || card.opponentImmune
    )
    if (priorityTargets.length) {
      const randomIndex = Math.floor(Math.random() * priorityTargets.length)
      const cardID = priorityTargets[randomIndex].id
      const indexOnBoard = data.defenderCurrentBoard.findIndex(
        (card) => card.id === cardID
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        card: data.defenderCurrentBoard[indexOnBoard],
        kill: true,
      })
    } else {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
      })
    }
    data = data.spellSplice(data)
    return data
  },
  droneOfAnubis: (data) => {
    data.myCurrentGY = data.myCurrentGY.map((card) => {
      if (card.type === "creature" || card.type === "spellcaster") {
        card.attack += 1
        card.defense += 1
      }
      return card
    })
    return data
  },
  raTheSolarPharaoh: (data) => {
    if (data.onHeal) {
      if (data.heal === "myHeal" && data.player === "self") {
        const randomIndex = Math.floor(
          Math.random() * (data.defenderCurrentBoard.length + 1)
        )
        if (randomIndex === data.defenderCurrentBoard.length) {
          data = data.resolveDamage(data, data.healAmount, "opponent", false)
        } else {
          data.defenderCurrentBoard[randomIndex].damageTaken +=
            data.resolveDamage(
              data,
              data.healAmount,
              "dontKill",
              false,
              false,
              data.defenderCurrentBoard[randomIndex]
            )
          data = data.killCreature({
            ...data,
            location: "defender",
            card: data.defenderCurrentBoard[randomIndex],
          })
        }
      } else if (data.heal === "opponentHeal" && data.player === "opponent") {
        const randomIndex = Math.floor(
          Math.random() * (data.attackerCurrentBoard.length + 1)
        )
        if (randomIndex === data.attackerCurrentBoard.length) {
          data = data.resolveDamage(data, data.healAmount, "self", false)
        } else {
          data.attackerCurrentBoard[randomIndex].damageTaken +=
            data.resolveDamage(
              data,
              data.healAmount,
              "dontKill",
              false,
              false,
              data.attackerCurrentBoard[randomIndex]
            )
          data = data.killCreature({
            ...data,
            location: "attacker",
            card: data.attackerCurrentBoard[randomIndex],
          })
        }
      }
    } else {
      if (data.draw === "myDraw") {
        for (let i = 0; i < data.drawnCards.length; i++) {
          data = data.calculateHealing(data, 2)
        }
      } else {
        for (let i = 0; i < data.theirDrawnCards.length; i++) {
          data = data.calculateHealing(data, 2, "opponent")
        }
      }
    }

    return data
  },
  thothLorebringer: (data) => {
    if (!data.drawFromGraveyard) {
      const cardsFound = {
        7: false,
        8: false,
        9: false,
      }
      for (let i = data.deck.length - 1; i >= 0; i--) {
        if (data.deck[i].cost > 6 && data.deck[i].cost < 10) {
          if (!cardsFound[`${data.deck[i].cost}`]) {
            cardsFound[`${data.deck[i].cost}`] = true
            data.myHand = [...data.myHand, data.deck.splice(i, 1)[0]]
          }
        }
      }
    } else {
      for (let i = 7; i < 10; i++) {
        const values = data.myCurrentGY.filter((card) => card.cost === i)

        if (values.length) {
          const randomIndex = Math.floor(Math.random() * values.length)
          data.myHand = [
            ...data.myHand,
            data.myCurrentGY.splice(
              data.myCurrentGY.findIndex(
                (card) => card.id === values[randomIndex].id
              ),
              1
            )[0],
          ]
        }
      }
    }

    return data
  },
  horusAllseeing: (data) => {
    if (data.location === "attacker") {
      const costValues = new Set()
      if (!data.drawFromGraveyard) {
        for (let i = 0; i < data.deck.length; i++) {
          costValues.add(data.deck[i].cost)
        }
        const maxCost = Math.max(...Array.from(costValues))
        const values = data.deck.filter((card) => card.cost === maxCost)
        const randomIndex = Math.floor(Math.random() * values.length)
        if (data.deck.length > 0) {
          data.myHand = [
            ...data.myHand,
            data.deck.splice(
              data.deck.findIndex((card) => card.id === values[randomIndex].id),
              1
            )[0],
          ]
        }
      } else {
        for (let i = 0; i < data.myCurrentGY.length; i++) {
          costValues.add(data.myCurrentGY[i].cost)
        }
        const maxCost = Math.max(...Array.from(costValues))
        const values = data.myCurrentGY.filter((card) => card.cost === maxCost)
        const randomIndex = Math.floor(Math.random() * values.length)
        if (data.myCurrentGY.length > 0) {
          data.myHand = [
            ...data.myHand,
            data.myCurrentGY.splice(
              data.myCurrentGY.findIndex(
                (card) => card.id === values[randomIndex].id
              ),
              1
            )[0],
          ]
        }
      }
    } else if (data.location === "defender") {
      const costValues = new Set()
      if (!data.opponentDrawFromGraveyard) {
        for (let i = 0; i < data.theirDeck.length; i++) {
          costValues.add(data.theirDeck[i].cost)
        }
        const maxCost = Math.max(...Array.from(costValues))
        const values = data.theirDeck.filter((card) => card.cost === maxCost)
        const randomIndex = Math.floor(Math.random() * values.length)
        if (data.theirDeck.length > 0) {
          data.theirHand = [
            ...data.theirHand,
            data.theirDeck.splice(
              data.theirDeck.findIndex(
                (card) => card.id === values[randomIndex].id
              ),
              1
            )[0],
          ]
        }
      } else {
        for (let i = 0; i < data.theirCurrentGY.length; i++) {
          costValues.add(data.theirCurrentGY[i].cost)
        }
        const maxCost = Math.max(...Array.from(costValues))
        const values = data.theirCurrentGY.filter(
          (card) => card.cost === maxCost
        )
        const randomIndex = Math.floor(Math.random() * values.length)
        if (data.theirCurrentGY.length > 0) {
          data.theirHand = [
            ...data.theirHand,
            data.theirCurrentGY.splice(
              data.theirCurrentGY.findIndex(
                (card) => card.id === values[randomIndex].id
              ),
              1
            )[0],
          ]
        }
      }
    }
    return data
  },
  jawsOfAmbush: (data) => {
    if (data.location === "attacker") {
      const randomIndex = Math.floor(
        Math.random() * (data.defenderCurrentBoard.length + 1)
      )
      if (randomIndex === data.defenderCurrentBoard.length) {
        data = data.resolveDamage(data, 5, "opponent", false)
      } else {
        data.defenderCurrentBoard[randomIndex].damageTaken +=
          data.resolveDamage(
            data,
            5,
            "dontKill",
            false,
            false,
            data.defenderCurrentBoard[randomIndex]
          )
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[randomIndex],
        })
      }
      if (!data.discardCard) {
        data = data.spellSplice(data)
      }
    } else if (data.location === "defender") {
      const randomIndex = Math.floor(
        Math.random() * (data.attackerCurrentBoard.length + 1)
      )
      if (randomIndex === data.attackerCurrentBoard.length) {
        data = data.resolveDamage(data, 5, "self", false)
      } else {
        data.attackerCurrentBoard[randomIndex].damageTaken +=
          data.resolveDamage(
            data,
            5,
            "dontKill",
            false,
            false,
            data.attackerCurrentBoard[randomIndex]
          )
        data = data.killCreature({
          ...data,
          location: "attacker",
          card: data.attackerCurrentBoard[randomIndex],
        })
      }
    }
    return data
  },
  scrollOfReturn: (data) => {
    if (data.location === "attacker") {
      data = data.drawCards(data, 2)
      if (!data.discardCard) {
        data = data.spellSplice(data)
      }
    } else {
      data = data.drawCards(data, 2, "opponent")
    }
    return data
  },
  slaveWarriors: (data) => {
    const slaveWarrior = {
      name: "Slave Warriors",
      type: "creature",
      class: "human",
      attack: data.card.attack,
      defense: data.card.defense,
      damageTaken: 0,
      cost: 2,
      text: "When played summon a copy\nTransfer stats to a random friendly minion when sacrificed",
      abilities: ["slaveWarriors"],
      activatesOn: ["noTarget"],
      effects: [],
      rarity: "common",
      id: data.randomId(data),
      image: "slaveWarriors",
      mythology: "token",
      sacrificialPawn: "aztecSlave",
      token: true,
    }
    data = data.playCreature({
      ...data,
      creature: slaveWarrior,
      castSpell: false,
    })
    return data
  },
  aztecHealer: (data) => {
    if (data.target.type === "self") {
      data = data.calculateHealing(data, 4)
    } else {
      if (data.mySpellCast.id !== data.target.id) {
        const target =
          data.attackerCurrentBoard[
            data.attackerCurrentBoard.findIndex(
              (card) => card.id === data.target.id
            )
          ]
        target.damageTaken > 4
          ? (target.damageTaken -= 4)
          : (target.damageTaken = 0)
        if (data.isHuman(target)) {
          target.attack += 1
          target.defense += 1
        }
      }
    }
    return data
  },
  theHolySpirit: (data) => {
    if (data.location === "attacker") {
      data = data.calculateHealing(data, 4)
    } else {
      data = data.calculateHealing(data, 4, "opponent")
    }
    return data
  },
  lilithDarkGoddess: (data) => {
    data.spellReduction.reducedCost = true
    data.spellReduction.lifeCost = { payLife: true, amount: 10000 }
    return data
  },
  lilithFirstWife: (data) => {
    data.creatureReduction = {
      reduceCost: 0,
      reducedCost: true,
      lifeCost: { payLife: true, amount: 10000 },
      dontSplice: false,
      reset: false,
    }
    return data
  },
  gabriel: (data) => {
    const statSteal = { attack: 0, defense: 0 }
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      if (
        data.defenderCurrentBoard[i].attack === 1 &&
        data.defenderCurrentBoard[i].defense === 1
      ) {
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[i],
          kill: true,
        })
      }
      data.kill = false
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      if (
        data.defenderCurrentBoard[i].attack > 1 ||
        data.defenderCurrentBoard[i].defense > 1
      ) {
        if (data.defenderCurrentBoard[i].attack > 1) {
          if (data.defenderCurrentBoard[i].attack > 2) {
            data.defenderCurrentBoard[i].attack -= 2
            statSteal.attack += 2
          } else if (data.defenderCurrentBoard[i].attack > 1) {
            data.defenderCurrentBoard[i].attack -= 1
            statSteal.attack += 1
          }
        }
        if (data.defenderCurrentBoard[i].defense > 1) {
          if (data.defenderCurrentBoard[i].defense > 2) {
            data.defenderCurrentBoard[i].defense -= 2
            statSteal.defense += 2
          } else if (data.defenderCurrentBoard[i].defense > 1) {
            data.defenderCurrentBoard[i].defense -= 1
            statSteal.defense += 1
          }
        }
      }
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
      })
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }

    data.buffCard = statSteal
    return data
  },
  lightbringer: (data) => {
    if (data.mySpellCast) {
      const lucifer = data.attackerCurrentBoard.find(
        (lucifer) => lucifer.id === data.mySpellCast.id
      )
      if (data.attacked.find((card) => card.id === lucifer.id)) {
        data.attacked.splice(
          data.attacked.findIndex((attacker) => attacker.id === lucifer.id),
          1
        )
      }
      lucifer.name = "Lucifer Lightbringer"
      lucifer.text = lucifer.text.replace(
        "ETB: Choose One:\nLightbringer\nOpen the gates to the Kingdom of Heaven, Lucifer gains haste and elusive then you gain 1 max life and heal for 1 for each human and angel on your board\n\nFallen Angel\nOpen the gates to the Kingdom of Hell becoming a demon, gain Provoke, and then summon 3 Fiends with haste and perishable",
        "Haste\nKingdom of Heaven\nElusive"
      )
      lucifer.image = "luciferLightbringer"
      lucifer.effects = ["haste"]
      lucifer.abilities = ["lightbringer"]
      lucifer.activatesOn = ["noTarget"]
      lucifer.elusive = true
      if ("choiceText" in lucifer) {
        delete lucifer.choiceText
      }

      const convertToNephilim = (card) => {
        if (data.isHuman(card)) {
          card.class = "nephilim"
        }
        return card
      }

      data.myCurrentGY = data.myCurrentGY.map(convertToNephilim)
      data.myHand = data.myHand.map(convertToNephilim)
      data.deck = data.deck.map(convertToNephilim)
      data.attackerCurrentBoard =
        data.attackerCurrentBoard.map(convertToNephilim)

      let healAmount = 0
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        if (
          data.isHuman(data.attackerCurrentBoard[i]) ||
          data.isAngel(data.attackerCurrentBoard[i])
        ) {
          healAmount += 1
        }
      }
      data = data.calculateHealing(data, healAmount)
      data.myPlayer.defense += healAmount
    }
    data.myKingdomOfHeaven = true

    return data
  },
  fallenAngel: (data) => {
    if (data.mySpellCast) {
      const lucifer = data.attackerCurrentBoard.find(
        (lucifer) => lucifer.id === data.mySpellCast.id
      )
      lucifer.name = "Lucifer Fallen Angel"
      lucifer.text = lucifer.text.replace(
        "ETB: Choose One:\nLightbringer\nOpen the gates to the Kingdom of Heaven, Lucifer gains haste and elusive then you gain 1 max life and heal for 1 for each human and angel on your board\n\nFallen Angel\nOpen the gates to the Kingdom of Hell becoming a demon, gain Provoke, and then summon 3 Fiends with haste and perishable",
        "Provoke\nKingdom of Hell\nWhen played Summon 3 Fiends with haste and perishable"
      )
      lucifer.image = "luciferFallenAngel"
      lucifer.class = "demon"
      lucifer.abilities = ["fallenAngel"]
      lucifer.effects = ["provoke"]
      lucifer.activatesOn = ["noTarget"]
      if ("choiceText" in lucifer) {
        delete lucifer.choiceText
      }

      const convertDemon = (card) => {
        if (data.isHuman(card)) {
          card.class = "demon"
        }
        return card
      }

      data.myHand.map(convertDemon)
      data.myCurrentGY.map(convertDemon)
      data.deck.map(convertDemon)
      data.attackerCurrentBoard.map(convertDemon)
    }

    const fiendConstructor = () => {
      const fiend = {
        name: "Fiend",
        type: "creature",
        class: "demon",
        attack: 2,
        defense: 2,
        damageTaken: 0,
        cost: 2,
        text: "Haste\nPerishable",
        abilities: [],
        activatesOn: [],
        effects: ["haste"],
        rarity: "legendary",
        id: data.randomId(data),
        image: "fiend",
        mythology: "biblical",
        token: true,
        perishable: true,
      }
      return fiend
    }

    data.myKingdomOfHell = true

    for (let i = 0; i < 3; i++) {
      const fiend = fiendConstructor()
      data = data.playCreature({
        ...data,
        creature: fiend,
        castSpell: false,
      })
    }

    return data
  },
  michael: (data) => {
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (data.isAngel(data.deck[i])) {
          data.deck[i].cost =
            data.deck[i].cost > 2 ? (data.deck[i].cost -= 2) : 0
          data.myHand = [...data.myHand, data.deck.splice(i, 1)[0]]
          break
        }
      }
    } else {
      for (let i = 0; i < data.myCurrentGY.length; i++) {
        if (data.isAngel(data.myCurrentGY[i])) {
          data.myCurrentGY[i].cost =
            data.myCurrentGY[i].cost > 2 ? (data.myCurrentGY[i].cost -= 2) : 0
          data.myHand = [...data.myHand, data.myCurrentGY.splice(i, 1)[0]]
          break
        }
      }
    }

    const buffAngels = (card) => {
      if (data.isAngel(card)) {
        card.attack += 2
        card.defense += 2
      }
      return card
    }

    data.myHand.map(buffAngels)
    data.myCurrentGY.map(buffAngels)
    data.deck.map(buffAngels)
    data.attackerCurrentBoard.map(buffAngels)
    return data
  },
  michaelGuardianOfSouls: (data) => {
    // data.opponentOnPlayGlobalsFunction
    //TODO: MAKE WORK FOR OPPONENT SUMMONS
    if (data.onPlayGlobalsFunction) {
      data.buffCard = { attack: 3, defense: 3 }
    } else if (
      data.location === "attacker" &&
      data.myCurrentGY[0]?.id === data?.card?.id
    ) {
      data.myCurrentGY[0].attack += 2
      data.myCurrentGY[0].defense += 2
    } else if (
      data.location === "defender" &&
      data.theirCurrentGY[0]?.id === data?.card?.id
    ) {
      data.theirCurrentGY[0].attack += 2
      data.theirCurrentGY[0].defense += 2
    } else if (data.playCreatureFunction && !data.onPlayGlobalsFunction) {
      data.myCurrentGY = data.myCurrentGY.map((card) => {
        if (card.type === "creature" || card.type === "spellcaster") {
          card.attack += 1
          card.defense += 1
        }
        return card
      })
    }

    return data
  },
  raziel: (data) => {
    const razielClone = {
      ...data.card,
      id: data.randomId(data),
      mythology: "token",
      token: true,
    }
    data = data.playCreature({
      ...data,
      creature: razielClone,
      castSpell: false,
    })
    return data
  },
  abaddon: (data) => {
    const fiendConstructor = () => {
      const fiend = {
        name: "Fiend",
        type: "creature",
        class: "demon",
        attack: 2,
        defense: 2,
        damageTaken: 0,
        cost: 2,
        text: "Haste\nPerishable",
        abilities: [],
        activatesOn: [],
        effects: ["haste"],
        rarity: "legendary",
        id: data.randomId(data),
        image: "fiend",
        mythology: "biblical",
        token: true,
        perishable: true,
      }
      return fiend
    }

    const fiendOne = fiendConstructor()
    const fiendTwo = fiendConstructor()

    data = data.playCreature({
      ...data,
      creature: fiendOne,
      castSpell: false,
    })
    data = data.playCreature({
      ...data,
      creature: fiendTwo,
      castSpell: false,
    })

    return data
  },
  azazel: (data) => {
    const maxDraw = data.myPlayer.sacrifices > 2 ? 2 : data.myPlayer.sacrifices
    data = data.drawCards(data, maxDraw)
    data.myPlayer.sacrifices -= maxDraw

    return data
  },
  baphometChaosweaver: (data) => {
    const originalLength = data.attackerCurrentBoard.length
    let count = 0
    while (count < originalLength) {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.attackerCurrentBoard[0],
        kill: true,
      })
      count++
    }
    const values = [...data.defenderCurrentBoard]

    while (count > 0 && values.length > 0) {
      const randomIndex = Math.floor(Math.random() * values.length)
      data = data.killCreature({
        ...data,
        location: "defender",
        card: values[randomIndex],
        kill: true,
      })
      values.splice(randomIndex, 1)
      count--
    }
    return data
  },
  beelzebubPrinceOfDemons: (data) => {
    const buffDemon = (card) => {
      if (data.isDemon(card)) {
        card.attack += 2
        card.defense += 2
        card.cost = card.cost > 1 ? (card.cost -= 1) : card.cost
      }
    }

    data.myHand.map(buffDemon)
    data.deck.map(buffDemon)
    data.myCurrentGY.map(buffDemon)
    data.attackerCurrentBoard.map(buffDemon)

    return data
  },
  belialTheCorruptor: (data) => {
    const costReduce = (card) => {
      if (data.isHuman(card) || data.isDemon(card)) {
        card.cost = card.cost > 1 ? (card.cost -= 1) : card.cost
      }
      return card
    }

    data.myHand.map(costReduce)
    data.deck.map(costReduce)
    data.myCurrentGY.map(costReduce)
    data.attackerCurrentBoard.map(costReduce)

    return data
  },
  molech: (data) => {
    data.buffCard = {
      attack: data.myPlayer.sacrifices,
      defense: data.myPlayer.sacrifices,
    }
    data.myPlayer.sacrifices -= 2
    return data
  },
  legion: (data) => {
    const legionConstructor = () => {
      const legion = {
        name: "Legion",
        type: "creature",
        class: "demon",
        attack: data.card.attack,
        defense: data.card.defense,
        damageTaken: 0,
        cost: data.card.cost,
        text: "When played summon two copies",
        abilities: ["legion"],
        activatesOn: ["noTarget"],
        effects: [],
        rarity: "legendary",
        id: data.randomId(data),
        image: "legion",
        mythology: "token",
        token: true,
      }
      return legion
    }
    const legionOne = legionConstructor()
    const legionTwo = legionConstructor()
    data = data.playCreature({
      ...data,
      creature: legionOne,
      castSpell: false,
    })

    data = data.playCreature({
      ...data,
      creature: legionTwo,
      castSpell: false,
    })

    return data
  },
  baalLordOfFlames: (data) => {
    if (data.target.type === "creature" || data.target.type === "spellcaster") {
      const faceDamage =
        data.myPlayer.sacrifices > data.target.defense - data.target.damageTaken
          ? data.myPlayer.sacrifices -
            (data.target.defense - data.target.damageTaken)
          : 0

      data = data.resolveDamage(data, data.myPlayer.sacrifices)
      data = data.resolveDamage(data, faceDamage, "opponent")
      data.myPlayer.sacrifices -= 2
    }
    return data
  },
  mammonLordOfGreed: (data) => {
    data.myPlayer.sacrifices += 3
    return data
  },
  astarothTheInfernal: (data) => {
    let kill = false
    data = data.resolveDamage(data, 1, "opponent", false)
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data.defenderCurrentBoard[i].damageTaken += data.resolveDamage(
        data,
        1,
        "dontKill",
        false,
        false,
        data.defenderCurrentBoard[i]
      )
    }
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
      })
      if (data.attackerKill) {
        kill = true
        i--
      }
      data.attackerKill = false
    }

    if (kill) {
      data = spellFunctions["astarothTheInfernal"](data)
    }

    return data
  },
  seraphinna: (data) => {
    const cardBuffs = (card) => {
      if (data.isHuman(card)) {
        card.attack += 1
        card.defense += 1
      }
      if (data.isAngel(card)) {
        card.cost = card.cost > 1 ? (card.cost -= 1) : card.cost
      }
      return card
    }
    data.buffCard = { attack: 1, defense: 1 }
    data.myHand.map(cardBuffs)
    data.myCurrentGY.map(cardBuffs)
    data.deck.map(cardBuffs)
    data.attackerCurrentBoard.map(cardBuffs)

    return data
  },
  hercules: (data) => {
    const cardBuffs = (card) => {
      if (data.isHuman(card)) {
        data = data.drawCards(data, 1)
      }
      if (data.isAGod(card)) {
        card.attack += 1
        card.defense += 1
      }
      return card
    }
    cardBuffs(data.card)
    data.attackerCurrentBoard.map(cardBuffs)

    return data
  },
  alexanderTheGreat: (data) => {
    const buffCreatures = (card) => {
      if (card.type === "creature" || card.type === "spellcaster") {
        card.attack += 1
        card.defense += 1
      }
      return card
    }

    if (data.drawFromGraveyard)
      data.myCurrentGY = data.myCurrentGY.map(buffCreatures)
    else data.deck = data.deck.map(buffCreatures)

    data = data.drawCards(data, 1)
    return data
  },
  andvarisTreasure: (data) => {
    data.myCurrentMana += 1
    data = data.spellSplice(data)
    data.myPlayer.defense += 3
    return data
  },
  cleopatra: (data) => {
    const target = data.attackerCurrentBoard.find(
      (card) => card.id === data.target.id
    )
    const cleopatra = data.attackerCurrentBoard.find(
      (card) => card.id === data.mySpellCast.id
    )
    cleopatra.pairID = target.id
    target.pairID = cleopatra.pairID
    target.effects.push("initialBlow")
    target.pairEffect = { effects: "initialBlow", text: "Initial Blow\n" }
    const textArray = target.text.split(" ")
    textArray.unshift("Initial Blow\n")
    target.text = textArray.join(" ")

    return data
  },
  morganLeFey: (data) => {
    if (!data.startTurn) {
      const soulDrain = {
        name: "Soul Drain",
        type: "spell",
        class: "sorcery",
        attack: null,
        defense: null,
        damageTaken: 0,
        cost: 1,
        text: "Deal 3 damage to an enemy minion, if it kills it draw a card",
        abilities: ["soulDrain"],
        activatesOn: [],
        effects: [],
        rarity: "common",
        id: data.randomId(data),
        image: "soulDrain",
        mythology: "celtic",
        token: true,
      }
      data.myHand.push(soulDrain)
    } else {
      const lightningBolt = {
        name: "Lightning Bolt",
        type: "spell",
        class: "sorcery",
        attack: null,
        defense: null,
        damageTaken: 0,
        cost: 1,
        text: "Deal 4 damage to an enemy target and if you target their player deal an additional 0/-2 damage to their armor\nMelt: 3",
        abilities: ["lightningBolt"],
        activatesOn: [],
        effects: [],
        melt: 3,
        rarity: "common",
        id: data.randomId(data),
        image: "lightningBolt",
        mythology: "celtic",
        token: true,
      }
      data.myHand.push(lightningBolt)
    }
    return data
  },
  merlin: (data) => {
    if (data.startTurn) {
      data = data.drawCards(data, 1)
    }
    if(data.defenderCurrentBoard.length === 0){
      data = data.resolveDamage(data, 3, "opponent")
    }else{
      const randomTarget = Math.floor(
        Math.random() * (data.defenderCurrentBoard.length)
      )
        data.defenderCurrentBoard[randomTarget].damageTaken += data.resolveDamage(
          data,
          3,
          "dontKill",
          false,
          false,
          data.defenderCurrentBoard[randomTarget]
        )
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[randomTarget],
        })
    }
    data = data.calculateHealing(data, 3)
    return data
  },
  archlichSovereign: (data) => {
    const cardBuff = (card) => {
      if (data.isUndead(card)) {
        card.attack += 1
        card.defense += 1
      }
      return card
    }
    data.deck.map(cardBuff)
    data.myCurrentGY.map(cardBuff)

    return data
  },
  corpseCrawler: (data) => {
    data.theirPlayer.poison += 3
    return data
  },
  blightBehmoth: (data) => {
    const myGY = data.myCurrentGY.filter(
      (card) => card.type === "spellcaster" || card.type === "creature"
    )
    const theirGY = data.theirCurrentGY.filter(
      (card) => card.type === "spellcaster" || card.type === "creature"
    )
    if (!myGY.length && !theirGY.length) {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.card,
        kill: true,
      })
    } else if (!myGY.length || !theirGY.length) {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.card,
        kill: true,
      })
      data = data.drawCards(data, 1)
    } else {
      data = data.drawCards(data, 2)
    }

    if (myGY.length) {
      const randomIndex = Math.floor(Math.random() * myGY.length)
      for (let i = 0; i < data.myCurrentGY.length; i++) {
        if (data.myCurrentGY[i].id === myGY[randomIndex].id) {
          data.myCurrentGY.splice(i, 1)
          break
        }
      }
    }

    if (theirGY.length) {
      const randomIndex = Math.floor(Math.random() * theirGY.length)
      for (let i = 0; i < data.theirCurrentGY.length; i++) {
        if (data.theirCurrentGY[i].id === theirGY[randomIndex].id) {
          data.theirCurrentGY.splice(i, 1)
          break
        }
      }
    }

    return data
  },
  boneLeviathan: (data) => {
    const myGY = data.myCurrentGY.filter(
      (card) => card.type === "creature" || card.type === "spellcaster"
    )
    let count = 3
    while (count > 0 && myGY.length) {
      const randomIndex = Math.floor(Math.random() * myGY.length)
      data.myCurrentGY.splice(
        data.myCurrentGY.findIndex((card) => card.id === myGY[randomIndex].id),
        1
      )
      myGY.splice(randomIndex, 1)
      count--
    }

    data.buffCard = { attack: 6 - count * 2, defense: 3 - count }

    return data
  },
  necromanticInfusion: (data) => {
    const corpseConstructor = () => {
      const corpse = {
        name: "Corpse",
        type: "creature",
        class: "undead",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Fate: Draw a card and play this card",
        abilities: ["corpse"],
        activatesOn: ["doNotCast"],
        effects: ["whenDrawn"],
        rarity: "common",
        id: data.randomId(data),
        image: "corpse",
        mythology: "norse",
      }
      return corpse
    }
    for (let i = 0; i < 3; i++) {
      data.myCurrentGY.unshift(corpseConstructor())
      const randomIndex = Math.floor(Math.random() * data.deck.length)
      data.deck.splice(randomIndex, 0, corpseConstructor())
    }
    data = data.spellSplice(data)
    return data
  },
  corpse: (data) => {
    if (data.location === "attacker") {
      const corpse = data.myHand.splice(
        data.myHand.findIndex((card) => card.id === data.drawnCardLoop.id),
        1
      )[0]
      data = data.playCreature({
        ...data,
        creature: corpse,
        castSpell: false,
      })
      data = data.drawCards(data, 1)
    } else {
      const corpse = data.theirHand.splice(
        data.theirHand.findIndex(
          (card) => card.id === data.theirDrawnCardLoop.id
        ),
        1
      )[0]
      data = data.playCreatureOnOpponentBoard({
        ...data,
        creature: corpse,
        castSpell: false,
      })
      data = data.drawCards(data, 1, "opponent")
    }
    return data
  },
  arcaneTorrent: (data) => {
    const originalLength = data.attackerCurrentBoard.length
    let count = 0
    while (count < originalLength) {
      data = data.killCreature({
        ...data,
        location: "attacker",
        card: data.attackerCurrentBoard[0],
        kill: true,
      })
      count++
    }
    count = 3
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++) {
        if (
          (data.deck[i].type === "creature" ||
            data.deck[i].type === "spellcaster") &&
          !data.deck[i].effects.includes("playWhenDrawn") &&
          !data.deck[i].effects.includes("whenDrawn") &&
          !data.deck[i]?.choiceText
        ) {
          data.deck[i].effects.push("playWhenDrawn")
          const newText = data.deck[i].text.split("")
          newText.unshift("Fate: Play when drawn\n")
          data.deck[i].text = newText.join("")
          count--
        }
        if (count === 0) break
      }
    } else {
      for (let i = 0; i < data.myCurrentGY.length; i++) {
        if (
          (data.myCurrentGY[i].type === "creature" ||
            data.myCurrentGY[i].type === "spellcaster") &&
          !data.myCurrentGY[i].effects.includes("playWhenDrawn") &&
          !data.myCurrentGY[i].effects.includes("whenDrawn") &&
          !data.myCurrentGY[i]?.choiceText
        ) {
          data.myCurrentGY[i].effects.push("playWhenDrawn")
          const newText = data.myCurrentGY[i].text.split("")
          newText.unshift("Fate: Play when drawn\n")
          data.myCurrentGY[i].text = newText.join("")
          count--
        }
        if (count === 0) break
      }
    }
    data = data.drawCards(data, originalLength)
    data = data.spellSplice(data)
    return data
  },
  deathmarchHorde: (data) => {
    const corpseConstructor = () => {
      const corpse = {
        name: "Corpse",
        type: "creature",
        class: "undead",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Fate: Draw a card and play this card",
        abilities: ["corpse"],
        activatesOn: ["doNotCast"],
        effects: ["whenDrawn"],
        rarity: "common",
        id: data.randomId(data),
        image: "corpse",
        mythology: "norse",
      }
      return corpse
    }
    for (let i = 0; i < 2; i++) {
      data.myCurrentGY.unshift(corpseConstructor())
      const randomIndex = Math.floor(Math.random() * data.deck.length)
      data.deck.splice(randomIndex, 0, corpseConstructor())
    }
    return data
  },
  necroticForest: (data) => {
    const values = data.myCurrentGY.filter(
      (card) => card.type === "creature" || card.type === "spellcaster"
    )

    while (data.defenderCurrentBoard.length) {
      let shouldKillCreature = true // Flag to determine if killCreature should be called

      const remainingDefense =
        data.defenderCurrentBoard[0].defense -
        data.defenderCurrentBoard[0].damageTaken

      if (remainingDefense > values.length) {
        break // No more defense left, exit the loop
      }

      for (let i = 0; i < remainingDefense; i++) {
        const randomIndex = Math.floor(Math.random() * values.length)
        data.myCurrentGY.splice(
          data.myCurrentGY.findIndex(
            (card) => card.id === values[randomIndex].id
          ),
          1
        )
        values.splice(randomIndex, 1)
      }

      if (shouldKillCreature) {
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[0],
          kill: true,
        })
      }
    }

    data.kill = false

    return data
  },
  jophielIlluminatedGuardian: (data) => {
    if (!data.drawFromGraveyard) {
      const angel = data.deck.find(
        (card) => card.cost === data.mana + 1 && data.isAngel(card)
      )
      if (angel) {
        data.myHand = [
          ...data.myHand,
          data.deck.splice(
            data.deck.findIndex((card) => card.id === angel.id),
            1
          )[0],
        ]
      } else {
        const anyAngel = data.deck.find((card) => data.isAngel(card))
        if (anyAngel) {
          data.myHand = [
            ...data.myHand,
            data.deck.splice(
              data.deck.findIndex((card) => card.id === anyAngel.id),
              1
            )[0],
          ]
        } else {
          data = data.drawCards(data, 1)
        }
      }
    } else {
      const angel = data.myCurrentGY.find(
        (card) => card.cost === data.mana + 1 && data.isAngel(card)
      )
      if (angel) {
        data.myHand = [
          ...data.myHand,
          data.myCurrentGY.splice(
            data.myCurrentGY.findIndex((card) => card.id === angel.id),
            1
          )[0],
        ]
      } else {
        const anyAngel = data.myCurrentGY.find((card) => data.isAngel(card))
        if (anyAngel) {
          data.myHand = [
            ...data.myHand,
            data.myCurrentGY.splice(
              data.myCurrentGY.findIndex((card) => card.id === anyAngel.id),
              1
            )[0],
          ]
        } else {
          data = data.drawCards(data, 1)
        }
      }
    }
    return data
  },
  eldritchGateway: (data) => {
    const firstPieceOfRlyeh = {
      name: "First piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: All minions on your board take 1 damage",
      abilities: ["firstRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "firstPiece",
      mythology: "lovecraft",
    }
    const secondPieceOfRlyeh = {
      name: "Second piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: Your opponent drains you for 3",
      abilities: ["secondRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "secondPiece",
      mythology: "lovecraft",
    }
    const thirdPieceOfRlyeh = {
      name: "Third piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: Your player receives 2 poison at the start of turn",
      abilities: ["thirdRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "thirdPiece",
      mythology: "lovecraft",
    }
    const fourthPieceOfRlyeh = {
      name: "Fourth piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: Your opponent summons a minion from their graveyard to the board",
      abilities: ["fourthRlyeh"],
      activatesOn: ["inGraveyard"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "fourthPiece",
      mythology: "lovecraft",
    }
    const fifthPieceOfRlyeh = {
      name: "Fifth piece of R'lyeh",
      type: "spell",
      class: "realm",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 5,
      text: "Realm: The sunken city rises if all five pieces are in the graveyard and releases C'Thulu from his prison if he is on the board or when he is played",
      abilities: [],
      activatesOn: [],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "fifthPiece",
      mythology: "lovecraft",
    }
    const piecesOfRlyeh = [
      firstPieceOfRlyeh,
      secondPieceOfRlyeh,
      thirdPieceOfRlyeh,
      fourthPieceOfRlyeh,
      fifthPieceOfRlyeh,
    ]
    let piecesFound = [false, false, false, false, false]
    for (let i = 0; i < data.theirCurrentGY.length; i++) {
      if (data.theirCurrentGY.length === 0) {
        break
      }
      const cardName = data.theirCurrentGY[i].name
      if (cardName.includes("First piece of R'lyeh")) {
        piecesFound[0] = true
      }
      if (cardName.includes("Second piece of R'lyeh")) {
        piecesFound[1] = true
      }
      if (cardName.includes("Third piece of R'lyeh")) {
        piecesFound[2] = true
      }
      if (cardName.includes("Fourth piece of R'lyeh")) {
        piecesFound[3] = true
      }
      if (cardName.includes("Fifth piece of R'lyeh")) {
        piecesFound[4] = true
      }
    }
    for (let i = 0; i < piecesFound.length; i++) {
      if (piecesFound[i] === false) {
        data.theirCurrentGY.unshift(piecesOfRlyeh[i])
        break
      }
    }

    const findCthulu = (cards) => {
      const index = cards.findIndex((card) => card.name === "C'Thulu")
      if (index !== -1) {
        return cards.splice(index, 1)[0]
      }
      return null
    }

    const sources = [data.deck, data.myCurrentGY, data.attackerCurrentBoard]

    for (const source of sources) {
      const cthuluCard = findCthulu(source)
      if (cthuluCard) {
        data.myHand.push(cthuluCard)
        break // Stop searching after finding C'thulu in one source
      }
    }

    data = data.spellSplice(data)
    return data
  },
  urielOfTheLight: (data) => {
    data.myPlayer.defense += data.attacker.attack
    return data
  },
  raphielRadiantHealer: (data) => {
    if (!data.startTurn) {
      data.myPlayer.revitalize += 5
    } else {
      data.myPlayer.revitalize += 3
    }
    return data
  },
  cronos: (data) => {
    if (data.playCreatureFunction) {
      data.extraTurn = true
    }
    return data
  },
  holyFire: (data) => {
    if (data.drawnCardLoop) {
      const newHolyFire = { ...data.drawnCardLoop }
      newHolyFire.id = data.randomId(data)
      data.myHand.push(newHolyFire)
    } else if (data.theirDrawnCardLoop) {
      const newHolyFire = { ...data.theirDrawnCardLoop }
      newHolyFire.id = data.randomId(data)
      data.theirHand.push(newHolyFire)
    } else {
      data = data.resolveDamage(data, 5)
      data = data.drawCards(data, 1)
      data = data.spellSplice(data)
    }
    return data
  },
  sandalphonCelestialEmissary: (data) => {
    if (data.drawnCardLoop) {
      data.myPlayer.defense += 3
    } else if (data.theirDrawnCardLoop) {
      data.theirPlayer.defense += 3
    } else {
      data.myPlayer.defense += 3
    }
    return data
  },
  incubus: (data) => {
    if (data.drawnCardLoop) {
      const target = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      if (data.defenderCurrentBoard[target]) {
        data.defenderCurrentBoard[target].damageTaken += data.resolveDamage(
          data,
          2,
          "dontKill",
          false,
          false,
          data.defenderCurrentBoard[target]
        )
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[target],
        })
      }
      data.myPlayer.sacrifices += 1
    } else if (data.theirDrawnCardLoop) {
      const target = Math.floor(
        Math.random() * data.attackerCurrentBoard.length
      )
      if (data.attackerCurrentBoard[target]) {
        data.attackerCurrentBoard[target].damageTaken += data.resolveDamage(
          data,
          2,
          "dontKill",
          false,
          false,
          data.attackerCurrentBoard[target]
        )
        data = data.killCreature({
          ...data,
          location: "attacker",
          card: data.attackerCurrentBoard[target],
        })
      }
      data.theirPlayer.sacrifices += 1
    } else {
      data = data.resolveDamage(data, 1)
      data.myPlayer.sacrifices += 1
    }
    return data
  },
  ember: (data) => {
    if (!data.handFunction) {
      data.spellCast = { ...data.card }
      data = data.drawCards(data, 2)
      data.handNumber = 1
      data.handSelection = true
      data.pauseGame = true
    } else {
      const card = data.handChoices[0]
      data.deck = [
        data.myHand.splice(
          data.myHand.findIndex((cardInHand) => cardInHand.id === card.id),
          1
        )[0],
        ...data.deck,
      ]
    }
    return data
  },
  belphegor: (data) => {
    if (data.OnDeathFunction) {
      if (data.location === "attacker") {
        data = data.resolveDamage(data, 2, "opponent")
      } else if (data.location === "defender") {
        data = data.resolveDamage(data, 2, "self")
      }
    } else {
      const defenderCurrentBoardOriginalLength =
        data.defenderCurrentBoard.length
      for (let i = 0; i < defenderCurrentBoardOriginalLength; i++) {
        const creature = data.defenderCurrentBoard[i]
        if(!creature) break

        if (creature.attack > 1) {
          creature.attack = Math.max(0, creature.attack - 2)
        }

        const killCreature = creature.defense > 2 ? false : true

        if (creature.defense > 1) {
          creature.defense = Math.max(1, creature.defense - 2)
        }

        data = data.killCreature({
          ...data,
          location: "defender",
          card: creature,
          kill: killCreature,
        })

        if (data.attackerKill) {
          i--
        }
        data.attackerKill = false
      }
    }

    return data
  },
  heaven: (data) => {
    if (!data.drawFromGraveyard) {
      const luciferIndex = data.deck.findIndex(
        (card) => card.name === "Lucifer"
      )
      if (luciferIndex !== -1) {
        const lucifer = data.deck.splice(luciferIndex, 1)[0]
        data.myHand = [...data.myHand, lucifer]
      }
    } else {
      const lucifer = data.myCurrentGY.find((card) => card.name === "Lucifer")
      if (lucifer !== undefined) {
        const luciferIndex = data.myCurrentGY.indexOf(lucifer)
        data.myCurrentGY.splice(luciferIndex, 1)
        data.myHand = [...data.myHand, lucifer]
      }
    }

    data.myPlayer.revitalize += 4
    data = data.calculateHealing(data, 6)
    data = data.spellSplice(data)
    return data
  },
  hell: (data) => {
    if (!data.drawFromGraveyard) {
      const luciferIndex = data.deck.findIndex(
        (card) => card.name === "Lucifer"
      )
      if (luciferIndex !== -1) {
        const lucifer = data.deck.splice(luciferIndex, 1)[0]
        data.myHand = [...data.myHand, lucifer]
      }
    } else {
      const lucifer = data.myCurrentGY.find((card) => card.name === "Lucifer")
      if (lucifer !== undefined) {
        const luciferIndex = data.myCurrentGY.indexOf(lucifer)
        data.myCurrentGY.splice(luciferIndex, 1)
        data.myHand = [...data.myHand, lucifer]
      }
    }

    const fiendConstructor = () => {
      const fiend = {
        name: "Fiend",
        type: "creature",
        class: "demon",
        attack: 2,
        defense: 2,
        damageTaken: 0,
        cost: 2,
        text: "Haste\nPerishable",
        abilities: [],
        activatesOn: [],
        effects: ["haste"],
        rarity: "legendary",
        id: data.randomId(data),
        image: "fiend",
        mythology: "biblical",
        token: true,
        perishable: true,
      }
      return fiend
    }

    for (let i = 0; i < 3; i++) {
      const fiend = fiendConstructor()
      data = data.playCreature({
        ...data,
        creature: fiend,
        castSpell: false,
      })
    }

    data = data.spellSplice(data)
    return data
  },
  anubisChosen: (data) => {
    if (data.location === "attacker") {
      if (!data.drawFromGraveyard) {
        for (let i = 0; i < data.deck.length; i++) {
          if (
            data.deck[i].cost < 3 &&
            (data.deck[i].type === "creature" ||
              data.deck[i].type === "spellcaster")
          ) {
            const playCreature = data.deck.splice(i, 1)[0]
            data = data.playCreature({
              ...data,
              creature: playCreature,
              castSpell: false,
            })
            break
          }
        }
      } else {
        for (let i = 0; i < data.myCurrentGY.length; i++) {
          if (
            data.myCurrentGY[i].cost < 3 &&
            data.myCurrentGY[i].name !== "Anubis' Chosen" &&
            (data.myCurrentGY[i].type === "creature" ||
              data.myCurrentGY[i].type === "spellcaster")
          ) {
            const playCreature = data.myCurrentGY.splice(i, 1)[0]
            data = data.playCreature({
              ...data,
              creature: playCreature,
              castSpell: false,
            })
            break
          }
        }
      }
    } else if (data.location === "defender") {
      if (!data.opponentDrawFromGraveyard) {
        for (let i = 0; i < data.theirDeck.length; i++) {
          if (
            data.theirDeck[i].cost < 3 &&
            (data.theirDeck[i].type === "creature" ||
              data.theirDeck[i].type === "spellcaster")
          ) {
            const playCreature = data.theirDeck.splice(i, 1)[0]
            data = data.playCreatureOnOpponentBoard({
              ...data,
              creature: playCreature,
              castSpell: false,
            })
            break
          }
        }
      } else {
        for (let i = 0; i < data.theirCurrentGY.length; i++) {
          if (
            data.theirCurrentGY[i].cost < 3 &&
            (data.theirCurrentGY[i].type === "creature" ||
              data.theirCurrentGY[i].type === "spellcaster")
          ) {
            const playCreature = data.theirCurrentGY.splice(i, 1)[0]
            data = data.playCreatureOnOpponentBoard({
              ...data,
              creature: playCreature,
              castSpell: false,
            })
            break
          }
        }
      }
    }
    return data
  },
  gashadokuro: (data) => {
    data = data.discardCards(data, 1, true, "self")
    if (data.discardedCards.self.length) {
      data = data.resolveDamage(
        data,
        data.discardedCards.self[0].cost,
        "opponent"
      )
    }
    return data
  },
  rallyingZephyr: (data) => {
    data.turnPass = true
    const cardBuff = (card) => {
      card.attack += 2
      card.defense += 2
      return card
    }
    data.attackerCurrentBoard = data.attackerCurrentBoard.map(cardBuff)
    data = data.spellSplice(data)
    return data
  },
  theEternal: (data) => {
    if (data.playCreatureFunction) {
      data.buffCard = { attack: 1, defense: 1 }
    } else {
      const eternal = data.attackerCurrentBoard.find(
        (card) => card.id === data.card.id
      )
      eternal.attack += 1
      eternal.defense += 1
    }
    return data
  },
  satan: (data) => {
    data = data.discardCards(data, 1, true, "opponent")
    return data
  },
  skullGiant: (data) => {
    if (data.location === "attacker") {
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        if (data.attackerCurrentBoard[i].id === data.revenge.id) continue
        data.attackerCurrentBoard[i].damageTaken += data.resolveDamage(
          data,
          data.damageReceived,
          "dontKill",
          false,
          false,
          data.attackerCurrentBoard[i]
        )
      }
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        data = data.killCreature({
          ...data,
          location: "attacker",
          card: data.attackerCurrentBoard[i],
        })
        if (data.defenderKill) {
          i--
        }
        data.defenderKill = false
      }
      for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
        if (data.defenderCurrentBoard[i].id === data.revenge.id) continue
        data.defenderCurrentBoard[i].damageTaken += data.resolveDamage(
          data,
          data.damageReceived,
          "dontKill",
          false,
          false,
          data.defenderCurrentBoard[i]
        )
      }
      for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[i],
        })
        if (data.attackerKill) {
          i--
        }
        data.attackerKill = false
      }
    } else {
      for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
        if (data.defenderCurrentBoard[i].id === data.revenge.id) continue
        data.defenderCurrentBoard[i].damageTaken += data.resolveDamage(
          data,
          data.damageReceived,
          "dontKill",
          false,
          false,
          data.defenderCurrentBoard[i]
        )
      }
      for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[i],
        })
        if (data.attackerKill) {
          i--
        }
        data.attackerKill = false
      }
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        if (data.attackerCurrentBoard[i].id === data.revenge.id) continue
        data.attackerCurrentBoard[i].damageTaken += data.resolveDamage(
          data,
          data.damageReceived,
          "dontKill",
          false,
          false,
          data.attackerCurrentBoard[i]
        )
      }
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        data = data.killCreature({
          ...data,
          location: "attacker",
          card: data.attackerCurrentBoard[i],
        })
        if (data.defenderKill) {
          i--
        }
        data.defenderKill = false
      }
    }
    return data
  },
  devoteesCall: (data) => {
    if (!data.prophecy) {
      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    } else {
      const createWorshipper = () => {
        return {
          name: "Worshipper",
          type: "creature",
          class: "human",
          attack: 0,
          defense: 1,
          damageTaken: 0,
          cost: 1,
          text: "Provoke\nCan't Attack",
          abilities: [],
          activatesOn: [],
          effects: ["cantAttack", "provoke"],
          rarity: "common",
          id: data.randomId(data),
          image: "worshipper",
          mythology: "biblical",
          token: true,
        }
      }

      for (let i = 0; i < 4; i++) {
        const worshipper = createWorshipper()
        data = data.playCreature({
          ...data,
          creature: { ...worshipper },
          castSpell: false,
        })
      }
    }
    return data
  },
  fruitfulResearch: (data) => {
    if (!data.prophecy) {
      data = data.drawCards(data, 1)
      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    } else {
      data = data.drawCards(data, 2)
    }
    return data
  },
  towerOfBabel: (data) => {
    if (!data.prophecy) {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
      })

      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    } else {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
      })
    }
    data.kill = false
    return data
  },
  theGreatFlood: (data) => {
    if (!data.prophecy) {
      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    } else {
      data = data.boardAreaOfEffect(data, 0, "both", true)
    }
    return data
  },
  noahsArk: (data) => {
    if (!data.prophecy) {
      const card = data.findCard(data, data.mySpellCast.id)
      card.arkMinions = data.attackerCurrentBoard.splice(
        0,
        data.attackerCurrentBoard.length
      )

      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    } else {
      for (let i = 0; i < data.prophecyCard.arkMinions.length; i++) {
        data = data.playCreature({
          ...data,
          creature: { ...data.prophecyCard.arkMinions[i] },
          castSpell: true,
        })
      }
    }
    return data
  },
  theSerenityPrayer: (data) => {
    data.myPlayer.revitalize += 3
    if (!data.prophecy) {
      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    }
    return data
  },
  etherealConflagration: (data) => {
    const randomHit = Math.floor(
      Math.random() * (data.defenderCurrentBoard.length + 1)
    )
    if (randomHit === data.defenderCurrentBoard.length) {
      data = data.resolveDamage(data, 1, "opponent")
    } else {
      data.defenderCurrentBoard[randomHit].damageTaken += data.resolveDamage(
        data,
        1,
        "dontKill",
        false,
        false,
        data.defenderCurrentBoard[randomHit]
      )
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[randomHit],
      })
    }
    data = data.calculateHealing(data, 1)
    data = data.handleProphecy({
      ...data,
      mySpellCast: data.prophecyCard || data.mySpellCast,
    })
    if (!data.prophecy) data = data.spellSplice(data)

    return data
  },
  oniMasks: (data) => {
    if (!data.prophecy) {
      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    } else {
      const createOni = () => {
        return {
          name: "Oni",
          type: "creature",
          class: "demon",
          attack: 3,
          defense: 1,
          damageTaken: 0,
          cost: 1,
          text: "Haste\nElusive",
          abilities: ["oniMasks"],
          activatesOn: ["noTarget"],
          effects: ["haste"],
          elusive: true,
          rarity: "legendary",
          id: data.randomId(data),
          image: "oni",
          mythology: "japanese",
          token: true,
        }
      }
      for (let i = 0; i < 2; i++) {
        data = data.playCreature({
          ...data,
          creature: createOni(),
          castSpell: false,
        })
      }
    }
    return data
  },
  hyperion: (data) => {
    const card = data.findCard(data, data.target.id)
    card.attack += 2
    return data
  },
  coeus: (data) => {
    if (data.isolationCheck(data.deck)) {
      const buffCreatures = (card) => {
        if (card.type === "creature" || card.type === "spellcaster") {
          card.attack += 1
          card.defense += 1
        }
        return card
      }

      if (data.drawFromGraveyard)
        data.myCurrentGY = data.myCurrentGY.map(buffCreatures)
      else data.deck = data.deck.map(buffCreatures)

      data = data.drawCards(data, 1)
      return data
    }
    return data
  },
  oceanus: (data) => {
    if (data.isolationCheck(data.deck)) {
      data.myPlayer.revitalize += 3
      data.myPlayer.defense += 5
    }
    return data
  },
  uranus: (data) => {
    const drawCards = () => {
      // Check if drawing from the graveyard
      const deckToDrawFrom = data.drawFromGraveyard
        ? data.myCurrentGY
        : data.deck

      // Filter cards based on type
      const matchingCards = deckToDrawFrom.reduce(
        (acc, card) => {
          if (data.isAMonster(card)) {
            acc.monster.push(card)
          } else if (data.isAGod(card)) {
            acc.god.push(card)
          } else if (data.isHuman(card)) {
            acc.human.push(card)
          }
          return acc
        },
        { monster: [], god: [], human: [] }
      )

      const monster = matchingCards?.monster[0]?.id
        ? deckToDrawFrom.splice(
            deckToDrawFrom.findIndex(
              (cardInDeck) => cardInDeck.id === matchingCards.monster[0].id
            ),
            1
          )[0]
        : null

      const god = matchingCards?.god[0]?.id
        ? deckToDrawFrom.splice(
            deckToDrawFrom.findIndex(
              (cardInDeck) => cardInDeck.id === matchingCards?.god[0]?.id
            ),
            1
          )[0]
        : null

      const human = matchingCards?.human[0]?.id
        ? deckToDrawFrom.splice(
            deckToDrawFrom.findIndex(
              (cardInDeck) => cardInDeck.id === matchingCards?.human[0]?.id
            ),
            1
          )[0]
        : null

      monster?.name && data.myHand.push(monster)
      god?.name && data.myHand.push(god)
      human?.name && data.myHand.push(human)
    }

    if (data.isolationCheck(data.deck)) {
      drawCards()
    }

    return data
  },
  twilightLucifer: (data) => {
    const drawCards = () => {
      // Check if drawing from the graveyard
      const deckToDrawFrom = data.drawFromGraveyard
        ? data.myCurrentGY
        : data.deck

      // Filter cards based on type
      const matchingCards = deckToDrawFrom.reduce(
        (acc, card) => {
          if (data.isAngel(card)) {
            acc.angel.push(card)
          } else if (data.isDemon(card)) {
            acc.demon.push(card)
          }
          return acc
        },
        { angel: [], demon: [] }
      )

      const angel = matchingCards?.angel[0]?.id
        ? deckToDrawFrom.splice(
            deckToDrawFrom.findIndex(
              (cardInDeck) => cardInDeck.id === matchingCards.angel[0].id
            ),
            1
          )[0]
        : null

      const demon = matchingCards?.demon[0]?.id
        ? deckToDrawFrom.splice(
            deckToDrawFrom.findIndex(
              (cardInDeck) => cardInDeck.id === matchingCards?.demon[0]?.id
            ),
            1
          )[0]
        : null

      angel?.name && data.myHand.push(angel)
      demon?.name && data.myHand.push(demon)
    }

    if (data.isolationCheck(data.deck)) {
      drawCards()
    }

    return data
  },
  crimsonSovereignty: (data) => {
    if (!data.prophecy) {
      data = data.drawCards(data, 1)
      data = data.handleProphecy(data)
      data = data.spellSplice(data)
    } else if (data.prophecyCard.prophecyCounter === 2) {
      data.myPlayer.revitalize += 3
      data = data.calculateHealing(data, 5)
    } else if (data.prophecyCard.prophecyCounter === 1) {
      const randomIndex = Math.floor(
        Math.random() * data.defenderCurrentBoard.length
      )
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "defender",
        card: data.defenderCurrentBoard[randomIndex],
        kill: true,
      })
      data.kill = false
    } else {
      const kagutsuchi = {
        name: "Kagutsuchi",
        type: "creature",
        class: "god",
        attack: 6,
        defense: 6,
        damageTaken: 0,
        cost: 10,
        text: "When played destroy all minions on your opponents board and summon a 3/1 Fire Elemental for each with provoke",
        abilities: ["kagutsuchi"],
        activatesOn: ["noTarget"],
        effects: [],
        rarity: "mythic",
        id: data.randomId(data),
        image: "kagutsuchi",
        mythology: "japanese",
      }
      data.myHand.push(kagutsuchi)
    }
    return data
  },
  kagutsuchi: (data) => {
    const boardLength = data.defenderCurrentBoard.length
    data = data.boardAreaOfEffect(data, 0, "opponent", true)
    const fireElemtnalConstructor = () => {
      return {
        name: "Fire Elemental",
        type: "creature",
        class: "monster",
        attack: 3,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Provoke",
        abilities: [],
        activatesOn: [],
        effects: ["provoke"],
        rarity: "common",
        id: data.randomId(data),
        image: "japaneseFireElemental",
        mythology: "japanese",
        token: true,
      }
    }
    for (let i = 0; i < boardLength; i++) {
      data = data.playCreature({
        ...data,
        creature: fireElemtnalConstructor(),
        castSpell: false,
      })
    }
    return data
  },
  hecateIncarnate: (data) => {
    if (data.isolationCheck(data.deck)) {
      data.myPlayer.userName = "Hecate"
      data.myPlayer.type = "self"
      data.myPlayer.abilities = [
        "necroSupremacy",
        "spellAmplification",
        "spellMastery",
      ]
      data.myPlayer.abilityDescription = [
        "Raise a random minion from your graveyard to the battlefield",
        "Your spells and abilities deal +1 damage",
        "Your next spell costs 3 less mana",
      ]

      data = data.spellSplice(data)
      data.usedPlayerAbility = false
    }
    
    return data
  },
  necroSupremacy: (data) => {
    const targets = data.myCurrentGY.filter(
      (card) =>
        (card.type === "creature" || card.type === "spellcaster") &&
        card.cost <= 5
    )
    if (targets.length > 0) {
      const randomIndex = Math.floor(Math.random() * targets.length)
      const summonedCreature = data.myCurrentGY.splice(
        data.myCurrentGY.findIndex(
          (card) => card.id === targets[randomIndex].id
        ),
        1
      )[0]
      summonedCreature.token = true
      data = data.playCreature({
        ...data,
        creature: { ...summonedCreature },
        castSpell: true,
      })
    }
    return data
  },
  spellMastery: (data) => {
    data.spellReduction.reduceCost += 3
    data.spellReduction.reducedCost = true
    return data
  },
  spellAmplification: (data) => {
    data.nextCastDamage.spellDamage += 1
    return data
  },
  hecatesTriune: (data) => {
    data = data.spellSplice(data)
    const targets = data.myCurrentGY.filter(
      (card) =>
        (card.type === "creature" || card.type === "spellcaster") &&
        card.cost <= 3
    )
    const threeDrops = targets.filter((card) => card.cost === 3)
    const twoDrops = targets.filter((card) => card.cost === 2)
    const oneDrops = targets.filter((card) => card.cost === 1)
    if (threeDrops.length > 0) {
      const randomIndex = Math.floor(Math.random() * threeDrops.length)
      const summonedCreature = data.myCurrentGY.splice(
        data.myCurrentGY.findIndex(
          (card) => card.id === threeDrops[randomIndex].id
        ),
        1
      )[0]

      data = data.playCreature({
        ...data,
        creature: { ...summonedCreature },
        castSpell: true,
      })
    }
    if (twoDrops.length > 0) {
      const randomIndex = Math.floor(Math.random() * twoDrops.length)
      const summonedCreature = data.myCurrentGY.splice(
        data.myCurrentGY.findIndex(
          (card) => card.id === twoDrops[randomIndex].id
        ),
        1
      )[0]

      data = data.playCreature({
        ...data,
        creature: { ...summonedCreature },
        castSpell: true,
      })
    }
    if (threeDrops.length > 0) {
      const randomIndex = Math.floor(Math.random() * oneDrops.length)
      const summonedCreature = data.myCurrentGY.splice(
        data.myCurrentGY.findIndex(
          (card) => card.id === oneDrops[randomIndex].id
        ),
        1
      )[0]

      data = data.playCreature({
        ...data,
        creature: { ...summonedCreature },
        castSpell: true,
      })
    }
    return data
  },
  theLadyOfTheLake: (data) => {
    data.doubleCast.doubleCast = true
    return data
  },
  inariGodOfProsperity: (data) => {
    data.myPlayer.defense += 1
    data = data.drawCards(data, 1)
    return data
  },
  inariGreatHarvestKami: (data) => {
    const fox = {
      name: "Fox Spirit",
      type: "creature",
      class: "monster",
      attack: 1,
      defense: 1,
      damageTaken: 0,
      cost: 1,
      text: "Haste",
      abilities: [],
      activatesOn: [],
      effects: ["haste"],
      rarity: "common",
      id: data.randomId(data),
      image: "foxSpirit",
      mythology: "japanese",
      token: true,
    }
    data = data.playCreature({
      ...data,
      creature: { ...fox },
      castSpell: false,
    })
    return data
  },
  ringOfGreed: (data) => {
    if (data.drawnCardLoop) {
      const newRingOfGreed = { ...data.drawnCardLoop }
      newRingOfGreed.id = data.randomId(data)
      newRingOfGreed.token = true
      data.myHand.push(newRingOfGreed)
    } else if (data.theirDrawnCardLoop) {
      const newRingOfGreed = { ...data.theirDrawnCardLoop }
      newRingOfGreed.id = data.randomId(data)
      newRingOfGreed.token = true
      data.theirHand.push(newRingOfGreed)
    } else {
      data.pauseGame = true
      data = data.drawCards(data, 1)
      if (data.discardNumber) {
        data.discardNumber += 1
      } else {
        data.discardNumber = 1
      }

      data = data.spellSplice(data)
    }
    return data
  },
  draculaApexPredator: (data) => {
    const statSteal = { attack: 0, defense: 0 }
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      if (
        data.defenderCurrentBoard[i].attack === 1 &&
        data.defenderCurrentBoard[i].defense === 1
      ) {
        data = data.killCreature({
          ...data,
          location: "defender",
          card: data.defenderCurrentBoard[i],
          kill: true,
        })
      }
      data.kill = false
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }
    for (let i = 0; i < data.defenderCurrentBoard.length; i++) {
      if (
        data.defenderCurrentBoard[i].attack > 1 ||
        data.defenderCurrentBoard[i].defense > 1
      ) {
        if (data.defenderCurrentBoard[i].attack > 1) {
          data.defenderCurrentBoard[i].attack -= 1
          statSteal.attack += 1
        }
        if (data.defenderCurrentBoard[i].defense > 1) {
          data.defenderCurrentBoard[i].defense -= 1
          statSteal.defense += 1
        }
      }
      data = data.killCreature({
        ...data,
        location: "defender",
        card: data.defenderCurrentBoard[i],
      })
      if (data.attackerKill) {
        i--
      }
      data.attackerKill = false
    }

    data.veilOfShadowsCard.attack += statSteal.attack
    data.veilOfShadowsCard.defense += statSteal.defense
    return data
  },
  hattoriHanzo: (data) => {
    if (data.location === "attacker") {
      const priorityTargets = data.defenderCurrentBoard.filter(
        (card) => card.damageTaken < card.defense
      )
      if (priorityTargets.length > 0) {
        const randomIndex = Math.floor(Math.random() * priorityTargets.length)
        const cardID = priorityTargets[randomIndex].id
        const indexOnBoard = data.defenderCurrentBoard.findIndex(
          (card) => card.id === cardID
        )
        if (indexOnBoard >= 0) {
          data = data.killCreature({
            ...data,
            location: "defender",
            card: data.defenderCurrentBoard[indexOnBoard],
            kill: true,
          })
          data.kill = false
        }
      }
    } else {
      const priorityTargets = data.attackerCurrentBoard.filter(
        (card) => card.damageTaken < card.defense
      )
      if (priorityTargets.length > 0) {
        const randomIndex = Math.floor(Math.random() * priorityTargets.length)
        const cardID = priorityTargets[randomIndex]?.id
        const indexOnBoard = data.attackerCurrentBoard.findIndex(
          (card) => card.id === cardID
        )
        if (indexOnBoard >= 0) {
          data = data.killCreature({
            ...data,
            sacrifice: true,
            location: "attacker",
            card: data.attackerCurrentBoard[indexOnBoard],
            kill: true,
          })
          data.kill = false
        }
      }
    }

    return data
  },
  lycan: (data) => {
    const werewolf = data.findCard(data, data.veilOfShadowsCard.id)
    werewolf.attack += 1
    werewolf.defense += 1
    werewolf.effects.push("bloodthirst")
    werewolf.image = "lycanTransformed"
    delete werewolf.veilOfShadows
    werewolf.text = werewolf.text.replace(
      "Elusive\nVeil of Shadows: Transform into a werewolf then gain +1/+1 and bloodthirst",
      "Elusive\nBloodthirst"
    )

    return data
  },
  apepsPrice: (data) => {
    const corpseConstructor = () => {
      const corpse = {
        name: "Corpse",
        type: "creature",
        class: "undead",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "Fate: Draw a card and play this card",
        abilities: ["corpse"],
        activatesOn: ["doNotCast"],
        effects: ["whenDrawn"],
        rarity: "common",
        id: data.randomId(data),
        image: "corpse",
        mythology: "egyptian",
      }
      return corpse
    }

    for (let i = 0; i < 2; i++) {
      data.myCurrentGY = [
        corpseConstructor(),
        corpseConstructor(),
        ...data.myCurrentGY,
      ]
      data.deck.splice(
        Math.floor(Math.random() * data.deck.length),
        0,
        corpseConstructor()
      )
      data.deck.splice(
        Math.floor(Math.random() * data.deck.length),
        0,
        corpseConstructor()
      )
      let count = 0
      for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
        if (data.isUndead(data.attackerCurrentBoard[i])) count++
      }
      data = data.drawCards(data, count)
    }

    data = data.spellSplice(data)
    return data
  },
  obsidianMirror: (data) => {
    data.handReveal = true
    data = data.spellSplice(data)
    return data
  },
  herculesSonOfZeus: (data) => {
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++)
        if (data.deck[i].name === "Pegasus") {
          data.myHand.push(data.deck.splice(i, 1)[0])
          break
        }
    } else {
      for (let i = 0; i < data.myCurrentGY.length; i++)
        if (data.myCurrentGY[i].name === "Pegasus") {
          data.myHand.push(data.myCurrentGY.splice(i, 1)[0])
          break
        }
    }
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.attackerCurrentBoard[i].name === "Pegasus") {
        data.attackerCurrentBoard.splice(i, 1)
        const herculesSkywardSovereign = {
          name: "Hercules, Skyward Sovereign",
          type: "creature",
          class: "demigod",
          attack: 10,
          defense: 10,
          damageTaken: 0,
          cost: 10,
          text: "Initial Blow\nHaste\nOn Attack: Cast your next sorcery twice, this effect does not stack",
          abilities: ["herculesSkywardSovereign"],
          activatesOn: ["onAttack"],
          effects: ["initialBlow", "haste"],
          rarity: "mythic",
          id: data.randomId(data),
          image: "herculesSkywardSovereign",
          mythology: "greek",
        }
        data = data.playCreature({
          ...data,
          creature: { ...herculesSkywardSovereign },
          castSpell: false,
        })
        data.dontPlayCreature = true
      }
    }

    return data
  },
  pegasus: (data) => {
    if (!data.drawFromGraveyard) {
      for (let i = 0; i < data.deck.length; i++)
        if (data.deck[i].name === "Hercules, Son of Zeus") {
          data.myHand.push(data.deck.splice(i, 1)[0])
          break
        }
    } else {
      for (let i = 0; i < data.myCurrentGY.length; i++)
        if (data.myCurrentGY[i].name === "Hercules, Son of Zeus") {
          data.myHand.push(data.myCurrentGY.splice(i, 1)[0])
          break
        }
    }
    for (let i = 0; i < data.attackerCurrentBoard.length; i++) {
      if (data.attackerCurrentBoard[i].name === "Hercules, Son of Zeus") {
        data.attackerCurrentBoard.splice(i, 1)
        const herculesSkywardSovereign = {
          name: "Hercules, Skyward Sovereign",
          type: "creature",
          class: "demigod",
          attack: 10,
          defense: 10,
          damageTaken: 0,
          cost: 10,
          text: "Initial Blow\nHaste\nOn Attack: Cast your next sorcery twice, this effect does not stack",
          abilities: ["herculesSkywardSovereign"],
          activatesOn: ["onAttack"],
          effects: ["initialBlow", "haste"],
          rarity: "mythic",
          id: data.randomId(data),
          image: "herculesSkywardSovereign",
          mythology: "greek",
        }
        data = data.playCreature({
          ...data,
          creature: { ...herculesSkywardSovereign },
          castSpell: false,
        })
        data.dontPlayCreature = true
      }
    }
    return data
  },
  herculesSkywardSovereign: (data) => {
    data.doubleCast.doubleCast = true
    return data
  },
  ankhOfBurial: (data) => {
    const cards = {
      resurrection: {},
      recursion: {},
      realm: {},
    }
    for (let i = 0; i < data.deck.length; i++) {
      if (!cards.realm.name && data.deck[i].class === "realm") {
        cards.realm = data.deck.splice(i, 1)[0]
        i--
        continue
      }
      if (
        !cards.resurrection.name &&
        (data.deck[i].type === "creature" ||
          data.deck[i].type === "spellcaster") &&
        "recursionCost" in data.deck[i]
      ) {
        cards.resurrection = data.deck.splice(i, 1)[0]
        i--
        continue
      }
      if (
        !cards.recursion.name &&
        data.deck[i].type === "spell" &&
        "recursionCost" in data.deck[i]
      ) {
        cards.recursion = data.deck.splice(i, 1)[0]
        i--
        continue
      }
    }
    for (const card in cards) {
      if (cards[card].name) data.myCurrentGY.unshift(cards[card])
    }
    data = data.spellSplice(data)
    return data
  },
  ankhOfResurrection: (data) => {
    if (
      (data.target.type === "creature" || data.target.type === "spellcaster") &&
      data.target.resurrection
    ) {
      const reviveTarget = data.myCurrentGY.splice(data.index, 1)[0]
      data = data.playCreature({
        ...data,
        creature: { ...reviveTarget },
        castSpell: true,
      })
      data = data.spellSplice(data)
    }
    return data
  },
  kingAtlasStormbringer: (data) => {
    if (data.playCreatureFunction) {
      if (data.deck.length > 3) {
        data.myCurrentGY = [...data.deck.splice(0, 3), ...data.myCurrentGY]
      } else {
        data.myCurrentGY = [
          ...data.deck.splice(0, data.deck.length),
          ...data.myCurrentGY,
        ]
      }
      data = data.handleCallStack(data, data.creature)
      data.spellReduction.reduceCost = 10000
      data.spellReduction.reducedCost = true
      data.spellReduction.reset = false
      data.spellReduction.dontSplice = true
    } else {
      if(data.target.cost <= 6) data = data.handleCallStack(data, data.target)
    }

    return data
  },
  enkidu: (data) => {
    const manaIncrease = (card) => {
      return { ...card, cost: card.cost + 1 }
    }

    data.theirHand = data.theirHand.map(manaIncrease)

    return data
  },
  gilgameshTreasureHunter: (data) => {
    const andvarisTreasure = {
      name: "Andvari's Treasure",
      type: "spell",
      class: "sorcery",
      attack: null,
      defense: null,
      damageTaken: 0,
      cost: 0,
      text: "Gain 1 mana and 3 maximum life",
      abilities: ["andvarisTreasure"],
      activatesOn: ["noTarget"],
      effects: [],
      rarity: "mythic",
      id: data.randomId(data),
      image: "andvarisTreasure",
      mythology: "token",
      token: true,
    }

    if (data.location === "attacker") {
      data.myHand.push(andvarisTreasure)
    } else {
      data.theirHand.push(andvarisTreasure)
    }

    return data
  },
  lamashtu: (data) => {
    data.theirHand = [
      ...data.theirHand,
      ...data.defenderCurrentBoard.splice(
        data.defenderCurrentBoard.findIndex(
          (card) => card.id === data.target.id
        ),
        1
      ),
    ]
    const minion = data.findCard(data, data.target.id)

    minion.cost += 2
    data = data.removeGlobals(data, "targetedGlobal", minion)
    return data
  },
  marduk: (data) => {
    if (data.playCreatureFunction) {
      const dataSets = ["myHand", "myCurrentGY", "deck"]

      for (const dataset of dataSets) {
        if (data[dataset].find((card) => card.name === "Imhullu")) {
          data.myHand.push(
            data[dataset].splice(
              data[dataset].findIndex((card) => card.name === "Imhullu"),
              1
            )[0]
          )
        }
      }
    } else {
      const attackBuff = (card) => {
        return { ...card, attack: (card.attack += 1) }
      }
      data.attackerCurrentBoard = data.attackerCurrentBoard.map(attackBuff)
    }

    return data
  },
  imhullu: (data) => {
    data.attackerCurrentBoard[data.index].attack += 1
    data.attackerCurrentBoard[data.index].elusive = true
    if(!data.attackerCurrentBoard[data.index].text.includes('Elusive')) data.attackerCurrentBoard[data.index].text ='Elusive\n' + data.attackerCurrentBoard[data.index].text

    data = data.spellSplice(data)
    return data
  },
  tiamat: (data) => {
    if (data.drawFromGraveyard) {
      data.myCurrentGY = [
        ...data.myHand.splice(0, data.myHand.length),
        ...data.myCurrentGY,
      ]
    } else {
      data.deck = [...data.myHand.splice(0, data.myHand.length), ...data.deck]
    }

    if (data.opponentDrawFromGraveyard) {
      data.theirCurrentGY = [
        ...data.theirHand.splice(0, data.theirHand.length),
        ...data.theirCurrentGY,
      ]
    } else {
      data.theirDeck = [
        ...data.theirHand.splice(0, data.theirHand.length),
        ...data.theirDeck,
      ]
    }

    const newMyDeck = []
    const newTheirDeck = []

    const shuffle = (deck, shuffledDeck) => {
      while (deck.length) {
        const randomCard = Math.floor(Math.random() * deck.length)
        const card = deck.splice(randomCard, 1)[0]
        shuffledDeck.push(card)
      }

      return shuffledDeck
    }

    data.deck = shuffle(data.deck, newMyDeck)
    data.theirDeck = shuffle(data.theirDeck, newTheirDeck)

    data = data.drawCards(data, 4, "both")

    return data
  },
  nergal: (data) => {
    data.theirPlayer.poison += 1

    data = data.resolveDamage(data, data.theirPlayer.poison, "opponent")

    return data
  },
  enkisBlessing: (data) => {
    if (data.drawnCardLoop) {
      const enkisBlessing = { ...data.drawnCardLoop }
      enkisBlessing.id = data.randomId(data)
      enkisBlessing.token = true
      data.myHand.push(enkisBlessing)
    } else if (data.theirDrawnCardLoop) {
      const enkisBlessing = { ...data.theirDrawnCardLoop }
      enkisBlessing.id = data.randomId(data)
      enkisBlessing.token = true
      data.theirHand.push(enkisBlessing)
    } else {
      data.myCurrentMana += 1
      data = data.spellSplice(data)
    }

    return data
  },
  wisdomOfEa: (data) => {
    data = data.drawCards(data, 3, "self")
    data = data.drawCards(data, 2, "opponent")
    data = data.spellSplice(data)

    return data
  },
  girtabliluWarrior: (data) => {
    data = data.resolveDamage(data, 3)
    const possibleTargets = data.defenderCurrentBoard.filter(
      (card) => card.defense > card.damageTaken
    )

    const randomIndex = Math.floor(
      Math.random() * data.defenderCurrentBoard.length
    )
    const minion = data.findCard(data, possibleTargets[randomIndex]?.id)

    if (minion) {
      minion.damageTaken += data.resolveDamage(
        data,
        3,
        "dontKill",
        false,
        false,
        minion
      )
    }

    data = data.killCreature({
      ...data,
      location: "defender",
      card: minion,
    })

    return data
  },
  anusBlessing: (data) => {
    data = data.calculateHealing(data, 5, "self")
    data = data.spellSplice(data)

    return data
  },
  tabletOfDreams: (data) => {
    if (data.draw === "myDraw") {
      for (let i = 0; i < data.drawnCards.length; i++) {
        data.myPlayer.defense += 2
      }
    } else {
      for (let i = 0; i < data.theirDrawnCards.length; i++) {
        data.theirPlayer.defense += 2
      }
    }

    return data
  },
  shieldOfGula: (data) => {
    if (data.onHeal) {
      if (data.heal === "myHeal" && data.player === "self") {
        const randomIndex = Math.floor(
          Math.random() * data.attackerCurrentBoard.length
        )
        if(data.attackerCurrentBoard.length > 0){
          data.attackerCurrentBoard[randomIndex].attack += 1
          data.attackerCurrentBoard[randomIndex].defense += 1
        }
      } else if (data.heal === "opponentHeal" && data.player === "opponent") {
        const randomIndex = Math.floor(
          Math.random() * (data.defenderCurrentBoard.length)
        )
        if(data.defenderCurrentBoard.length > 0){
          data.defenderCurrentBoard[randomIndex].attack += 1
          data.defenderCurrentBoard[randomIndex].defense += 1
        }
      }
    }
    return data
  },
  totemOfNingal: (data) => {
    data.myPlayer.defense += 1
    for(let i = 0; i < data.attackerCurrentBoard.length; i++){
      data.attackerCurrentBoard[i].damageTaken = Math.max(data.attackerCurrentBoard[i].damageTaken -= 1, 0)
      data.attackerCurrentBoard[i].defense += 1
    }

    return data
  },
  yogSothoth: (data) => {
    if(data.killCreatureFunction){
      if (data.location === "attacker") {
        const card = data.card
        for(let i = 0; i < card.minions.length; i++){
          data = data.playCreature({
            ...data,
            creature: { ...card.minions[i] },
            castSpell: true,
          })
        }
      } else if (data.location === "defender") {
        for(let i = 0; i < data.card.minions.length; i++){
          data = data.playCreatureOnOpponentBoard({
            ...data,
            creature: { ...data.card.minions[i] },
            castSpell:  false,
          })
        }
      }
    } else if (data.target.name !== "Yog-Sothoth") {
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.target,
        kill: true,
      })
      const oldGod = data.findCard(data, data.mySpellCast.id);
      oldGod.minions = [{...data.target, id: data.randomId(data)}, {...data.target, id: data.randomId(data)}]
    }
    return data
  },
  curseOfAffliction: (data) => {
    if(data.startTurn){
      data.myPlayer.damageTaken += 2
    }else{
      data = data.spellSplice(data)
    }
    return data
  },
  plagueOfNergal: (data) => {
    const curse =  () => {
      const card = {
      "name": "Curse of Affliction",
      "type": "spell",
      "class": "curse",
      "attack": null,
      "defense": null,
      "damageTaken": 0,
      "cost": 5,
      "text": "Take 2 damage as long as this card is in your hand at the start of your turn\nBanish this card when played",
      "abilities": ["curseOfAffliction"],
      "activatesOn": ["noTarget"],
      "effects": [],
      "rarity": "common",
      "id": data.randomId(data),
      "image": "",
      "mythology": "akkadian",
      "token": true
      }

      return card
    }

    if(data.opponentDrawFromGraveyard){
      for(let i = 0; i < 3; i++) {
        data.theirCurrentGY.push(curse())
      }
    }else{
      for(let i = 0; i < 3; i++){
        const randomIndex = Math.floor(Math.random() * data.theirDeck.length)
        data.theirDeck.splice(randomIndex, 0, curse())
      }
    }

    data = data.spellSplice(data)

    return data
  },
  hecatesShadowSpawn: (data) => {
    const curse = () => {
      const card = {
          "name": "Curse of Subjugation",
          "type": "spell",
          "class": "curse",
          "attack": null,
          "defense": null,
          "damageTaken": 0,
          "cost": 4,
          "text": "When this card is in your hand at the start of your turn summon a minion that costs 3 or less from your opponents deck to their board, ETBs do not trigger\nBanish this card when played",
          "abilities": ["curseOfSubjugation"],
          "activatesOn": ["noTarget"],
          "effects": [],
          "rarity": "common",
          "id": data.randomId(data),
          "image": "",
          "mythology": "greek",
          "token": true
      }

      return card
    }

    if(data.opponentDrawFromGraveyard){
      for(let i = 0; i < 3; i++) {
        data.theirCurrentGY.push(curse())
      }
    }else{
      for(let i = 0; i < 3; i++){
        const randomIndex = Math.floor(Math.random() * data.theirDeck.length)
        data.theirDeck.splice(randomIndex, 0, curse())
      }
    }

    data = data.spellSplice(data)

    return data
  },
  curseOfSubjugation: (data) => {
    if(data.startTurn){
      const targets = data.opponentDrawFromGraveyard ? data.theirCurrentGY.filter((card) => (card.type === 'creature' || card.type === 'spellcaster') && card.cost <= 3) : data.theirDeck.filter((card) => (card.type === 'creature' || card.type === 'spellcaster') && card.cost <= 3)

      const summononedMinion = targets[Math.floor(Math.random() * targets.length)]
      const removeMinion = data.opponentDrawFromGraveyard ? data.theirCurrentGY.splice(data.theirCurrentGY.findIndex((card) => card.id === summononedMinion.id), 1) : data.theirDeck.splice(data.theirDeck.findIndex((card) => card.id === summononedMinion.id), 1)

      if(removeMinion){
        data = data.playCreatureOnOpponentBoard({
          ...data,
          creature: summononedMinion,
          castSpell: false,
        })
      }
    }else{
      data = data.spellSplice(data)
    }

    return data
  },
  xipeTotec: (data) => {
    data.myPlayer.userName = "Xipe Totec, Flayed God"
    data.myPlayer.type = "self"
    data.myPlayer.abilities = ["spawnWench", "bloodSacrifice"]
    data.myPlayer.abilityDescription = [
      "Summon a 1/1 aztec slave",
      "Sacrifice the minion you control with lowest total stats then draw a card",
    ]
    data = data.spellSplice(data)
    data.usedPlayerAbility = false
    return data
  },
  spawnWench: (data) => {
    const slaveConstructor = (image) => {
      const slave = {
        name: "Aztec Slaves",
        type: "creature",
        class: "human",
        attack: 1,
        defense: 1,
        damageTaken: 0,
        cost: 1,
        text: "When sacrificed transfer this creatures stats to a random friendly minion",
        abilities: [],
        activatesOn: [],
        effects: [],
        rarity: "common",
        id: data.randomId(data),
        image: image,
        sacrificialPawn: "aztecSlave",
        token: true,
        mythology: "token",
      }
      return slave
    }

    const imageArrray = ["slaveOne", "slaveTwo"]
    const slave = slaveConstructor(imageArrray[Math.floor(Math.random() * imageArrray.length)])

    data = data.playCreature({ ...data, creature: slave, castSpell: false })
        
    return data
  },
  bloodSacrifice: (data) => {
    const statsArray = []

    for(let i = 0; i < data.attackerCurrentBoard.length; i++){
      statsArray.push({stats: (data.attackerCurrentBoard[i].attack + data.attackerCurrentBoard[i].defense), id: data.attackerCurrentBoard[i].id})
    }

    if(statsArray.length > 0){
      statsArray.sort((a, b) => a.stats - b.stats)
      data = data.killCreature({
        ...data,
        sacrifice: true,
        location: "attacker",
        card: data.attackerCurrentBoard[data.attackerCurrentBoard.findIndex((card) => card.id === statsArray[0].id)],
        kill: true,
      })

      data = data.drawCards(data, 1)
    }

    return data
  }
}

export default spellFunctions